.c-form__label {
    @include make-h4();
    color: $body-color;
    margin-bottom: $spacer--xs;
    margin-top: 0;
}

.c-form__label--error {
    color: $error;
}

.c-form__label--checkbox:before {
    display: none;
}
.c-form__label--radio:before {
    display: none;
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
    display: none;
}
.c-form__radio-input:checked + .c-form__label--radio:before {
    display: none;
}

.c-form__radio-group {
    @include make-row($spacer);
    margin-bottom: -$spacer;
}

.c-form__radio {
    @include make-col-ready($spacer);
    margin-bottom: $spacer;
    width: 50%;
    flex: 1 1 12rem;
}

.c-form__label--radio {
    @include type-scale(m);
    border-radius: rem(4);
    border: rem(1) solid $primary;
    background-color: $white;
    color: $primary;
    display: block;
    width: 100%;
    padding: $spacer--s $spacer--s;
    text-align: center;
    margin-bottom: 0;
    color: $primary;
    font-weight: bold;
    font-family: $font-family--secondary;

    &:hover {
        border-color: $primary;
        box-shadow: 0 0 0 rem(1) $primary;
        color: $primary;
    }
    
}

.c-form__radio-input:checked + .c-form__label--radio {
    border-color: $primary;
    background-color: $primary;
    color: $white;
}

.c-form__label--error {
    @include type-scale(s, 1.25);
    margin-top: $spacer--xs;
    color: color-step($error, 600);
}

// TEMP
.c-form__step {
    display: block;

    .one-step {
        margin-top: $spacer--l;
    }
}

.c-form__control, 
.c-form__textarea {
    border-color: $primary;
    color: $body-color;

    &:focus {
        border-color: $primary;
        border-radius: rem(4);
        outline: none;
        box-shadow: 0 0 0 rem(1) $primary, 0 0 rem(4) 0 rgba($primary, 0.4);
    }
}
