.c-page--single {
    .c-hero__content,
    .c-page__footer {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }

    .c-page__content {
        @include make-container;
    }

    .c-page__main {
        max-width: 60rem;
        margin: 3rem auto;

        .c-wysiwyg {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-page__featured {
        margin-top: 0;

        @include media-breakpoint-up(m) {
            max-width: 50%;
            padding-left: $grid-gutter-width;
        }
    }
}
