.c-content-builder {
    > * {
        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}
