table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

td,
th {
    padding: 0.5rem 1rem;
}

thead {
    th,
    td {
        background-color: theme-color('primary');
        color: $white;
        font-weight: $font-weight--normal;
        text-transform: uppercase;
    }
}
tbody {
    td,
    th {
        border-bottom: rem(1) solid theme-color('light');
    }
    tr:last-child {
        td,
        th {
            border-bottom: 0;
        }
    }
}
