.c-page--backpage {

    .c-hero__title {

        @include media-breakpoint-up(l) {
            max-width: 60%;
        }
    }



    .c-page__aside {
        .c-form--search {
            @include media-breakpoint-up(l) {
                margin-bottom: $spacer--xl;
            }
        }
    }

    .c-page__footer {
        @include media-breakpoint-down(m) {
            margin-top: $spacer--3xl;
            margin-bottom: $spacer--3xl;
        }
        @include media-breakpoint-up(l) {
            margin-top: $spacer--5xl;
            margin-bottom: $spacer--5xl;
        }
    }
    .c-page__footer-content {
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(l) {
            margin-left: 0;
        }
    }

    hr {
        border: 0;
        border-bottom: rem(1) solid $gray-100;

        @include media-breakpoint-down(m) {
            margin-top: $spacer--2xl;
            margin-bottom: $spacer--2xl;
        }
        @include media-breakpoint-up(l) {
            margin-top: $spacer--3xl;
            margin-bottom: $spacer--3xl;
        }
    }
}
