.c-404 {

    .c-hero__title {
        color: $white;
    }

    .c-hero__description {
        color: $white;
        margin-bottom: $spacer--2xl;
        margin-top: $spacer;
    }

    .c-page__header {
        background-image: url('/wp-content/uploads/bg-lander-thankyou.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
}
