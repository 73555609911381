.c-video__play {
    flex: 0 0 auto;
    @include media-breakpoint-only(s) {
        font-size: 0.75rem;
        width: $spacer--xl;
        height: $spacer--xl;
        line-height: $spacer--xl;
    }
}

.c-video__duration {
    @include media-breakpoint-only(s) {
        @include type-scale('xs', 1);
    }
}

.c-video__title {
    @include media-breakpoint-only(s) {
        @include type-scale('s', 1.2);
    }
    @include media-breakpoint-up(m) {
        @include type-scale('base', 1.2);
    }
}

.c-youtube {
    margin-top: $spacer--2xl;
    margin-bottom: $spacer--2xl;
}
