.c-action-block {
    text-align: left;
    padding: 0;
    @include media-breakpoint-only(m) {
        text-align: left;
    }
}

a.c-action-block--card {
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    &:hover {
        box-shadow: none;
    }
}

.c-action-block__media {
    width: 100%;
}

.c-action-block__image {
    img {
        border-radius: rem(4);
        max-width: 100%;
        // width: 100%;
    }
}

.c-action-list {
    &.c-action-list--gray-bg {
        .c-action-block {
            background-color: $gray-alt;
        }
    }
    &.c-action-list--white-bg {
        .c-action-block {
            background-color: $white;
        }
    }
}

.c-action-list--col-1 {
    .c-action-list__items {
        display: block;
    }

    .c-action-block {
        max-width: none;
    }
}

.c-action-list--col-2 {
    .c-action-list__items {
        display: flex;
        flex-wrap: wrap;
    }

    .c-action-list__item {
        @include make-col-ready();
        @include media-breakpoint-up(m) {
            width: 50%;
        }
    }
}

.c-action-list--center {
    .c-action-block {
        text-align: center;
    }
}

.c-action-list--col-3 {
    .c-action-list__items {
        display: flex;
        flex-wrap: wrap;
    }

    .c-action-list__item {

        @include make-col-ready();
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-up(m) {
            width: percentage(1/3);
        }
    }

    .c-action-block {
        text-align: center;
        display: flex;
        align-items: flex-start;
        padding: $spacer--2xl;
        max-width: 40rem;
        margin-bottom: 0;
    }

    &.c-action-list--left {
        .c-action-block {
            text-align: left;
        }
    }
}

.c-action-list--col-4 {
    .c-action-block__image {
        img {
            border-radius: 100%;

            @include media-breakpoint-only(s) {
                width: 4rem;
            }
        }
    }

    .c-action-block__copy {
        text-align: left;
    }

    .c-action-block__content {
        text-align: left;
    }
    @include media-breakpoint-up(xs) {
        .c-action-list__items {
            display: flex;
            flex-wrap: wrap;
        }

        .c-action-list__item {
            flex: 1 1 26rem;
        }

        .c-action-block {
            // flex-direction: row;
            display: flex;
            align-items: flex-start;
            padding: $spacer--2xl;
            max-width: 40rem;
            margin-bottom: 0;
        }

        .c-action-block__media {
            flex: 0 0 5rem;
        }

        .c-action-block__content {
            flex: 1 1 auto;
            // padding-left: $grid-gutter-width;
        }
    }
}

.c-action-list--col-5 {
    .c-action-list__items {
        display: flex;
        flex-wrap: wrap;
    }

    .c-action-list__item {

        @include make-col-ready();
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-up(m) {
            width: percentage(1/5);
        }
        @include media-breakpoint-down(m) {
            width: percentage(1);
        }
    }

    .c-action-block {
        text-align: center;
        display: flex;
        align-items: flex-start;
        padding: $spacer--2xl;
        max-width: 40rem;
        margin-bottom: 0;
        @include media-breakpoint-down(m) {
            padding: $spacer;
        }
    }

    &.c-action-list--left {
        .c-action-block {
            text-align: left;
        }
    }
}

.c-action-list--narrow {
    .c-action-list__items {
        max-width: 55rem;
        margin: auto;
    }

    .c-action-block {
        max-width: 22rem;
    }
}

.c-action-list--icon {
    .c-action-block__image {
        img {
            border-radius: 100%;
        }
    }

    .c-action-block__copy {
        text-align: left;
    }

    .c-action-block__content {
        text-align: left;
    }
    @include media-breakpoint-up(m) {
        .c-action-list__items {
            display: flex;
            flex-wrap: wrap;
        }

        .c-action-list__item {
            flex: 1 1 26rem;
        }

        .c-action-block {
            flex-direction: row;
            display: flex;
            align-items: flex-start;
            padding: 0;
        }

        .c-action-block__media {
            flex: 0 0 5rem;
        }

        .c-action-block__content {
            flex: 1 1 auto;
            padding-left: $grid-gutter-width;
        }
    }
}

.no-flexbox,
.no-flexboxlegacy {
    .c-action-list__items {
        overflow: hidden;
    }

    .c-action-list__item {
        display: block;
        float: left;
        width: 33%;
    }

    .c-action-list--col-2 {
        .c-action-list__item {
            display: block;
            float: left;
            width: 49%;
        }
    }
}
