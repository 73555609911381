.c-form {
	.c-disclaimer {
		@include type-scale(xs, 1.2);
		color: $body-color;
		font-style: normal;
		margin-bottom: $spacer;
		margin-top: $spacer--xl;
		display: flex;
		justify-content: center;

		&:before {
			@include make-icon;
			@include type-scale(base, 1);
			content: icon('lock');
			flex: 0 0 auto;
			padding-right: $spacer;
		}
	}
}

.c-form__wrapper {
	background-color: $white;
	border-radius: $spacer--2xs;
	padding: 0 $spacer--xl $spacer--l;
	box-shadow: 0 0 0.5rem 0 rgba($primary, 0.3);

	@include media-breakpoint-down(m) {
		max-width: 38rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.c-form__title {
	@include type-scale(l, 1);
	color: $white;
	background-color: color-step($primary, 700);
	padding: $spacer;
	text-align: center;
	font-weight: $font-weight--bold;
	border-radius: $spacer--2xs $spacer--2xs 0 0;
	margin-left: -$spacer--xl;
	margin-right: -$spacer--xl;
}

.c-form__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: $spacer--m;
	padding-bottom: $spacer--m;
	position: relative;
	max-width: 25rem;
	margin-left: auto;
	margin-right: auto;

	&:before {
		content: '';
		display: block;
		height: rem(1);
		width: 100%;
		background: $gray-300;
		top: 50%;
		left: 0;
		position: absolute;
		z-index: 0;
	}
}
.c-form__nav-item {
	text-align: center;
	position: relative;
	z-index: 1;
	cursor: pointer;
}
.c-form__nav-button {
	@include type-scale(xs, 1);
	width: $spacer--l;
	height: $spacer--l;
	font-weight: bold;
	text-align: center;
	background: $white;
	border: rem(1) solid rgba($dark, 0.3);
	color: rgba($dark, 0.3);
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	box-shadow: 0 0 0 0.5rem $white;

	&.is-active {
		border-width: rem(2);
		border-color: $primary;
		color: $primary;
	}
}

.c-form__group {
	margin-bottom: $spacer--s;
}

.c-form__footer {
	margin-top: $spacer;
}

.c-form__fields--split {
	@include make-row();

	.c-form__group {
		@include make-col-ready();
		width: 50%;
	}
}

.c-form__wrapper--single {
	margin-top: $spacer--3xl;
	margin-bottom: $spacer--3xl;
}

.c-form--single {
	padding-top: $spacer;
	padding-bottom: $spacer;

	.c-form__submit {
		width: 100%;
	}
}

.c-form--search {
	display: flex;

	.c-button {
		flex-grow: 0;
		flex-shrink: 0;
		margin-left: $spacer--s;
	}
}

.no-flexbox,
.no-flexboxlegacy {
	.c-hero--split {
		.c-hero__main {
			&:after {
				background: transparent;
			}
		}
	}

	.c-form__nav {
		display: block;
	}

	.c-form__nav-item {
		float: left;
		width: 33%;
	}

	.c-form__nav {
		width: 100%;
	}

	.c-form__step {
		width: 100%;
		// float: none;
	}
}

.hero--variant-b {
	fieldset[data-step='1'] .c-form__fields {
		@include media-breakpoint-up(m) {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr;
			column-gap: $spacer;
		}

		.c-button {
			@include media-breakpoint-up(m) {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: $spacer--s;
			}
		}
	}

	fieldset[data-step='2'] .c-form__fields {
		@include media-breakpoint-up(m) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			column-gap: $spacer;
		}

		@include media-breakpoint-up(l) {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr;
			column-gap: $spacer;
		}

		.c-form__group:first-of-type {
			@include media-breakpoint-up(l) {
				grid-column: 1 / span 2;
			}
		}

		.c-button {
			@include media-breakpoint-up(m) {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: $spacer--s;
			}
		}
	}
}
