.c-related-posts {
    margin-top: $spacer--xl;
    margin-bottom: $spacer--xl;
}

.c-related-posts__title {
    margin-bottom: 2rem;
}

.c-related-posts__list {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
    overflow-x: auto;
}

.c-related-posts__item {
    padding: 0 1rem;
    min-width: 18rem;
    display: flex;
    align-items: stretch;

    .c-post-block {
        margin-top: 0.25rem;
        margin-bottom: 1rem;
    }

    .c-post-block--card {
        &,
        .c-post-block__inner,
        .c-post-block__content {
            display: flex;
            align-items: stretch;
        }
        .c-post-block__inner,
        .c-post-block__content {
            flex-direction: column;
        }
        .c-post-block__content {
            flex-grow: 1;
        }
        .c-post-block__after {
            margin-top: auto;
        }
    }
}
