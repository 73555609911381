.c-timeline {
    padding-left: 0;
    margin-bottom: 0;
}

.c-timeline__item {
    padding-left: 2.5rem;
    position: relative;
    list-style: none;
    padding-bottom: 1rem;

    &:last-child {
        padding-bottom: 0;
    }

    &:before {
        content: '';
        display: block;
        border-radius: 100%;
        height: 1.5rem;
        width: 1.5rem;
        border: 0.375rem solid $white;
        background-color: theme-color('dark');
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    &:after {
        content: '';
        display: block;
        width: rem(2);
        height: 100%;
        position: absolute;
        left: rem(11);
        top: 0;
        background-color: theme-color('light');
        z-index: 1;
    }
}

.c-timeline__item-title {
}

.c-timeline__item-content {
}
