.c-media-block {
	.c-wysiwyg {
		margin-top: 0;
	}

	.c-ul {
		li {
			margin-bottom: $spacer--l;
		}
	}
}

.c-media-block__media {
	@include media-breakpoint-up(l) {
		width: percentage(1/3);
	}
}

.c-media-block__content {
	@include media-breakpoint-up(l) {
		width: percentage(2/3);
	}
}

.c-media-block--nm {
	margin-top: 0;
	margin-bottom: 0;
}

.c-media-block--col-6-4 {
    .c-media-block__content {
        @include media-breakpoint-up(l) {
            width: 55%;
        }
    }

    .c-media-block__media {
        @include media-breakpoint-up(l) {
            width: 45%;
        }
    }
}

// @TODO FIX THIS
// we should have a center modifier instead of basing it on the template
.c-page--lander {
	.c-media-block {
		h2 {
			&:after {
				right: initial;
			}
		}
	}
}
