.c-cta {
    display: block;
    border: 0;
    padding: 0;
    position: relative;
}

.c-cta__title {
    @include type-scale(l, 1.2);
}

.c-cta__description {
    margin-top: $spacer--xs;
    margin-bottom: 0;
}


.c-cta__button {
    margin-top: $spacer--2xl;
}

.c-cta__button--livechat {
    @include media-breakpoint-only(s) {
        display: none;
    }
}
.c-cta__button--phone {
    @include media-breakpoint-up(m) {
        display: none;
    }
}


.c-cta--primary {
    background-color: $primary;
    background-image: url('#{$img-path}cta-pattern.png');
    background-repeat: no-repeat;
    background-position: top right;
    border: 0;
    padding: 0;
    display: block;
    border-radius: $spacer--2xs;

    .c-cta__container {
        width: 100%;

        @include media-breakpoint-only(s) {
            padding: $spacer--l;
        }
        @include media-breakpoint-up(m) {
            position: relative;
            overflow: hidden;
        }

    }

    .c-cta__description {
        color: $white;
    }

    .c-cta__title {
        color: $white;
    }

    .c-button {
        @include make-button-variant(
            $white,
            $white,
            $primary,
            $accent,
            $accent,
            $white
        );
        box-shadow: $box-shadow;
    }

    $angle: 19deg;

    .c-cta__media {
        transform: rotate(-$angle);

        @include media-breakpoint-only(s) {
            width: 6rem;
            height: 6rem;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-up(m) {
            position: absolute;
            right: 62%;
            top: 50%;
            width: 12.5rem;
            height: 12.5rem;
            z-index: 0;
            margin-top: -6.25rem;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 110%;
            height: 110%;
            background-color: color-step($primary, 600);
            transform: rotate(-5deg);
            border-radius: 1rem;
            top: -7.5%;
            left: -2.5%;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 98%;
            height: 98%;
            background-color: color-step($primary, 700);
            border-radius: 1rem;
            top: 1%;
            left: 1%;
            z-index: 1;
        }
    }


    .c-cta__image-wrapper {
        position: relative;
        overflow: hidden;
        border-radius: $spacer;
        width: 100%;
        height: 100%;
        z-index: 2;

        img {
            width: 125%;
            height: 125%;
            transform: rotate($angle) translate(-12.5%, -6.25%);
            margin: 0;
        }
    }

    .c-cta__content {
        text-align: center;

        @include media-breakpoint-only(s) {
            margin-top: $spacer;
        }
        @include media-breakpoint-up(m) {
            min-height: 12rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 60%;
            padding: $spacer--xl $spacer;
            float: right;
        }
    }

    .c-cta__button {

        .c-button {
            @include cover-parent();
        }
    }
}

.c-cta--full {
    max-width: none;
    background-color: $primary;


    h2 {
        color: $white;
    }

    .c-cta__container {


        @include make-row;
        max-width: 54rem;
        margin-left: auto;
        margin-right: auto;
        padding-top: $spacer--2xl;
        padding-bottom: $spacer--2xl;

        @include media-breakpoint-down(m) {
            text-align: center;
        }
    }

    .c-cta__media {
        @include make-col-ready;

        @include media-breakpoint-up(l) {
            width: percentage(1/3);
            margin-left: auto;
        }

    }

    .c-cta__content {

        @include make-col-ready;

        text-align: left;

        @include media-breakpoint-up(l) {
            width: percentage(2/3);
            margin-left: auto;
        }




        @include media-breakpoint-down(m) {
            text-align: center;
        }
    }

    .c-cta__description {
        text-align: left;
        margin: 0;
        color: $white;

        @include media-breakpoint-down(m) {
            text-align: center;
        }
    }



    .c-cta__title {
        text-align: left;
    }

    .c-button {
        @include make-button-variant(
            $white,
            $white,
            $primary,
            $accent,
            $accent,
            $white
        );
        box-shadow: $box-shadow;
    }
}

.c-cta--stacked {
    .c-cta__container {
        display: block;
        padding: $spacer--l;
        background-image: none;
    }
    .c-cta__media {
        width: 6rem;
        height: 6rem;
        position: relative;
        top: 0;
        right: 0;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
    }
    .c-cta__content {
        min-height: 0;
        width: 100%;
        padding: 0;
        margin-top: $spacer--xl;
        float: none;
    }
}

.c-cta--top-aside {
    text-align: center;
    margin-bottom: $spacer--l;


    .c-cta__img {
        margin-bottom: $spacer--s;
    }

    @include media-breakpoint-down(m) {
        display: none;
    }

}


.c-cta--no-mask {
    .c-cta__image-wrapper {
        overflow: visible;
    }
}
