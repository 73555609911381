.c-lander--meso-packet-variant {
    background-image: url('/wp-content/uploads/meso-packet-bg.png');
    background-repeat: no-repeat;
    background-size: cover;

    .c-hero--lander {
        background-color: transparent;

        .c-hero__title,
        .c-hero__description {
            color: #0d1b26;
        }

        .c-hero__before {
            @include media-breakpoint-down(m) {
                padding-bottom: 0;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        .c-hero__title {
            @include media-breakpoint-down(m) {
                text-align: center;
            }
        }

        .c-form__wrapper {
            background-color: none;
            border-radius: none;
            box-shadow: unset;
        }
    }

    .c-hero__before {
        margin: auto;
        padding-bottom: 3rem;
    }

    .c-hero__content {
        @include make-row();
        justify-content: space-around;
        align-items: center;
    }

    .c-hero__main {
        @include make-col-ready();

        @include media-breakpoint-up(l) {
            @include make-col(5);
        }

        &:after {
            display: none;
        }
    }

    .c-hero__aside {
        @include make-col-ready();

        @include media-breakpoint-up(l) {
            @include make-col(5);
        }
    }

    .c-form__wrapper {
        background-color: transparent;
        border-radius: none;
        box-shadow: unset;

        @include media-breakpoint-down(m) {
            padding: 0;
        }
    }

    .h2,
    h2 {
        margin-bottom: 0;
    }

    .item-list {
        @include make-row();
        justify-content: center;
    }

    .item-card {
        @include make-col-ready();
        @include make-card();
        background-color: $white;
        padding: 1rem;
        align-items: center;
        border-radius: rem(8);
        margin-top: $action-block-margin-top;
        margin-bottom: $action-block-margin-bottom;
        margin-right: 1rem;
        margin-left: 1rem;
        color: $action-block-color;

        @include media-breakpoint-down(m) {
            margin-top: rem(12);
            margin-bottom: rem(12);
        }

        @include media-breakpoint-up(l) {
            @include make-col(3.6);
        }
    }

    .item-card__header {
        display: flex;
        align-items: center;
    }

    .item-card__header-title {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0.5rem;
    }

    .c-action-block {
        padding: 1rem;
    }

    .c-section--mobile-only {
        margin-bottom: 0;
        padding-bottom: 2.5rem;

        .c-button {
            margin-left: 1rem;
            margin-right: 1rem;
            background-color: $secondary-light;
            border-color: $secondary-light;
            color: $dark;

            @include media-breakpoint-only(s) {
                width: calc(100% - 2rem);
            }
        }
    }
}
