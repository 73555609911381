.c-profile-contact {
}

.c-profile-contact__list {
}

.c-profile-contact__item {
    display: inline-block;

    &:after {
        content: '|';
        padding: 0 0.5rem;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
}

.c-profile-contact__link {
}
