.c-table {
    margin-top: $spacer--xl;
    margin-bottom: $spacer--xl;
    
    table {
        margin-top: 0;
        border: rem(1) solid $gray;
    }
}

.c-table-responsive {
    max-width: 100%;
    overflow-x: auto;
}

.c-table__title {
    background-color: $primary;
    color: $white;
    font-family: $font-family--secondary;
    border-radius: rem(4) rem(4) 0 0;
    display: block;
    margin: 0;
    padding: $spacer;
}

.c-table__sources {
    @include type-scale(s);
    margin-top: $spacer;
    text-align: center;
    
}

.c-factbox {
    & + .c-table {
        margin-top: $spacer--2xl;
    }
}
