.patient-resources {
    .c-button {
        @include media-breakpoint-only(s) {
            width: 100%;
        }
    }

    .c-about__subsection {
        img {
            width: 100%;
            @include media-breakpoint-down(m) {
                display: block;
            }
        }
    }

    .c-breadcrumbs {
        @include media-breakpoint-down(m) {
            &:after {
                background: linear-gradient(to right, rgba($gray-alt, 0) 0%, rgba($gray-alt, 0) 100%);
            }
        }
    }

    .c-hero {
        margin-bottom: 0;
        min-height: rem(410);
        background-color: $gray-alt;
        background-image: url('/wp-content/uploads/hero-bg.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-down(m) {
            padding-top: $spacer;
            padding-bottom: $spacer--2xl;
            min-height: auto;
        }

        .c-hero__title {
            color: $dark-text;
            font-weight: normal;
            position: relative;
            margin-top: rem(100);
            @include media-breakpoint-only(s) {
                margin-top: rem(50);
            }

            &:before {
                position: absolute;
                content: '';
                height: rem(2);
                width: rem(64);
                background-color: $accent;
                top: rem(-24);
                left: 0;
            }
        }

        .c-hero__description {
            color: $dark-text;
            font-size: rem(21);
            @include media-breakpoint-only(s) {
                font-size: rem(18);
            }
        }

        .c-breadcrumbs,
        .c-breadcrumbs a,
        .c-breadcrumbs span {
            color: $dark-text;
        }
    }

    .c-section__header {
        text-align: center;
        @include media-breakpoint-up(l) {
            margin: 0 auto;
            width: rem(554);
        }
        @include media-breakpoint-only(m) {
            margin: 0 auto;
            width: rem(400);
        }
    }

    .c-section--one {
        padding-top: 0;
        padding-bottom: 0;

        .c-section__title {
            @include media-breakpoint-down(m) {
                margin-top: $spacer--xl;
                margin-bottom: 0;
            }
            @include media-breakpoint-only(s) {
                margin-top: $spacer--2xl;
                margin-bottom: 0;
            }
        }

        .c-section__content {
            @include media-breakpoint-down(m) {
                order: 2;
            }
        }

        .c-section__image {
            @include media-breakpoint-down(m) {
                order: 1;
                margin-bottom: 0;
            }
        }
    }

    .c-section--two {
        padding-top: $spacer;

        .l-container {
            background-color: $gray-alt;
            border-radius: rem(8);
            padding-top: rem(56);
            padding-bottom: rem(60);
        }

        .c-section--books {
            padding-top: 0;
            padding-bottom: 0;

            .l-container {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .c-cta {
            margin-top: rem(-40);
            text-align: center;
        }

        .c-action-block__content,
        .c-action-block__copy,
        .c-action-block__media {
            @include media-breakpoint-only(s) {
                text-align: center;
            }
        }

        .c-section__title {
            font-size: rem(18);
            letter-spacing: rem(0.5);
            text-transform: uppercase;
            padding-bottom: 0;
            margin-bottom: rem(55);
            margin-top: 0;
            @include media-breakpoint-only(s) {
                margin-bottom: $spacer;
            }
        }

        .c-action-list__item {
            .c-action-block {
                margin-top: 0;
                margin-bottom: rem(64);
                @include media-breakpoint-only(s) {
                    margin-bottom: $spacer--2xl;
                }
            }
        }
    }

    .c-section--three {
        background-color: $gray-alt;
        padding-top: 0;
        padding-bottom: $spacer--2xl;

        .c-about__subsection {
            img {
                border-radius: rem(6);
            }
        }

        .c-section__content {
            @include media-breakpoint-down(m) {
                order: 2;
            }
        }

        .c-section__image {
            @include media-breakpoint-down(m) {
                order: 1;
                margin-bottom: 0;
            }
        }

        .c-section__title {
            margin-bottom: 0;
            padding-bottom: 0;
            @include media-breakpoint-only(s) {
                margin-top: 0;
            }
        }

        .l-row {
            align-items: center;
        }
        @include media-breakpoint-only (s) {
            background-color: $white;
        }
    }

    .c-section--four {
        padding-top: 0;
        padding-bottom: 0;

        .c-about__subsection {
            img {
                border-radius: rem(6);
            }
        }

        .c-section__content {
            @include media-breakpoint-down(m) {
                order: 2;
            }
        }

        .c-section__image {
            @include media-breakpoint-down(m) {
                order: 1;
                margin-bottom: 0;
            }
        }

        .c-section__title {
            margin-bottom: $spacer;
            padding-bottom: 0;
            @include media-breakpoint-only(s) {
                margin-top: 0;
            }
        }

        .c-cta__description {
            text-align: left;
            margin-left: 0;
        }

        .l-row {
            align-items: center;
        }

        .c-section--cta {
            @include media-breakpoint-down(m) {
                padding-top: $spacer;
            }
        }

        .cta-block {
            background-color: $gray-alt;
            background-image: url('/wp-content/uploads/content-bg-1.png');
            background-position: right bottom;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: rem(8);
            padding: rem(56);
            @include media-breakpoint-only(m) {
                padding: $spacer--xl;
            }
            @include media-breakpoint-only(s) {
                padding: $spacer--2xl;
            }

            .c-cta__title {
                display: block;
                font-size: rem(27);
                margin-bottom: rem(32);
                @include media-breakpoint-only(s) {
                    text-align: center;
                }
            }

            .c-section__cta-image {
                width: 40%;
                text-align: center;
                @include media-breakpoint-only(s) {
                    width: 100%;
                }

                img {
                    width: 70%;
                }
            }

            .c-section__cta-content {
                width: 60%;
                text-align: left;
                @include media-breakpoint-only(s) {
                    width: 100%;
                }
            }
        }
        @include media-breakpoint-only(s) {
            .c-cta {
                margin-bottom: 0;
            }
        }
    }

    .c-section--five {
        background-color: $gray-alt;
        padding-top: 0;
        @include media-breakpoint-down(m) {
            padding-bottom: 0;
        }

        .c-section__content {
            @include media-breakpoint-down(m) {
                order: 2;
            }
        }

        .c-section__profile {
            @include media-breakpoint-down(m) {
                order: 1;
                margin-bottom: 0;
            }
        }

        .c-section__content {
            @include media-breakpoint-only(s) {
                margin-top: 0;
            }
        }

        .c-section__profile {
            @include media-breakpoint-only(s) {
                margin-bottom: 0;
            }
        }

        .c-section__title {
            @include media-breakpoint-down(m) {
                margin-top: $spacer--2xl;
                margin-bottom: 0;
            }
            @include media-breakpoint-only(s) {
                margin-top: $spacer--2xl;
                margin-bottom: $spacer;
            }
        }

        .c-profile-block {
            width: 100%;
            margin-bottom: rem(40);
            padding-left: rem(133);
            @include media-breakpoint-only(m) {
                padding-left: 0;
                width: auto;
            }
            @include media-breakpoint-only(s) {
                padding-left: 0;
                width: auto;
                display: flex;
            }

            .c-profile-block__name {
                font-size: rem(23);
            }

            .c-profile-block__title {
                font-size: rem(18);
                font-weight: 600;
                color: $primary;
            }

            .c-profile-block__content {
                padding-left: rem(33);
            }

            img {
                border-radius: rem(8);
                @include media-breakpoint-only(s) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
