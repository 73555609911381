.c-widget {
    width: 100%;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
}

.c-widget__title {
    @include make-h6;
    text-transform: uppercase;
    display: block;
    padding-bottom: $spacer;
    border-bottom: rem(1) solid $gray;
    margin-bottom: $spacer--l;
}

.c-widget--phone {
    margin-bottom: 1rem;
}
