.c-site-header {
    z-index: 1000;
    background-color: $white;

    @include media-breakpoint-down(m) {
        position: sticky;
        top: 0;
    }
}




.c-site-header--lander {
    border-bottom: 0;
    background-color: $primary;
    box-shadow: none;

    .c-site-header__content {
        padding-top: $spacer--l;
    }

    .c-site-header__phone {
        text-align: right;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        margin-left: 1rem;

        @include media-breakpoint-down(m) {
            box-shadow: none;
        }
    }

    .c-site-header__phone-title {
        @include type-scale(s, 1);
        display: block;
        color: $white;
        text-transform: uppercase;
        font-weight: normal;

        @include media-breakpoint-down(m) {
            display: none;
        }
    }

    .c-site-header__phone-button {
        @include type-scale(xl, 1.2);
        display: block;
        color: $white;
        padding: 0;
        border: 0;

        &:hover {
            color: $white;
        }

        @include media-breakpoint-only(s) {
            @include type-scale(base);
        }
    }


}

.c-site-header--lander-variant {
    border-bottom: 0;
    background-color: $white;
    box-shadow: none;

    .c-site-header__content {
        padding-top: $spacer--l;
    }

    .c-site-header__phone {
        text-align: right;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        margin-left: 1rem;

        @include media-breakpoint-down(m) {
            box-shadow: none;
        }
    }

    .c-site-header__phone-title {
        @include type-scale(s, 1);
        display: block;
        text-transform: uppercase;
        font-weight: normal;

        @include media-breakpoint-down(m) {
            display: none;
        }
    }

    .c-site-header__phone-button {
        @include type-scale(xl, 1.2);
        display: block;
        padding: 0;
        border: 0;

        &:hover {
            color: $white;
        }

        @include media-breakpoint-only(s) {
            @include type-scale(base);
        }
    }

}




.c-site-header__content {
    display: flex;

    @include media-breakpoint-down(m) {
        align-items: stretch;
    }
    @include media-breakpoint-up(l) {
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: $spacer--l;
        padding-top: $spacer--3xl;


    }
}


.c-site-header__button {
    border: 0;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;

    &:hover {
        background: none;
    }
}



.c-site-header__button--mobile {
    @include type-scale(2xl, false);
    border: 0;
    padding: 0 ($grid-gutter-width / 2);
    margin-left: -($grid-gutter-width / 2);


    @include media-breakpoint-up(l) {
        display: none;
    }
}



.c-site-header__left {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    font-size: 0;

    a,
    a:hover {
        text-decoration: none;
    }
    @include media-breakpoint-only(s) {
        max-width: 12rem;
        padding-top: $spacer--s;
        padding-bottom: $spacer--s;
    }
    @include media-breakpoint-only(m) {
        max-width: 15rem;
        padding-top: $spacer;
        padding-bottom: $spacer;
    }
    @include media-breakpoint-down(m) {
        margin-right: auto;
    }
    @include media-breakpoint-up(l) {
        margin-right: $grid-gutter-width;
        max-width: 18rem;
    }
}
.c-site-header__logo {}



.c-site-header__right {
    flex: 0 0 auto;
}
.c-mobile-nav {}

.c-mobile-nav__drawer {
    @include media-breakpoint-down(m) {
        position: fixed;
        right: 100%;
        top: 0;
        height: 100%;
        width: 100%;
        background: $white;
        max-width: 25rem;
        z-index: 2;
        transform: translateX(0%);
        transition: transform 0.2s ease-in-out;
        overflow-y: auto;

        .c-mobile-nav.is-active & {
            transform: translateX(100%);
        }
    }
    @include media-breakpoint-up(l) {
        display: flex;
        align-items: center;
    }
}
.c-mobile-nav__header {
    background: $primary;
    text-align: center;
    margin-bottom: $spacer--l;

    @include media-breakpoint-up(l) {
        display: none;
    }
}
.c-site-header__search-form {
    padding-left: $spacer--l;
    padding-right: $spacer--l;
    margin-bottom: $spacer--l;

    @include media-breakpoint-up(l) {
        display: none;
    }
}
.c-site-header__search-link {
    @include type-scale(s, false);
    display: inline-block;
    color: inherit;
    text-decoration: none;
    line-height: $site-header-height--desktop;
    color: $site-header-color;
    position: relative;
    margin-left: $spacer--s;

    &:hover {
        text-decoration: none;
    }

    @include media-breakpoint-down(m) {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        @include type-scale(base, false);
        margin-left: $spacer;
    }
}

.c-site-header__nav {
    @include media-breakpoint-down(m) {
        padding-left: $spacer--l;
        padding-right: $spacer--l;
        margin-bottom: $spacer--l;

        &:before {
            @include make-h6;
            content: 'Navigation';
            text-transform: uppercase;
            display: block;
            padding-bottom: $spacer;
            border-bottom: rem(1) solid $gray;
            margin-bottom: $spacer--l;
        }
    }
}
.c-mobile-nav__mask {
    position: fixed;
    cursor: pointer;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition:  opacity 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    z-index: 1;

    @include media-breakpoint-up(l) {
        width: 0;
        height: 0;
    }

    .c-mobile-nav.is-active & {
        opacity: 1;
        visibility: visible;
        transition:  opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
    }
}
.c-site-header__button--phone {
    @include media-breakpoint-only(s) {
        @include type-scale(2xl, false);

        .o-icon {
            display: initial;
        }
        .copy {
            display: none;
        }
    }

    @include media-breakpoint-only(m) {
        @include type-scale(l, false);
        width: auto;

        .o-icon {
            display: none;
        }
        .copy {
            display: initial;
        }
    }
    @include media-breakpoint-down(m) {
        padding: 0 ($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
    }
    @include media-breakpoint-up(l) {
        display: none;
    }

    @include media-breakpoint-up(l) {
        @include type-scale(base);
        @include make-button-variant($primary);
        position: absolute;
        top: 0;
        right: 0;
        padding: rem(2) $spacer--s;
        border-radius: 0 0 $spacer--2xs $spacer--2xs;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

    }
}

.c-button--phone-link {
    .o-icon {

        @include type-scale(2xl);

        @include media-breakpoint-up(m) {
            display: none;
        }
    }
}

.c-button--phone-link {
    @include type-scale(m);
    padding: 0;
    margin-right: $spacer--xs;

    &:hover {
        text-decoration: underline;
    }
}


.c-header__phone-context {
    @include type-scale(m);
    opacity: 0.6;
    padding: 0;

    &:hover {
        opacity: 1;
    }

    @include media-breakpoint-down(m) {
        display: none;
    }
}






.no-flexbox,
.no-flexboxlegacy {


    .c-site-header__content {
        // overflow: hidden;
        //
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .c-site-header__right {

        display: inline-block;
        float: right;

    }
}
