.c-modal {
    @include make-card;
    border-radius: $spacer--2xs;
    background-color: $light;
    padding: $spacer--l;
}

.c-modal__header {
    @include make-h3();
    margin-top: $spacer;
    font-weight: bold;
    margin-bottom: $spacer;
    line-height: 1;
}

.c-modal__content {
    @include type-scale(m);
    font-family: $font-family--secondary;  

}

.c-modal--fact-check {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    
}


.c-modal--medically-reviewed {

    .c-modal__content {
        display: flex;
        
    }

    .o-icon {
        font-size: rem(27);
        color: $accent;
        margin-right: $spacer;
    }

    .c-modal__header {
        margin: 0;
        line-height: 1;
    }

    .c-attribution__desc {
        color: $dark;
        font-weight: bold;

    }

    .c-attribution__reviewer {
        border-radius: $site-border-radius;
        border-left: rem(4) solid $accent;
        padding-left: $spacer;
        margin-top: $spacer;
    }

    .c-attribution__reviewer__name {
        font-size: rem(24);
        font-family: $font-family--secondary;
        color: $primary;
        font-weight: bold;
        line-height: normal;
    }

    .c-attribution__reviewer__title {
        @include type-scale(base);
        font-style: italic;

    }
}
