.c-sources {
    margin-top: $spacer--3xl;
    padding-top: $spacer--3xl;
    border-top: rem(1) solid $gray-100;
}

.c-sources__title {
    @include make-h6();
    text-transform: uppercase;
    margin-top: 0;

}

.c-sources__list {
    padding-left: 1.5rem;

    li {
        @include type-scale(s);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
