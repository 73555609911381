.c-hero {
	background-color: $primary;
	padding-top: 2rem;
	padding-bottom: 4rem;
}

.c-breadcrumbs {
	margin-bottom: rem(44);

	@include media-breakpoint-down(m) {
		position: relative;

		&:after {
			content: '';
			display: block;
			width: $spacer--l;
			height: 100%;
			background: linear-gradient(
				to right,
				rgba($primary, 0) 0%,
				rgba($primary, 1) 100%
			);
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

// please for the love everything delete this entire block
// this is what we get for testing only certain templates
.single .c-hero--backpage .c-breadcrumbs {
	margin-bottom: 0;
}

.c-breadcrumbs__list {
	@include media-breakpoint-down(m) {
		width: 100%;
		overflow-x: auto;
		white-space: nowrap;
	}
}

.c-hero__title {
	font-weight: bold;
	color: $white;
}

.c-hero__description {
	// max-width: 40rem;

	@include media-breakpoint-up(l) {
		max-width: 60%;
	}
}

.c-hero__phone {
	text-align: right;
}

.c-hero__phone-title {
	@include type-scale(s, 1);
	display: block;
	color: $white;
	text-transform: uppercase;
	font-weight: normal;
}

.c-hero__phone-button {
	@include type-scale(xl, 1.2);
	display: block;
	color: $white;
	padding: 0;
	border: 0;
}

.c-hero--backpage {
	color: $white;
	background-image: url('/wp-content/uploads/hero-pattern.png');
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
	padding-top: $spacer--xl;
	position: relative;

	@include media-breakpoint-down(m) {
		margin-bottom: $spacer--xl;
		background-size: cover;
	}

	.c-breadcrumbs {
		margin-bottom: rem(44);
	}

	.c-hero__subtitle {
		@include type-scale(s);
		color: $white;
		margin: 0;
		padding-bottom: 0;

		&:after {
			display: none;
		}

		.o-icon {
			margin-right: $spacer--2xs;
		}
	}

	.c-hero__content {
		@include media-breakpoint-down(m) {
			max-width: 40rem;
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-up(l) {
			display: flex;
			justify-content: space-between;
			flex-wrap: no-wrap;
			align-items: center;
			margin-left: -($grid-gutter-width / 2);
			margin-right: -($grid-gutter-width / 2);
		}
	}

	.c-hero__main {
		@include media-breakpoint-up(l) {
			flex: 1 1 auto;
			padding-left: ($grid-gutter-width / 2);
			padding-right: ($grid-gutter-width / 2);
		}
	}

	.c-hero__aside {
		@include media-breakpoint-up(l) {
			flex: 0 0 auto;
			padding-left: ($grid-gutter-width / 2);
			padding-right: ($grid-gutter-width / 2);
			width: rem(350);
			z-index: 1;
		}

		img {
			max-width: 100%;
		}

		@include media-breakpoint-down(m) {
			width: 100%;
			height: 100%;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			position: fixed;
			top: 100%;
			left: 0;
			z-index: 1050;
			background-color: $white;
			transition: top, visibility;
			transition-duration: 0.2s, 0s;
			transition-delay: 0s, 0.2s;
			visibility: hidden;

			.c-hero__image {
				display: none;
			}

			&.is-active {
				top: 0;
				visibility: visible;
				transition-delay: 0s, 0s;
			}
		}
	}

	.c-hero__image {
		border-radius: rem(4) rem(4) 0 0;
	}

	.c-hero__phone {
		@include media-breakpoint-down(m) {
			display: none;
		}
	}
}

.c-hero--lander {
	background-color: $primary;
	color: $white;
	position: relative;

	@include media-breakpoint-down(m) {
		padding-top: $spacer--xl;
	}

	&:after {
		content: '';
		display: block;
		left: 0;
		bottom: 0;
		z-index: 0;
		width: 100%;
		height: 6rem;
		background-color: $white;
		position: absolute;
	}
	.c-hero__content {
		position: relative;
		z-index: 1;
	}

	.c-hero__copy > *:last-child {
		margin-bottom: 0;
	}

	.c-hero__title,
	h1 {
		color: inherit;
		margin-bottom: 1.5rem;
	}

	.c-hero__main {
		@include type-scale(l);
	}

	.c-hero__description {
		@include type-scale(l);
		margin-top: $spacer--l;
		max-width: auto;
		// width: 100%;
		max-width: 100%;
	}

	.c-ul {
		@include type-scale(m, 1.5);
	}

	.c-ul__item {
		margin-bottom: $spacer--xs;
		padding-left: $spacer;
		padding-right: $spacer;

		&:before {
			left: auto;
			transform: translateX(-$spacer);
			top: $spacer--s;
			background-color: $light;
		}
	}

	.c-hero__image {
		margin-top: $spacer;
		max-width: 100%;
		height: auto;
	}

	.c-form__footer {
		.c-button {
			width: 100%;
		}
	}
}

.c-hero--full {
	position: static;
	padding-top: $spacer--2xl;
	padding-bottom: $spacer--2xl;

	&:after {
		display: none;
	}
}

.c-hero--profile {
	// i had to overwrite --backage template
	// because i didn't have time to separate the different versions
	.c-hero__main {
		min-height: auto;
	}

	.c-hero__title {
		color: $white;
	}

	.c-hero__description {
		color: $white;
	}

	.c-hero__before {
		width: auto;

		img {
			margin-bottom: 0;
		}
	}
}

.c-hero--center {
	text-align: center;

	.c-hero__content {
		max-width: 48rem;
		margin-left: auto;
		margin-right: auto;
	}

	.c-hero__main {
		margin-bottom: $spacer--2xl;
	}

	.c-hero__description {
		@include type-scale(m);
		margin-top: $spacer--l;
		margin-left: auto;
		margin-right: auto;
		max-width: 40rem;
	}

	.c-form__radio {
		@include media-breakpoint-up(l) {
			flex-basis: 10rem;
		}
	}
}

.c-hero--split {
	padding-top: $spacer--2xl;
	overflow: hidden;
	padding-bottom: $spacer;

	&:after {
		display: none;
	}

	.c-hero__content {
		@include make-row();
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.c-hero__main {
		@include make-col-ready();
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		max-width: 45rem;

		@include media-breakpoint-down(m) {
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			align-items: center;
		}

		@include media-breakpoint-up(l) {
			width: 60%;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translate(-250vw, -$spacer--6xl);
			width: 1000vw;
			height: 1000vh;
			background: $light;
			z-index: 0;
		}
	}

	.c-hero__image {
		position: relative;
		z-index: 1;
	}

	.c-hero__aside {
		@include make-col-ready();

		@include media-breakpoint-down(m) {
			margin-top: $spacer;
		}

		@include media-breakpoint-up(l) {
			@include make-col-ready();
			width: 40%;
		}
	}
}

.no-flexbox,
.no-flexboxlegacy {
	.c-hero--lander {
		overflow: hidden;
	}

	.c-hero--backpage {
		// overflow: hidden;
	}

	.c-hero--split {
		.c-hero__main {
			display: block;
			float: left;
		}

		.c-hero__aside {
			display: block;
			float: right;
		}
	}
}

.c-hero--featured {
	padding-bottom: 0;

	.c-hero__content {
		position: relative;
	}
	.c-hero__aside {
		@include media-breakpoint-up(l) {
			position: absolute;
			top: 0;
			right: 0;
		}

		@include media-breakpoint-down(m) {
			// display: none;
		}
	}

	.c-aside {
		background-color: $white;

		@include media-breakpoint-up(l) {
			box-shadow: $box-shadow;
		}
	}

	.c-hero__main {
		@include media-breakpoint-up(l) {
			min-height: 19rem;
		}
	}
}

//commented this class out. It does not seem to be used anywhere. Incorect BEM name
/*.c-hero--buttonMobile {

    @include media-breakpoint-up(l) {
        display: none;
    }
}*/

// corrected this BEM name from camel case. It was throwing errors and could stop deployments
.c-hero--no-image {
	@include media-breakpoint-up(l) {
		.c-hero__button {
			display: none;
		}
	}
}

.c-hero--horizontal {
	@include media-breakpoint-down(s) {
		background: linear-gradient(to bottom, $primary 50%, $white 50%);
		padding-bottom: $spacer--xl;

		&::after {
			content: none;
		}

		.c-form__radio {
			flex: auto;
		}

		.c-form__label--radio {
			font-size: rem(14);
		}
	}

	@include media-breakpoint-down(m) {
		.c-picture {
			display: none;
		}

		.c-hero__main {
			margin-bottom: $spacer--xl;
		}
	}

	@include media-breakpoint-up(m) {
		.c-form__step-container {
			.c-form__fields {
				display: flex;
				gap: $spacer;
				align-items: flex-end;
			}
		}
	}

	.c-form .c-disclaimer {
		margin-top: $spacer;
	}

	.c-form__nav {
		max-width: unset;
	}

	.c-hero__main {
		display: flex;
		justify-content: space-between;
	}

	.c-hero__copy {
		max-width: rem(600);
	}

	.c-pro-con__list--pros {
		@include media-breakpoint-up(m) {
			display: flex;
			gap: $spacer--m;
		}

		li {
			font-size: rem(16);

			&::before {
				content: url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="#{svgColor($secondary-light)}" /%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="#{svgColor($secondary-light)}" /%3E%3C/svg%3E');
				margin-right: rem(8);
			}
		}
	}
}

.c-hero--lander.hero--variant-c {
	.c-form__title {
		margin-bottom: 2rem;
	}

	.c-hero__main {
		justify-content: center;
	}

	@include media-breakpoint-up(l) {
		margin-bottom: 4rem;

		.c-pro-con__list--pros {
			flex-direction: column;
		}

		.c-hero__main {
			display: block;
		}

		.c-hero__content {
			display: grid;
			grid-template-columns: 1fr rem(400);
			grid-template-rows: 1fr;
			column-gap: 1.5rem;
		}

		&::after {
			display: none;
		}
	}
}
