.c-backpage-mobile-nav {
    @include media-breakpoint-down(m) {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: $white;
        box-shadow: 0 0 $spacer--s 0 rgba($black, 0.1);
        border-top: rem(1) solid $gray-100;
        z-index: 900;
    }
    @include media-breakpoint-up(l) {
        display: none;
    }
}

.c-backpage-mobile-nav__list {
    padding: $spacer--s 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.c-backpage-mobile-nav__item {
    @include type-scale(xs, 1);
    flex: 1 1 auto;
    padding: 0 $spacer--s;
    text-align: center;
}

.c-backpage-mobile-nav__button {
    @include type-scale(xs, 1);
    @include make-button-variant(
        transparent, 
        transparent,
        $dark,
        transparent, 
        transparent, 
        $dark
    );
    border: none;
    padding: 0;
    border-radius: 0;
    height: auto;
    font-weight: $font-weight--normal;
    min-width: 3rem;

    &.is-active {
        color: $primary;
        font-weight: $font-weight--bold;
    }

    .o-icon {
        @include type-scale(base, 1);
        display: block;
        margin-bottom: $spacer--2xs;
    }
}