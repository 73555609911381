.c-dl {
    background-color: $light;
    border-radius: $site-border-radius;
    border: rem(1) solid $gray-100;
    padding: $spacer--l;

    @include media-breakpoint-up(m) {
        padding: $spacer--2xl;
    }


    .c-wysiwyg.c-dl__content {
        margin-top: 0;
    }
}

.c-dl__group {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $spacer--xl;
    padding-top: $spacer--2xl;
    border-bottom: rem(1) solid $gray-100;


    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border: 0;
        padding-bottom: 0;
    }
}

.c-dl__media {
    flex: 0 0 9.5rem;
    margin-bottom: $spacer--l;

    img {
        border-radius: rem(4);
        border: rem(1) solid $gray-100;
        box-shadow: $box-shadow;
        background: $white;
    }
}

.c-dl__content {
    flex: 1 1 20rem;
    border-bottom: rem(1) solid $gray-100;
    padding-bottom: $spacer;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;


    &:last-child {
        border: 0;
        padding-bottom: 0;
    }

}

.c-dl__title {
    @include make-h3();
    font-family: $font-family--secondary;
    // NOTE: 4/28: removed, was causing a layout issue with definition lists in content. leaving incase it's needed elsewhere and we need to try a different solution. If no issues delete later.
    // flex: 1 0 10rem;
    margin-bottom: $spacer;
    margin-top: 0;
}

.c-dl__definition {
    // NOTE: 4/28: removed, was causing a layout issue with definition lists in content. leaving incase it's needed elsewhere and we need to try a different solution. If no issues delete later.
    // flex: 2 1 25rem;

    .c-wysiwyg {
        margin-top: 0;
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
    }

    .c-button--link {
        margin-top: $spacer;
    }
}

.c-section--box {
    background-color: $light;
    border-radius: rem(4);
    padding-left: $spacer--2xl;
    padding-right: $spacer--2xl;

    &.c-section {
        padding-top: $spacer--2xl;
        padding-bottom: $spacer--2xl;
    }

    &.c-section--center {

        .c-wysiwyg {
            margin: 0;
        }
    }

    .h2 {
        margin-top: 0;
    }

    .c-dl__title {
        @include make-h3();
        margin-bottom: $spacer--l;
    }

    & + .c-section--mobile-only {
        margin-top: $spacer--2xl;
    }
}
