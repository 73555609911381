.c-site-footer {
    background-color: $primary;

    a {
        text-decoration: underline;
    }

    section {
        border-bottom: rem(1) solid color-step($primary, 600);
        margin-bottom: $spacer--2xl;
        padding-bottom: $spacer--2xl;

        &:last-of-type {
            margin-bottom: $spacer--m;
        }
    }
}

.c-site-footer__cols {
    @include make-row();
}

.c-site-footer__col {
    @include make-col-ready();
    @include media-breakpoint-up(m) {
        width: percentage(1/3);
    }
    @include media-breakpoint-only(s) {
        margin-top: 2rem;
    }
    text-align: left;

    strong {
        @include type-scale(m);
        display: block;
        margin-bottom: $spacer--l;
        padding-bottom: $spacer;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            height: rem(2);
            width: rem(24);
            background-color: $secondary-light;
            bottom: rem(0);
            left: 0;
        }
    }

    .c-menu__link,
    .c-button--link {
        display: block;
        margin-bottom: 1rem;
        text-decoration: none;
        opacity: 0.8;

        &:hover {
            text-decoration: underline;
            opacity: 1;
            visibility: visible;
            transition-delay: 0s, 0s;
        }
    }

    .c-button--link {
        @include type-scale(base);
        font-family: $font-family--sans-serif;
        color: $white;
        font-weight: normal;
        padding: 0;
        margin-bottom: 3rem;

        &::after {
            display: none;
        }
    }
}

.c-menu--2nd {
    margin-bottom: 0;
}

.c-site-footer__menu {
    .c-menu__list {
        display: block;
    }

    .c-menu__item {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-site-footer__disclaimer {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: $spacer;
    font-style: normal;
}

.c-site-footer__logo-container {
    width: 75%;
}

.c-site-footer__logo {
    @include media-breakpoint-up(m) {
        margin: 4.3rem 0 0 30rem;
    }
    @include media-breakpoint-only(m) {
        margin: 4.4rem 0 0 15rem;
    }
}

.c-site-footer__certs {
    height: 3rem;

    img {
        max-height: 100%;
        width: auto;
    }
}

.no-flexbox,
.no-flexboxlegacy {
    .c-site-footer {
        .l-container {
            display: block;
        }
    }

    .c-site-footer__cols {
        overflow: hidden;
    }

    .c-site-footer__col {
        display: block;
        float: left;
    }

    .c-site-footer__disclaimer {
        display: block;
        float: none;
    }
}
