.c-tabs {
}

.c-tabs__nav {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.c-tabs__nav-item {
    margin: 0 0 0 0.5rem;
    list-style: none;
}

.c-tabs__nav-link {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
    margin-left: 0.5rem;
    background-color: theme-color('light');
    display: block;
    text-decoration: none;
    color: theme-color('dark');

    &.is-active {
        color: $white;
        background-color: theme-color('dark');
    }

    &:hover {
    }
}

.c-tabs__tabs {
}

.c-tabs__tab {
    display: none;
    border: 0.25rem solid theme-color('dark');
    border-radius: 0.5rem;
    padding: 1.75rem;

    &.is-active {
        display: block;
    }
}

.c-tabs__tab-content {
}
