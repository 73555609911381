// Covid Banner temp
.covid-banner {
    width: 100%;
    background: $light;
    padding-top: rem(10);
    padding-bottom: rem(10);
    padding-left: rem(8);
    padding-right: rem(8);

    p {
        text-align: center;
        margin: 0;
        font-size: rem(14);
        color: $body-color;

        span {
            color: $error;
            font-weight: bold;
        }
    }
}
