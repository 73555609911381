.c-breadcrumbs {
    @include type-scale(s);
    color: rgba(theme-color('dark'), 0.85);

    a {
        color: rgba(theme-color('dark'), 0.85);
        text-decoration: none;

        &:hover {
            color: theme-color('dark');
            text-decoration: underline;
        }
    }
    // sass-lint:disable class-name-format
    .breadcrumb_last {
        color: theme-color('dark');
        font-weight: bold;
    }
    // sass-lint:enable class-name-format
}

.c-breadcrumbs--white {
    color: rgba($white, 0.85);

    a {
        color: rgba($white, 0.85);
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }
    // sass-lint:disable class-name-format
    .breadcrumb_last {
        color: $white;
        font-weight: bold;
    }
    // sass-lint:enable class-name-format
}
