.c-profile-social {
}

.c-profile-social__list {
}

.c-profile-social__item {
    display: inline-block;
}

.c-profile-social__link {
    padding: 0.5rem;

    .o-icon {
        @include type-scale(m);
    }
}
