.c-related-profiles {
    margin-top: 3rem;
}

.c-related-profiles__heading {
    margin-bottom: 2rem;
    text-align: center;
}

.c-related-profiles__list {
    @include make-row;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
        @include make-col-ready;
        width: 50%;
        margin-bottom: 2rem;

        @include media-breakpoint-only(m) {
            width: percentage(1/3);
            margin-bottom: 3rem;
        }
        @include media-breakpoint-up(l) {
            width: percentage(1/4);
            margin-bottom: 3rem;
        }
    }

    .c-profile-block {
        display: flex;
    }
}

.c-related-profiles--card {

    .c-related-profiles__list {
        margin-left: auto;
        margin-right: auto;
    }

    li {
        @include make-col-ready;
        @include make-card;
        @include make-card-hover; 
        flex: 0 0 46%;
        margin: 2%;
        width: auto;
        padding: $spacer;

        @include media-breakpoint-only(m) {
             flex: 0 0 29.33%;
        }
        @include media-breakpoint-up(l) {
            flex: 0 0 21%;
        }
    }

}

.c-related-profiles--two-col {
    
    li {
        flex: 0 1 96%;

        @include media-breakpoint-up(m) {
            flex: 0 1 46%;
        }
    }

}

.c-related-profiles--three-col {

    @include media-breakpoint-up(m) {
        li {
            flex: 0 1 29.33%;
        }
    }
}
