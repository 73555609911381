.c-share {
    margin-top: 3rem;
    border-top: rem(1) solid theme-color('light');
    padding-top: 3rem;
    text-align: center;
}

.c-share__heading {
    margin-bottom: 0.5rem;
    display: block;
}

.c-share__list {
    display: flex;
    justify-content: center;

    @include media-breakpoint-only(s) {
        flex-direction: column;
    }
}

.c-share__item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @include media-breakpoint-only(s) {
        margin-bottom: 1rem;
    }
}

.c-share__link {
    width: 6rem;
    text-align: center;
}

.c-share__link--twitter {
    @include make-button-variant(social-color('twitter'));
}

.c-share__link--facebook {
    @include make-button-variant(social-color('facebook'));
}

.c-share__copy {
    display: none;

    textarea {
        width: 100%;
        max-width: 30rem;
        margin: 0 auto 0.5rem;
        display: block;
        text-align: center;
    }
}
