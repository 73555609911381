.c-alert--browser-update {

    background: #bc1822;
    padding: $spacer--xs;
    box-shadow: 0 rem(2) rem(20) 0 $gray-100;
    display: none;

    a {
        color: $white;
    }

    p {
        @include type-scale(s);
        margin: 0;
        text-align: center;
        color: $white;
    }

    .cssgridlegacy & {
        display: block;
    }
}
