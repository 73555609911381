.c-sources {
    margin-top: 3rem;
    border-top: rem(1) solid theme-color('light');
    padding-top: 3rem;

    &.is-active {
    }
}

.c-sources__title {
    text-align: center;
    margin-bottom: 2rem;
}

.c-sources__lead-in {
    @include type-scale(xs);
    font-style: italic;
}

.c-sources__list-wrapper {
    height: 8rem;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transition: 0.2s ease-in-out all;
    text-align: left;
}

.c-sources__toggle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease-in-out 0s;

    .c-sources__list-wrapper.is-active & {
        opacity: 0;
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
            rgba(#ffffff, 0) 0%,
            rgba(#ffffff, 1) 75%
        );
    }
    .c-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.c-sources__list {
    position: relative;
    z-index: 1;
    list-style: decimal;

    li {
        @include type-scale(xs);
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    a {
        @include make-link;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
    }
}
