.c-profile-block {
    @include media-breakpoint-down(s) {
        display: block;
    }
}
.c-profile-block__image {
    border-radius: 0;

    @include media-breakpoint-down(s) {
        margin-bottom: $spacer;
    }
}

a.c-profile-block__name:before {
    display: none;
}

.c-profile-block__content {
    width: 100%;
}


.c-profile-block--doctor {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacer--2xl;

    .c-profile-block__image-wrapper {
        flex: 0 0 6rem;
        padding-right: $spacer;
    }

    .c-profile-block__content {
        flex: 1 1 25rem;
    }

    .c-profile-block__image {
        border-radius: 100%;
        margin-right: 0;
    }
    
    .c-profile-block__name {
        @include type-scale(m, 1.2);
    }
    .c-profile-block__title {
        @include type-scale(s, 1.2);
        margin-top: $spacer--2xs;
        color: $gray-700;
    }
    .c-profile-block__after {
        @include type-scale(base, 1.75);
    }
}

