table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0;
}

td,
th {
    padding: 0.5rem 1rem;
}

thead {
    th,
    td {
        background-color: $light;
        // border: rem(1) solid $gray;
        color: $body-color;
        font-weight: normal;
        text-transform: uppercase;
        border-bottom: rem(1) solid $gray;
    }
}

tbody {

    background: $light;

    th,
    td {
        border-top: rem(1) solid $gray;
        border-bottom: rem(1) solid $gray;
    }


    tr:last-child {
        td,
        th {
            border-bottom: 0;
        }
    }
}
