@charset "UTF-8";
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.75;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", Arial;
  font-weight: 400;
}

@-ms-viewport {
  width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  color: #424242;
  text-align: left;
  background-color: #ffffff;
  min-width: 20rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.75;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 0.75em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2270c3;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: rgb(9.0234375, 40.90625, 67.9765625);
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: rgb(91, 78.52, 78.52);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 0.125rem dotted;
  outline: 0.25rem auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -0.125rem;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

@media (max-width: 54.9375em) {
  html.admin {
    margin-top: 0 !important;
  }
}

@media (max-width: 54.9375em) {
  #wpadminbar {
    display: none !important;
  }
}

.c-button {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: #303740;
  padding: 0.875rem 1.875rem;
  color: #303740;
  background-color: #ffffff;
  text-transform: none;
  letter-spacing: 0.0625rem;
  font-weight: 600;
  font-family: Lato, Arial;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.u-hover-children:hover .c-button, .c-button:hover {
  text-decoration: none;
  border-color: rgb(178.5, 178.5, 178.5);
  background-color: rgb(178.5, 178.5, 178.5);
  color: #303740;
}
.c-button:focus {
  outline: 0;
}
.c-button .o-icon {
  line-height: inherit;
  font-size: inherit;
  display: inline-block;
}

.c-button--primary {
  background-color: #0f4471;
  border-color: #0f4471;
  color: #ffffff;
}
.u-hover-children:hover .c-button--primary, .c-button--primary:hover {
  background-color: rgb(10.5, 47.6, 79.1);
  border-color: rgb(10.5, 47.6, 79.1);
  color: #ffffff;
}

.c-button--secondary {
  background-color: #1e8527;
  border-color: #1e8527;
  color: #ffffff;
}
.u-hover-children:hover .c-button--secondary, .c-button--secondary:hover {
  background-color: rgb(21, 93.1, 27.3);
  border-color: rgb(21, 93.1, 27.3);
  color: #ffffff;
}

.c-button--accent {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}
.u-hover-children:hover .c-button--accent, .c-button--accent:hover {
  background-color: rgb(17.5, 110.6, 154);
  border-color: rgb(17.5, 110.6, 154);
  color: #ffffff;
}

.c-button--success {
  background-color: #00ff00;
  border-color: #00ff00;
  color: #ffffff;
}
.u-hover-children:hover .c-button--success, .c-button--success:hover {
  background-color: rgb(0, 178.5, 0);
  border-color: rgb(0, 178.5, 0);
  color: #ffffff;
}

.c-button--error {
  background-color: #d03232;
  border-color: #d03232;
  color: #ffffff;
}
.u-hover-children:hover .c-button--error, .c-button--error:hover {
  background-color: rgb(145.6, 35, 35);
  border-color: rgb(145.6, 35, 35);
  color: #ffffff;
}

.c-button--light {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  color: #303740;
}
.u-hover-children:hover .c-button--light, .c-button--light:hover {
  background-color: rgb(173.6, 173.6, 173.6);
  border-color: rgb(173.6, 173.6, 173.6);
  color: #303740;
}

.c-button--dark {
  background-color: #303740;
  border-color: #303740;
  color: #ffffff;
}
.u-hover-children:hover .c-button--dark, .c-button--dark:hover {
  background-color: rgb(33.6, 38.5, 44.8);
  border-color: rgb(33.6, 38.5, 44.8);
  color: #ffffff;
}

.c-button--twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.u-hover-children:hover .c-button--twitter, .c-button--twitter:hover {
  background-color: rgb(20.3, 112.7, 169.4);
  border-color: rgb(20.3, 112.7, 169.4);
  color: #ffffff;
}

.c-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.u-hover-children:hover .c-button--facebook, .c-button--facebook:hover {
  background-color: rgb(41.3, 62.3, 106.4);
  border-color: rgb(41.3, 62.3, 106.4);
  color: #ffffff;
}

.c-button--google-plus {
  background-color: #ea4335;
  border-color: #ea4335;
  color: #ffffff;
}
.u-hover-children:hover .c-button--google-plus, .c-button--google-plus:hover {
  background-color: rgb(163.8, 46.9, 37.1);
  border-color: rgb(163.8, 46.9, 37.1);
  color: #ffffff;
}

.c-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
  color: #ffffff;
}
.u-hover-children:hover .c-button--linkedin, .c-button--linkedin:hover {
  background-color: rgb(0, 83.3, 126.7);
  border-color: rgb(0, 83.3, 126.7);
  color: #ffffff;
}

.c-button--pinterest {
  background-color: #bd081c;
  border-color: #bd081c;
  color: #ffffff;
}
.u-hover-children:hover .c-button--pinterest, .c-button--pinterest:hover {
  background-color: rgb(132.3, 5.6, 19.6);
  border-color: rgb(132.3, 5.6, 19.6);
  color: #ffffff;
}

.c-button--instagram {
  background-color: #f56040;
  border-color: #f56040;
  color: #ffffff;
}
.u-hover-children:hover .c-button--instagram, .c-button--instagram:hover {
  background-color: rgb(171.5, 67.2, 44.8);
  border-color: rgb(171.5, 67.2, 44.8);
  color: #ffffff;
}

.c-button--outline {
  background-color: transparent;
  border-color: #0f4471;
  color: #0f4471;
}
.u-hover-children:hover .c-button--outline, .c-button--outline:hover {
  background-color: #0f4471;
  border-color: #0f4471;
  color: #ffffff;
}

.c-button--gradient {
  background-image: linear-gradient(90deg, #084c7f, #34c6f4, #084c7f);
  background-size: 200% 200%;
  background-position: left;
  border-color: transparent;
  color: #ffffff;
}
.u-hover-children:hover .c-button--gradient, .c-button--gradient:hover {
  border-color: transparent;
  background-position: right;
  color: #ffffff;
}

.c-button--xs {
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.75rem;
  border-width: 0.0625rem;
  padding: 0.1875rem 0.5rem;
  letter-spacing: 0.03125rem;
  font-weight: normal;
}

.c-button--s {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  border-radius: 1.5rem;
  border-width: 0.125rem;
  padding: 0.375rem 1rem;
  letter-spacing: 0.0625rem;
  font-weight: normal;
}

.c-button--l {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  border-radius: 2rem;
  border-width: 0.25rem;
  padding: 1rem 2.5rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}
@media (min-width: 35em) {
  .c-button--l {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-button--l {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.c-button--link {
  background: none;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;
  color: #0f4471;
  font-weight: bold;
}
@supports (text-decoration-color: transparent) {
  .c-button--link {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.u-hover-children:hover .c-button--link, .c-button--link:hover {
  color: rgb(10.5, 47.6, 79.1);
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
}

.c-button--line {
  background: none;
  border-color: transparent;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0;
  padding: 0.25rem 0.5rem;
  border-bottom: 0.125rem solid #ffffff;
  border-left: 0;
  border-right: 0;
}
.u-hover-children:hover .c-button--line, .c-button--line:hover {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: #ffffff;
}

.c-button--icon {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  padding: 0;
  border: 0;
  font-size: inherit;
  line-height: 1;
}
.c-button--icon.c-button--xs {
  width: 1.5rem;
  height: 1.5rem;
}
.c-button--icon.c-button--s {
  width: 2rem;
  height: 2rem;
}

.c-button--ghost {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
}
.u-hover-children:hover .c-button--ghost, .c-button--ghost:hover {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}

p + .c-button {
  margin-top: 2.5rem;
}

.c-button {
  letter-spacing: 0;
  font-family: "Poppins", "Lato", Arial;
}

.c-button--outline {
  background-color: #ffffff;
  border-color: #303740;
  color: #303740;
}
.u-hover-children:hover .c-button--outline, .c-button--outline:hover {
  background-color: #303740;
  border-color: #303740;
  color: #ffffff;
}

.c-button--secondary-light {
  background-color: #18d8b8;
  border-color: #18d8b8;
  color: #303740;
}
.u-hover-children:hover .c-button--secondary-light, .c-button--secondary-light:hover {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}

.c-button--white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0f4471;
}
.u-hover-children:hover .c-button--white, .c-button--white:hover {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}

.c-button--outline-white {
  background-color: transparent;
  border-color: #e6edf2;
  color: #e6edf2;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: bold;
  padding-left: 1.875rem;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  width: fit-content;
}
.u-hover-children:hover .c-button--outline-white, .c-button--outline-white:hover {
  background-color: rgb(217.56, 217.56, 217.56);
  border-color: rgb(217.56, 217.56, 217.56);
  color: #0f4471;
}
.c-button--outline-white .c-button__icon {
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 100%;
  color: #199edc;
  margin-right: 0.25rem;
  position: absolute;
  left: 0.5rem;
}
.c-button--outline-white.c-button--xs {
  border-radius: 0.25rem;
  line-height: 1;
}
.c-button--outline-white.c-button--xs .c-button__icon {
  line-height: 1;
}

.c-button--ghost {
  box-shadow: none;
}
.c-button--ghost.c-button--white {
  color: #ffffff;
  background: transparent;
  border-color: transparent;
}
.c-button--ghost.c-button--white:hover {
  color: #ffffff;
  background: transparent;
  border-color: transparent;
}

.c-button--link {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  background-color: transparent;
  border-color: transparent;
  color: #2270c3;
  height: auto;
  border: 0;
  text-decoration: none;
  text-align: left;
  position: relative;
  line-height: 1.875rem;
  font-family: "Poppins", "Lato", Arial;
}
@media (min-width: 35em) {
  .c-button--link {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-button--link {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
.u-hover-children:hover .c-button--link, .c-button--link:hover {
  background-color: transparent;
  border-color: transparent;
  color: #199edc;
}
.c-button--link:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.875rem;
  content: "\e90a";
  border-radius: 100%;
  color: #199edc;
  height: 1.875rem;
  width: 1.875rem;
  padding-left: 0.5rem;
  text-align: center;
  font-weight: bold;
  display: inline-block;
}
.c-button--link:hover {
  text-decoration: underline;
}
@media (min-width: 55em) {
  .c-button--link {
    line-height: 1.875rem;
  }
}
@media (max-width: 54.9375em) {
  .c-button--link {
    line-height: 1.875rem;
  }
}
.no-flexboxlegacy .c-button--link:before {
  padding-top: 0.875rem;
}

.c-cta.c-cta--button-inverted {
  background: #0f4471;
  border-radius: 0 0 0.25rem 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}
.c-cta.c-cta--button-inverted .c-cta__button {
  margin-top: 0;
}
.c-cta.c-cta--button-inverted .c-button {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  font-size: 1rem;
  height: auto;
  border: 0;
  padding-left: 2.5rem;
  text-decoration: none;
  text-align: left;
  position: relative;
  line-height: 1.875rem;
  font-family: "Poppins", "Lato", Arial;
}
.u-hover-children:hover .c-cta.c-cta--button-inverted .c-button, .c-cta.c-cta--button-inverted .c-button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
}
.c-cta.c-cta--button-inverted .c-button:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.875rem;
  content: "\e90a";
  border-radius: 100%;
  background-color: #199edc;
  color: #ffffff;
  position: absolute;
  left: 0;
  height: 1.875rem;
  width: 1.875rem;
  text-align: center;
  font-weight: bold;
}
@media (min-width: 55em) {
  .c-cta.c-cta--button-inverted .c-button {
    line-height: 1.875rem;
  }
}
@media (max-width: 54.9375em) {
  .c-cta.c-cta--button-inverted .c-button {
    line-height: 1.875rem;
  }
}
.c-cta.c-cta--button-inverted:hover {
  background-color: rgb(13.08, 57.12, 94.92);
}
.no-flexboxlegacy .c-cta.c-cta--button-inverted .c-button:before {
  padding-top: 0.875rem;
}

.c-button--menu {
  background-color: transparent;
  border-color: transparent;
  color: #0f4471;
  font-size: 1.125rem;
  line-height: 1.7777777778;
  border: 0;
  border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.u-hover-children:hover .c-button--menu, .c-button--menu:hover {
  background-color: transparent;
  border-color: transparent;
  color: #199edc;
}
@media (min-width: 35em) {
  .c-button--menu {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-button--menu {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-button--menu:hover {
  border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}

.c-button--xs .o-icon {
  line-height: 1;
}

.c-button--rounded {
  border-radius: 0.25rem;
}

.c-button--icon {
  line-height: 3rem;
}

.c-button--icon[href*=facebook] {
  color: #0f4471;
}
.c-button--icon[href*=facebook]:hover {
  background-color: rgba(255, 255, 255, 0.66);
}

.c-button--icon[href*=twitter] {
  color: #0f4471;
}
.c-button--icon[href*=twitter]:hover {
  background-color: rgba(255, 255, 255, 0.66);
}

.c-button--l {
  letter-spacing: normal;
}

.c-button--icon-only {
  background-color: transparent;
  border-color: transparent;
  color: #000000;
  box-shadow: none;
  border: solid 0.125rem #0f4471;
  padding: 0.625rem 0.6875rem;
  margin-top: 0;
  border-radius: 3.125rem;
  color: #0f4471;
}
.u-hover-children:hover .c-button--icon-only, .c-button--icon-only:hover {
  background-color: transparent;
  border-color: #199edc;
  color: #0f4471;
}
.c-button--icon-only .c-button__icon--after {
  margin: 0;
}
.c-button--icon-only:hover {
  box-shadow: none;
}

.c-form__label {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.c-form__label--error {
  color: #d03232;
  display: none;
}
.c-form__group.has-error .c-form__label--error {
  display: block;
}

.c-form__control,
.c-form__textarea {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 0.4375rem;
  color: rgb(71, 54.36, 54.36);
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: 3rem;
  border: 0.0625rem solid rgb(200.92, 200.92, 200.92);
}
.c-form__control::-ms-expand,
.c-form__textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
.c-form__group.has-error .c-form__control,
.c-form__group.has-error .c-form__textarea {
  border-color: rgba(208, 50, 50, 0.5);
}

.c-form__textarea {
  height: 6rem;
}

.c-form__checkbox-input,
.c-form__radio-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.c-form__label--checkbox,
.c-form__label--radio {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
}

.c-form__label--checkbox:before,
.c-form__label--radio:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  width: 1.25rem;
  text-align: left;
}

.c-form__label--checkbox:before {
  content: "\f096";
}

.c-form__label--radio:before {
  content: "\e905";
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
  content: "\f046";
}

.c-form__radio-input:checked + .c-form__label--radio:before {
  content: "\e904";
}

.c-form__label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: #424242;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-form__label {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-form__label {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.c-form__label--error {
  color: #d03232;
}

.c-form__label--checkbox:before {
  display: none;
}

.c-form__label--radio:before {
  display: none;
}

.c-form__checkbox-input:checked + .c-form__label--checkbox:before {
  display: none;
}

.c-form__radio-input:checked + .c-form__label--radio:before {
  display: none;
}

.c-form__radio-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-bottom: -1rem;
}

.c-form__radio {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  width: 50%;
  flex: 1 1 12rem;
}

.c-form__label--radio {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  border-radius: 0.25rem;
  border: 0.0625rem solid #0f4471;
  background-color: #ffffff;
  color: #0f4471;
  display: block;
  width: 100%;
  padding: 0.75rem 0.75rem;
  text-align: center;
  margin-bottom: 0;
  color: #0f4471;
  font-weight: bold;
  font-family: "Poppins", "Lato", Arial;
}
@media (min-width: 35em) {
  .c-form__label--radio {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-form__label--radio {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-form__label--radio:hover {
  border-color: #0f4471;
  box-shadow: 0 0 0 0.0625rem #0f4471;
  color: #0f4471;
}

.c-form__radio-input:checked + .c-form__label--radio {
  border-color: #0f4471;
  background-color: #0f4471;
  color: #ffffff;
}

.c-form__label--error {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  margin-top: 0.5rem;
  color: rgb(180.48, 42, 42);
}

.c-form__step {
  display: block;
}
.c-form__step .one-step {
  margin-top: 1.5rem;
}

.c-form__control,
.c-form__textarea {
  border-color: #0f4471;
  color: #424242;
}
.c-form__control:focus,
.c-form__textarea:focus {
  border-color: #0f4471;
  border-radius: 0.25rem;
  outline: none;
  box-shadow: 0 0 0 0.0625rem #0f4471, 0 0 0.25rem 0 rgba(15, 68, 113, 0.4);
}

/*!
 * Hamburger for ARES
*/
.c-hamburger {
  display: block;
  cursor: pointer;
  width: 2rem;
  height: 1.125rem;
  position: relative;
}

@media (max-width: 34.9375em) {
  .c-hamburger {
    width: 1.5rem;
    height: 0.75rem;
  }
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  background-color: currentColor;
  position: absolute;
  left: 0;
}
@media (max-width: 34.9375em) {
  .c-hamburger__bar {
    height: 0.125rem;
    border-radius: 0.0625rem;
    margin-top: -0.0625rem;
  }
}
@media (min-width: 35em) {
  .c-hamburger__bar {
    height: 0.1875rem;
    border-radius: 0.09375rem;
    margin-top: -0.09375rem;
  }
}

.c-hamburger__bar--1,
.c-hamburger__bar--3 {
  transition: transform 0.1s ease, top 0.1s ease 0.1s;
}
.c-hamburger.is-active .c-hamburger__bar--1,
.c-hamburger.is-active .c-hamburger__bar--3 {
  transition: transform 0.1s ease 0.1s, top 0.1s ease;
}

.c-hamburger__bar--1 {
  top: 0%;
  transform: rotate(0deg);
}
.c-hamburger.is-active .c-hamburger__bar--1 {
  top: 50%;
  transform: rotate(45deg);
}

.c-hamburger__bar--2 {
  top: 50%;
  opacity: 1;
  transition: opacity 0.1s ease 0.1s;
}
.c-hamburger.is-active .c-hamburger__bar--2 {
  opacity: 0;
  transition: opacity 0.1s ease 0.1s;
}

.c-hamburger__bar--3 {
  top: 100%;
  transform: rotate(0deg);
}
.c-hamburger.is-active .c-hamburger__bar--3 {
  top: 50%;
  transform: rotate(-45deg);
}

h1,
.h1 {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
}
@media (min-width: 35em) {
  h1,
  .h1 {
    font-size: 2.0625rem;
    line-height: 1.2121212121;
  }
}
@media (min-width: 55em) {
  h1,
  .h1 {
    font-size: 2.5625rem;
    line-height: 1.1707317073;
  }
}

h2,
.h2 {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1rem;
}
@media (min-width: 35em) {
  h2,
  .h2 {
    font-size: 1.6875rem;
    line-height: 1.1851851852;
  }
}
@media (min-width: 55em) {
  h2,
  .h2 {
    font-size: 2rem;
    line-height: 1.25;
  }
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
@media (min-width: 35em) {
  h3,
  .h3 {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  h3,
  .h3 {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
@media (min-width: 35em) {
  h4,
  .h4 {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  h4,
  .h4 {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.1764705882;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
}
@media (min-width: 35em) {
  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  h5,
  .h5 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
}

.c-link-list {
  padding-left: 1rem;
  list-style-type: none;
}

.c-ol {
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.c-ol__item {
  margin-bottom: 0.75rem;
}
.c-ol__item:last-child {
  margin-bottom: 0;
}

.c-ul {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.c-ul__item {
  list-style: none;
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1.25rem;
}
.c-ul__item::before {
  content: "";
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 100%;
  left: 0;
  top: 0.6rem;
  background-color: #979797;
}

@media (min-width: 35em) {
  .c-ul--split {
    column-count: 2;
    column-gap: 2.5rem;
  }
}

.c-ul--doctors {
  border: 0.0625rem solid rgb(200.92, 200.92, 200.92);
  background-color: #f8f8f8;
  border-radius: 0.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.c-ul--doctors .c-ul__item {
  display: flex;
  flex-wrap: wrap;
  border-top: 0.0625rem solid rgb(200.92, 200.92, 200.92);
  padding: 1.5rem 0;
  margin: 0;
}
.c-ul--doctors .c-ul__item:before {
  display: none;
}
.c-ul--doctors .c-ul__item:first-child {
  border-top: none;
}
.c-ul--doctors .c-ul__item strong,
.c-ul--doctors .c-ul__item span {
  flex: 1 1 15rem;
}
.c-ul--doctors .c-ul__item strong {
  padding-right: 0.5rem;
}

.c-dl {
  background-color: #f8f8f8;
  border-radius: 0.25rem;
  border: 0.0625rem solid rgb(217.56, 217.56, 217.56);
  padding: 1.5rem;
}
@media (min-width: 35em) {
  .c-dl {
    padding: 2.5rem;
  }
}
.c-dl .c-wysiwyg.c-dl__content {
  margin-top: 0;
}

.c-dl__group {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  padding-top: 2.5rem;
  border-bottom: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}
.c-dl__group:first-child {
  padding-top: 0;
}
.c-dl__group:last-child {
  border: 0;
  padding-bottom: 0;
}

.c-dl__media {
  flex: 0 0 9.5rem;
  margin-bottom: 1.5rem;
}
.c-dl__media img {
  border-radius: 0.25rem;
  border: 0.0625rem solid rgb(217.56, 217.56, 217.56);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background: #ffffff;
}

.c-dl__content {
  flex: 1 1 20rem;
  border-bottom: 0.0625rem solid rgb(217.56, 217.56, 217.56);
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}
.c-dl__content:last-child {
  border: 0;
  padding-bottom: 0;
}

.c-dl__title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: "Poppins", "Lato", Arial;
  margin-bottom: 1rem;
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-dl__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-dl__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-dl__definition .c-wysiwyg {
  margin-top: 0;
  margin-bottom: 0;
}
.c-dl__definition p {
  margin-top: 0;
}
.c-dl__definition .c-button--link {
  margin-top: 1rem;
}

.c-section--box {
  background-color: #f8f8f8;
  border-radius: 0.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.c-section--box.c-section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.c-section--box.c-section--center .c-wysiwyg {
  margin: 0;
}
.c-section--box .h2 {
  margin-top: 0;
}
.c-section--box .c-dl__title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 35em) {
  .c-section--box .c-dl__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-section--box .c-dl__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-section--box + .c-section--mobile-only {
  margin-top: 2.5rem;
}

.c-faq__title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 35em) {
  .c-faq__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-faq__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-logo {
  display: block;
  text-indent: -10000rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 0;
  padding-top: 16.1354581673%;
  background-image: url("../assets/img/logos/logo-dark.svg");
}

.c-logo--light {
  color: #f8f8f8;
  background-image: url("../assets/img/logos/logo-light.svg");
}

.c-hummingbird {
  font-size: 1.125rem;
  line-height: 1.5555555556;
}
@media (min-width: 35em) {
  .c-hummingbird {
    font-size: 1.1875rem;
    line-height: 1.4736842105;
  }
}
@media (min-width: 55em) {
  .c-hummingbird {
    font-size: 1.25rem;
    line-height: 1.6;
  }
}

.c-caption {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  font-style: italic;
}
@media (min-width: 35em) {
  .c-caption {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-caption {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-disclaimer {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  font-style: italic;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

td,
th {
  padding: 0.5rem 1rem;
}

thead th,
thead td {
  background-color: #0f4471;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}

tbody td,
tbody th {
  border-bottom: 0.0625rem solid #f8f8f8;
}
tbody tr:last-child td,
tbody tr:last-child th {
  border-bottom: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
}

td,
th {
  padding: 0.5rem 1rem;
}

thead th,
thead td {
  background-color: #f8f8f8;
  color: #424242;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: 0.0625rem solid #979797;
}

tbody {
  background: #f8f8f8;
}
tbody th,
tbody td {
  border-top: 0.0625rem solid #979797;
  border-bottom: 0.0625rem solid #979797;
}
tbody tr:last-child td,
tbody tr:last-child th {
  border-bottom: 0;
}

.c-timeline {
  padding-left: 0;
  margin-bottom: 0;
}

.c-timeline__item {
  padding-left: 2.5rem;
  position: relative;
  list-style: none;
  padding-bottom: 1rem;
}
.c-timeline__item:last-child {
  padding-bottom: 0;
}
.c-timeline__item:before {
  content: "";
  display: block;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  border: 0.375rem solid #ffffff;
  background-color: #303740;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.c-timeline__item:after {
  content: "";
  display: block;
  width: 0.125rem;
  height: 100%;
  position: absolute;
  left: 0.6875rem;
  top: 0;
  background-color: #f8f8f8;
  z-index: 1;
}

.c-pro-con {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
}
@media (min-width: 35em) {
  .c-pro-con {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    flex-wrap: nowrap;
  }
}
.c-pro-con .c-ul {
  padding-left: 0;
  margin-top: 0;
  list-style-type: none;
}
.c-pro-con .c-ul__item {
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 2rem;
  position: relative;
}
.c-pro-con .c-ul__item:first-child {
  margin-top: 0;
}
.c-pro-con .c-ul__item:before {
  font-size: 1.0625rem;
  line-height: 0.9411764706;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  font-size: 1rem;
  line-height: 1.5;
  top: 0;
  left: 0;
}
@media (min-width: 35em) {
  .c-pro-con .c-ul__item:before {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-pro-con .c-ul__item:before {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.c-pro-con__list {
  width: 100%;
  padding: 1.5rem;
}
.c-pro-con__list > :first-child {
  margin-top: 0;
}
.c-pro-con__list > *:last-child {
  margin-bottom: 0;
}

.c-pro-con__list--cons .c-ul__item:before {
  content: "\f00d";
  color: #d03232;
}

.c-pro-con__list--pros .c-ul__item:before {
  content: "\e916";
  color: #00ff00;
}

.c-pro-con__title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin-bottom: 1.5rem;
  display: block;
}
@media (min-width: 35em) {
  .c-pro-con__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-pro-con__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
.c-pro-con__list--pros .c-pro-con__title {
  color: #00ff00;
}
.c-pro-con__list--cons .c-pro-con__title {
  color: #d03232;
}

.c-alert--browser-update {
  background: #bc1822;
  padding: 0.5rem;
  box-shadow: 0 0.125rem 1.25rem 0 rgb(217.56, 217.56, 217.56);
  display: none;
}
.c-alert--browser-update a {
  color: #ffffff;
}
.c-alert--browser-update p {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin: 0;
  text-align: center;
  color: #ffffff;
}
.cssgridlegacy .c-alert--browser-update {
  display: block;
}

.c-banner {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0.25rem;
}

.c-banner__content {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  padding: 0.25rem 0.75rem;
  flex-grow: 1;
}

.c-banner__close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-banner--dark {
  background-color: #303740;
  color: #ffffff;
}

.c-banner--fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transform: translateY(-100%);
  transition: 0.2s transform ease-in-out;
}
.c-banner--fixed.is-active {
  transform: translateY(0%);
}

.c-blockquote {
  max-width: 40rem;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
}

.c-blockquote__image {
  display: block;
  margin: 0 auto;
  border-radius: 100%;
}

.c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  margin: 1rem auto;
  font-family: "Poppins", "Lato", Arial;
}
@media (min-width: 35em) {
  .c-blockquote__quote {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

.c-blockquote__title {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: block;
}
@media (min-width: 35em) {
  .c-blockquote__title {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-blockquote__title {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-blockquote__subtitle {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  color: rgb(131, 126.84, 126.84);
}

.c-blockquote__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.5rem;
}

.c-blockquote--left {
  text-align: left;
}

.c-blockquote--right {
  text-align: right;
}

.c-blockquote--small .c-blockquote__quote {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 35em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-blockquote--small .c-blockquote__quote {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-blockquote--small .c-blockquote__title {
  font-size: 0.875rem;
  line-height: 1.7142857143;
}
.c-blockquote--small .c-blockquote__subtitle {
  font-size: 0.75rem;
  line-height: 1.6666666667;
}

.c-blockquote__title {
  font-weight: normal;
}
.c-blockquote__title:before {
  content: "—";
  padding-right: 1rem;
}

.c-blockquote .c-blockquote__quote {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  font-weight: bold;
}
@media (min-width: 35em) {
  .c-blockquote .c-blockquote__quote {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-blockquote .c-blockquote__quote {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-blockquote .c-blockquote__quote {
  max-width: 50rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0;
  position: relative;
}
.c-blockquote .c-blockquote__quote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  color: #0f4471;
  content: "\e90f";
  top: 0;
  left: 0;
}
.c-blockquote .c-blockquote__quote:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  color: #0f4471;
  content: "\e90e";
  top: 0;
  right: 0;
}

.c-blockquote--primary {
  position: relative;
  background-color: #0f4471;
  background-image: url("../assets/img/cta-pattern.png");
  background-repeat: no-repeat;
  background-position: top right;
  color: #ffffff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: none;
  margin-top: 0;
}
.c-blockquote--primary:before {
  background-image: url("../assets/img/cta-pattern-corner.png");
  background-repeat: no-repeat;
  background-position: bottom left;
  content: "";
  position: absolute;
  width: 4.625rem;
  height: 2.1875rem;
  left: 0;
  bottom: 0;
}
.c-blockquote--primary .c-blockquote__quote {
  max-width: 50rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0;
  position: relative;
}
.c-blockquote--primary .c-blockquote__quote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  color: #199edc;
  content: "\e90f";
  top: 0;
  left: 0;
}
.c-blockquote--primary .c-blockquote__quote:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  color: #199edc;
  content: "\e90e";
  top: 0;
  right: 0;
}

.c-blockquote--secondary {
  position: relative;
  background-color: #f1f4f6;
  background-image: url("/wp-content/uploads/content-bg-2.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  color: #0d1b26;
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-weight: normal;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: none;
  margin-top: 0;
}
.c-page--lander .c-blockquote--secondary {
  margin-bottom: 0;
}
@media (max-width: 34.9375em) {
  .c-blockquote--secondary {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.5rem;
    margin-bottom: 0;
  }
}
.c-blockquote--secondary .c-blockquote__footer {
  text-align: left;
  margin: 0 21.5rem;
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-blockquote--secondary .c-blockquote__footer {
    margin: 0 8.5rem;
  }
}
@media (max-width: 54.9375em) {
  .c-blockquote--secondary .c-blockquote__footer {
    margin: 0 2rem;
  }
}
@media (max-width: 34.9375em) {
  .c-blockquote--secondary .c-blockquote__footer {
    margin: 0;
    padding: 0 1rem;
  }
}
.c-blockquote--secondary .c-blockquote__title {
  font-weight: bold;
}
.c-blockquote--secondary .c-blockquote__quote {
  max-width: 50rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-top: 0;
  position: relative;
  text-align: left;
  font-family: "Lato", Arial;
  font-weight: 400;
  font-style: italic;
}
@media (max-width: 34.9375em) {
  .c-blockquote--secondary .c-blockquote__quote {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.c-blockquote--secondary .c-blockquote__quote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  color: #199edc;
  content: "\e90f";
  top: 0;
  left: -3.75rem;
  font-size: 5rem;
}
@media (max-width: 34.9375em) {
  .c-blockquote--secondary .c-blockquote__quote:before {
    top: -1.5rem;
    left: 1rem;
    font-size: 1.25rem;
  }
}
.c-blockquote--secondary .c-blockquote__quote:after {
  display: none;
}

.c-blockquote--gradient-blue {
  background: linear-gradient(to right, #0f4471, #2270c3);
}
.c-blockquote--gradient-blue.c-blockquote--primary:before {
  background-image: url("/wp-content/uploads/bg-pattern-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.c-action-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  padding: 2rem;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #424242;
  margin-left: auto;
  margin-right: auto;
}
.c-action-block:hover {
  text-decoration: none;
  color: #424242;
}

.c-action-block__image {
  margin-bottom: 1rem;
}

.c-action-block__title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
@media (min-width: 35em) {
  .c-action-block__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-action-block__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.c-action-block__copy {
  color: inherit;
}

.c-action-block__button {
  margin-top: 1rem;
}

.c-action-block--card {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  max-width: none;
}

a.c-action-block--card {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
}
a.c-action-block--card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}

.c-action-block--white {
  background-color: #ffffff;
}

.c-action-block--light {
  background-color: #f8f8f8;
}

.c-action-block--card-image-left {
  text-align: left;
}
.c-action-block--card-image-left .c-action-block__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.c-action-block--card-image-left .c-action-block__image {
  flex: 0 0 4rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__title {
  flex: 1 1 auto;
  padding-left: 1rem;
  margin: 0;
}
.c-action-block--card-image-left .c-action-block__copy {
  flex: 0 1 100%;
  margin-top: 1rem;
}

.c-action-block__title {
  margin-top: 0;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  display: block;
}
.c-action-block__media + .c-action-block__content .c-action-block__title {
  margin-bottom: 0.125rem;
}
.c-action-block__media + .c-action-block__content .c-action-block__title:after {
  display: none;
}

h2.c-action-block__title {
  padding-bottom: 0;
}
h2.c-action-block__title:after {
  display: none;
}

.c-action-block__content,
.c-action-block__title,
.c-action-block__copy {
  max-width: 100%;
}

@media (max-width: 34.9375em) {
  .c-action-block__button--chat {
    display: none;
  }
}

@media (min-width: 35em) {
  .c-action-block__button--phone {
    display: none;
  }
}

.c-post-block {
  max-width: 30rem;
  margin-bottom: 2rem;
  position: relative;
}
.c-post-block a {
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.c-post-block__image {
  width: 100%;
  display: block;
}

.c-post-block__content {
  padding: 1.5rem 0;
}

.c-post-block__category {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: rgb(131, 126.84, 126.84);
  margin-bottom: 0.25rem;
  display: inline-block;
}
.c-post-block__category:hover {
  text-decoration: underline;
}

a.c-post-block__link {
  position: inherit;
  z-index: 0;
}
a.c-post-block__link:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.c-post-block__title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 35em) {
  .c-post-block__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-post-block__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@supports (text-decoration-color: transparent) {
  .c-post-block__title {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
}
.c-post-block__link:hover .c-post-block__title {
  color: #0f4471;
  text-decoration: underline;
  text-decoration-color: #0f4471;
}

.c-post-block__meta {
  margin-top: 1rem;
}

.c-post-block__button {
  margin-top: 1rem;
}

.c-post-block--card .c-post-block__inner {
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
  border-radius: 1rem;
}
.c-post-block--card .c-post-block__inner:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}
.c-post-block--card .c-post-block__image {
  border-radius: 1rem 1rem 0 0;
}
.c-post-block--card .c-post-block__content {
  padding: 1.5rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__image {
  border-radius: 1rem 0 0 1rem;
}
.c-post-block--card.c-post-block--horizontal .c-post-block__content {
  padding: 1.5rem;
}

.c-post-block--horizontal {
  max-width: 60rem;
}
.c-post-block--horizontal .c-post-block__inner {
  display: flex;
  align-items: stretch;
}
.c-post-block--horizontal .c-post-block__image-wrapper {
  width: 33.3333333333%;
  flex-grow: 0;
  flex-shrink: 0;
}
.c-post-block--horizontal .c-post-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.c-post-block--horizontal .c-post-block__content {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.c-profile-block {
  position: relative;
  display: inline-flex;
}
.c-profile-block a {
  position: relative;
  z-index: 1;
}

.c-profile-block__image-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.c-profile-block__image {
  border-radius: 50%;
  margin-right: 1rem;
}

.c-profile-block__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-profile-block__before {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}

.c-profile-block__name {
  display: block;
  font-weight: bold;
}
@supports (text-decoration-color: transparent) {
  .c-profile-block__name {
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.2s ease-in-out;
  }
}

a.c-profile-block__name {
  position: inherit;
  position: inherit;
  z-index: 0;
}
a.c-profile-block__name:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}
a.c-profile-block__name:hover {
  color: #0f4471;
  text-decoration: underline;
  text-decoration-color: #0f4471;
}

.c-profile-block__title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}

.c-profile-block__after {
  display: block;
  font-size: 0.75rem;
  line-height: 1.6666666667;
}

.c-profile-block__button {
  margin-top: 0.5rem;
}

.c-profile-block--stacked {
  flex-direction: column;
  text-align: center;
}
.c-profile-block--stacked .c-profile-block__image {
  margin-right: 0;
  margin-bottom: 1rem;
}

@media (max-width: 34.9375em) {
  .c-profile-block {
    display: block;
  }
}

.c-profile-block__image {
  border-radius: 0;
}
@media (max-width: 34.9375em) {
  .c-profile-block__image {
    margin-bottom: 1rem;
  }
}

a.c-profile-block__name:before {
  display: none;
}

.c-profile-block__content {
  width: 100%;
}

.c-profile-block--doctor {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
}
.c-profile-block--doctor .c-profile-block__image-wrapper {
  flex: 0 0 6rem;
  padding-right: 1rem;
}
.c-profile-block--doctor .c-profile-block__content {
  flex: 1 1 25rem;
}
.c-profile-block--doctor .c-profile-block__image {
  border-radius: 100%;
  margin-right: 0;
}
.c-profile-block--doctor .c-profile-block__name {
  font-size: 1.0625rem;
  line-height: 1.1764705882;
}
@media (min-width: 35em) {
  .c-profile-block--doctor .c-profile-block__name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 55em) {
  .c-profile-block--doctor .c-profile-block__name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
.c-profile-block--doctor .c-profile-block__title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  margin-top: 0.25rem;
  color: rgb(111, 102.68, 102.68);
}
.c-profile-block--doctor .c-profile-block__after {
  font-size: 1rem;
  line-height: 1.75;
}

.c-breadcrumbs {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  color: rgba(48, 55, 64, 0.85);
}
.c-breadcrumbs a {
  color: rgba(48, 55, 64, 0.85);
  text-decoration: none;
}
.c-breadcrumbs a:hover {
  color: #303740;
  text-decoration: underline;
}
.c-breadcrumbs .breadcrumb_last {
  color: #303740;
  font-weight: bold;
}

.c-breadcrumbs--white {
  color: rgba(255, 255, 255, 0.85);
}
.c-breadcrumbs--white a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}
.c-breadcrumbs--white a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.c-breadcrumbs--white .breadcrumb_last {
  color: #ffffff;
  font-weight: bold;
}

.c-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-top: 0.125rem solid rgb(217.56, 217.56, 217.56);
  border-bottom: 0.125rem solid rgb(217.56, 217.56, 217.56);
  max-width: 48rem;
  margin: 2rem auto;
  position: relative;
}

.c-cta__icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.c-cta__image {
  margin-bottom: 1rem;
}

.c-cta__title {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  font-weight: normal;
  font-family: "Poppins", "Lato", Arial;
  max-width: 40rem;
  margin: 0 auto;
}
@media (min-width: 35em) {
  .c-cta__title {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

.c-cta__description {
  max-width: 40rem;
  margin: 1rem auto;
  text-align: center;
}

.c-cta__button {
  margin-top: 1rem;
}

.c-cta--video {
  margin: 0;
  border: 0;
}
@media (max-width: 34.9375em) {
  .c-cta--video {
    padding: 3rem 1rem 1rem;
  }
}
@media (min-width: 35em) {
  .c-cta--video {
    padding: 1.5rem 3rem 2.5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__title {
    font-size: 1.0625rem;
    line-height: 1.1764705882;
  }
}
@media (max-width: 34.9375em) and (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (max-width: 34.9375em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 35em) and (min-width: 35em) {
  .c-cta--video .c-cta__title {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 35em) and (min-width: 55em) {
  .c-cta--video .c-cta__title {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-cta__button {
    margin-top: 0.75rem;
  }
}
.c-cta--video .c-button {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}
@media (max-width: 34.9375em) {
  .c-cta--video .c-button {
    font-size: 0.75rem;
    line-height: 1;
    border-radius: 0.75rem;
    border-width: 0.0625rem;
    padding: 0.1875rem 0.5rem;
    letter-spacing: 0.03125rem;
    font-weight: normal;
  }
}
.u-hover-children:hover .c-cta--video .c-button, .c-cta--video .c-button:hover {
  background-color: rgb(17.5, 110.6, 154);
  border-color: rgb(17.5, 110.6, 154);
  color: #ffffff;
}

.c-cta--button {
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
}
.c-cta--button > * {
  display: none;
}
.c-cta--button > .c-cta__button {
  display: block;
}

.c-cta {
  display: block;
  border: 0;
  padding: 0;
  position: relative;
}

.c-cta__title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
@media (min-width: 35em) {
  .c-cta__title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-cta__title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.c-cta__description {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.c-cta__button {
  margin-top: 2.5rem;
}

@media (max-width: 34.9375em) {
  .c-cta__button--livechat {
    display: none;
  }
}

@media (min-width: 35em) {
  .c-cta__button--phone {
    display: none;
  }
}

.c-cta--primary {
  background-color: #0f4471;
  background-image: url("../assets/img/cta-pattern.png");
  background-repeat: no-repeat;
  background-position: top right;
  border: 0;
  padding: 0;
  display: block;
  border-radius: 0.25rem;
}
.c-cta--primary .c-cta__container {
  width: 100%;
}
@media (max-width: 34.9375em) {
  .c-cta--primary .c-cta__container {
    padding: 1.5rem;
  }
}
@media (min-width: 35em) {
  .c-cta--primary .c-cta__container {
    position: relative;
    overflow: hidden;
  }
}
.c-cta--primary .c-cta__description {
  color: #ffffff;
}
.c-cta--primary .c-cta__title {
  color: #ffffff;
}
.c-cta--primary .c-button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0f4471;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.u-hover-children:hover .c-cta--primary .c-button, .c-cta--primary .c-button:hover {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}
.c-cta--primary .c-cta__media {
  transform: rotate(-19deg);
}
@media (max-width: 34.9375em) {
  .c-cta--primary .c-cta__media {
    width: 6rem;
    height: 6rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 35em) {
  .c-cta--primary .c-cta__media {
    position: absolute;
    right: 62%;
    top: 50%;
    width: 12.5rem;
    height: 12.5rem;
    z-index: 0;
    margin-top: -6.25rem;
  }
}
.c-cta--primary .c-cta__media:before {
  content: "";
  display: block;
  position: absolute;
  width: 110%;
  height: 110%;
  background-color: rgb(13.08, 57.12, 94.92);
  transform: rotate(-5deg);
  border-radius: 1rem;
  top: -7.5%;
  left: -2.5%;
}
.c-cta--primary .c-cta__media:after {
  content: "";
  display: block;
  position: absolute;
  width: 98%;
  height: 98%;
  background-color: rgb(11.16, 46.24, 76.84);
  border-radius: 1rem;
  top: 1%;
  left: 1%;
  z-index: 1;
}
.c-cta--primary .c-cta__image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.c-cta--primary .c-cta__image-wrapper img {
  width: 125%;
  height: 125%;
  transform: rotate(19deg) translate(-12.5%, -6.25%);
  margin: 0;
}
.c-cta--primary .c-cta__content {
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-cta--primary .c-cta__content {
    margin-top: 1rem;
  }
}
@media (min-width: 35em) {
  .c-cta--primary .c-cta__content {
    min-height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    padding: 2rem 1rem;
    float: right;
  }
}
.c-cta--primary .c-cta__button .c-button {
  position: inherit;
  z-index: 0;
}
.c-cta--primary .c-cta__button .c-button:before {
  content: "";
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.c-cta--full {
  max-width: none;
  background-color: #0f4471;
}
.c-cta--full h2 {
  color: #ffffff;
}
.c-cta--full .c-cta__container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  max-width: 54rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 54.9375em) {
  .c-cta--full .c-cta__container {
    text-align: center;
  }
}
.c-cta--full .c-cta__media {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-cta--full .c-cta__media {
    width: 33.3333333333%;
    margin-left: auto;
  }
}
.c-cta--full .c-cta__content {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: left;
}
@media (min-width: 55em) {
  .c-cta--full .c-cta__content {
    width: 66.6666666667%;
    margin-left: auto;
  }
}
@media (max-width: 54.9375em) {
  .c-cta--full .c-cta__content {
    text-align: center;
  }
}
.c-cta--full .c-cta__description {
  text-align: left;
  margin: 0;
  color: #ffffff;
}
@media (max-width: 54.9375em) {
  .c-cta--full .c-cta__description {
    text-align: center;
  }
}
.c-cta--full .c-cta__title {
  text-align: left;
}
.c-cta--full .c-button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0f4471;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.u-hover-children:hover .c-cta--full .c-button, .c-cta--full .c-button:hover {
  background-color: #199edc;
  border-color: #199edc;
  color: #ffffff;
}

.c-cta--stacked .c-cta__container {
  display: block;
  padding: 1.5rem;
  background-image: none;
}
.c-cta--stacked .c-cta__media {
  width: 6rem;
  height: 6rem;
  position: relative;
  top: 0;
  right: 0;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
}
.c-cta--stacked .c-cta__content {
  min-height: 0;
  width: 100%;
  padding: 0;
  margin-top: 2rem;
  float: none;
}

.c-cta--top-aside {
  text-align: center;
  margin-bottom: 1.5rem;
}
.c-cta--top-aside .c-cta__img {
  margin-bottom: 0.75rem;
}
@media (max-width: 54.9375em) {
  .c-cta--top-aside {
    display: none;
  }
}

.c-cta--no-mask .c-cta__image-wrapper {
  overflow: visible;
}

.c-factbox {
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 0.25rem;
  border-left: 0.1875rem solid #199edc;
  padding-left: 1.5rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 35em) {
  .c-factbox {
    font-size: 1.4375rem;
    line-height: 1.7391304348;
  }
}
@media (min-width: 55em) {
  .c-factbox {
    font-size: 1.625rem;
    line-height: 1.6923076923;
  }
}
.c-factbox .c-factbox__title {
  display: block;
  font-weight: bold;
}
.c-factbox .c-factbox__title + .c-factbox__copy {
  display: inline;
}
.c-factbox + .c-list {
  margin-top: 1.5rem;
}

.c-figure {
  display: table;
  max-width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.c-figure__image-link {
  display: block;
}

.c-figure__image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.c-figure__figcaption {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin-top: 1rem;
}

.c-figure__caption {
  font-style: italic;
}

.c-figure__sources {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 0.75rem;
}

@media (min-width: 35em) {
  .c-figure--right {
    float: right;
    max-width: 50%;
    margin: 0 0 2rem 2rem;
  }
}

@media (min-width: 35em) {
  .c-figure--left {
    float: left;
    max-width: 50%;
    margin-top: 0;
    margin: 0 2rem 2rem 0;
  }
}

.c-figure__caption {
  text-align: center;
  font-style: normal;
}

.c-figure--right {
  text-align: center;
  display: block;
}
.c-figure--right img {
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.c-figure--right .c-figure__figcaption {
  display: block;
}
.c-figure--right .c-figure__caption {
  font-style: normal;
  display: block;
}
@media (min-width: 35em) {
  .c-figure--right {
    float: right;
    max-width: 16rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    height: auto;
    width: 100%;
  }
}

.c-figure--video .c-figure__caption {
  text-align: center;
}

.c-form {
  position: relative;
}

.c-form__group {
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-form__group--archer-s1e5 {
  display: none;
}

.c-form__group.is-hidden {
  display: none;
}

.c-form__step {
  display: none;
}

.c-form__step:first-child {
  display: block;
}

.c-form__submit-error,
.c-form__network-error {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  color: #d03232;
  background-color: #ffffff;
  position: absolute;
  z-index: 200;
}

.c-form__network-error {
  margin: 1rem;
}

.c-form__lightbox {
  content: "";
  position: absolute;
  z-index: 199;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.c-form__submit-animation {
  display: none;
}

.c-form--submitting .c-form__submit-animation {
  display: block;
  position: absolute;
  width: 5rem;
  height: 5rem;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-form--submitting .c-form__submit-animation:after {
  content: " ";
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
  border-radius: 50%;
  border: 0.375rem solid #1e8527;
  border-color: #1e8527 transparent #1e8527 transparent;
  animation: submit-animation 1.2s linear infinite;
}
@keyframes submit-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.c-form--submitting .c-form__lightbox {
  display: block;
}

.c-form--disabled .c-form__submit-error {
  visibility: hidden;
}
.c-form--disabled .c-form__lightbox {
  display: block;
}

.c-pagination {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 35em) {
  .c-pagination {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-pagination {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-pagination__prev,
.c-pagination__next {
  padding: 0.5rem;
  color: #2270c3;
  margin: 0 0.5rem;
}
.c-pagination__prev:hover,
.c-pagination__next:hover {
  color: rgb(9.0234375, 40.90625, 67.9765625);
}

.c-pagination__pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-pagination__page {
  list-style: none;
  padding: 0 0.5rem;
}

.c-pagination__page--active .c-pagination__link,
.c-pagination__page--active .c-pagination__link:hover {
  border-color: rgb(151, 151, 151);
  color: rgb(111, 102.68, 102.68);
}

.c-pagination__link {
  display: block;
  padding: 0.5rem 0.5rem 0.375rem;
  border-bottom: 0.125rem solid transparent;
  color: #2270c3;
}
.c-pagination__link:hover {
  color: rgb(9.0234375, 40.90625, 67.9765625);
  border-color: rgb(9.0234375, 40.90625, 67.9765625);
}

.c-profile-contact__item {
  display: inline-block;
}
.c-profile-contact__item:after {
  content: "|";
  padding: 0 0.5rem;
}
.c-profile-contact__item:last-child:after {
  display: none;
}

.c-profile-social__item {
  display: inline-block;
}

.c-profile-social__link {
  padding: 0.5rem;
}
.c-profile-social__link .o-icon {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 35em) {
  .c-profile-social__link .o-icon {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-profile-social__link .o-icon {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-share {
  margin-top: 3rem;
  border-top: 0.0625rem solid #f8f8f8;
  padding-top: 3rem;
  text-align: center;
}

.c-share__heading {
  margin-bottom: 0.5rem;
  display: block;
}

.c-share__list {
  display: flex;
  justify-content: center;
}
@media (max-width: 34.9375em) {
  .c-share__list {
    flex-direction: column;
  }
}

.c-share__item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media (max-width: 34.9375em) {
  .c-share__item {
    margin-bottom: 1rem;
  }
}

.c-share__link {
  width: 6rem;
  text-align: center;
}

.c-share__link--twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: #ffffff;
}
.u-hover-children:hover .c-share__link--twitter, .c-share__link--twitter:hover {
  background-color: rgb(20.3, 112.7, 169.4);
  border-color: rgb(20.3, 112.7, 169.4);
  color: #ffffff;
}

.c-share__link--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: #ffffff;
}
.u-hover-children:hover .c-share__link--facebook, .c-share__link--facebook:hover {
  background-color: rgb(41.3, 62.3, 106.4);
  border-color: rgb(41.3, 62.3, 106.4);
  color: #ffffff;
}

.c-share__copy {
  display: none;
}
.c-share__copy textarea {
  width: 100%;
  max-width: 30rem;
  margin: 0 auto 0.5rem;
  display: block;
  text-align: center;
}

.c-share {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.c-share__heading {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

@media (max-width: 54.9375em) {
  .c-share__list {
    flex-direction: row;
    justify-content: center;
  }
}

.c-share__link {
  width: auto;
  text-transform: uppercase;
  font-weight: normal;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 54.9375em) {
  .c-share__link {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    padding: 0;
  }
  .c-share__link .c-button__copy {
    display: none;
  }
}

.c-share__copy textarea {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  border: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}

.c-simple {
  width: 13.4375rem;
  background: #413c6a;
  color: #ffffff;
  text-align: center;
  position: relative;
  margin: 0;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translate(-120%);
  transition: transform 0.2s ease-in-out;
  z-index: 100;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #0f4471;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-image: url("../assets/img/cta-pattern.png");
  background-repeat: no-repeat;
  background-position: top right;
}
.c-simple.is-active {
  transform: translate(0);
}
@media (max-width: 34.9375em) {
  .c-simple {
    display: none;
  }
}

.c-simple__content {
  display: block;
}

.c-simple__icon,
.c-simple__image {
  display: block;
  max-width: 100%;
  margin: auto;
}

.c-simple__title {
  font-size: 1.0625rem;
  line-height: 1.1764705882;
  font-weight: normal;
  display: block;
  padding: 0.25rem 0.5rem 1rem;
  font-weight: bold;
}
@media (min-width: 35em) {
  .c-simple__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 55em) {
  .c-simple__title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}

.c-simple__button {
  margin: 0;
  margin-top: 1rem;
}

.c-simple__expand:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 0;
}

.c-simple__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 0.125rem;
  background-color: rgba(25, 158, 220, 0.5);
  height: auto;
  color: #ffffff;
  line-height: 2rem;
}
.c-simple__close:hover {
  background-color: #199edc;
  color: #ffffff;
}

.c-simple--minimal {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.c-simple--minimal .c-button {
  border-radius: 0.25rem;
  padding: 0.8rem 0.8rem;
}

.no-flexbox .c-simple--full,
.no-flexboxlegacy .c-simple--full {
  display: none;
}

.c-social-links__title {
  padding-bottom: 0.5rem;
}

.c-social-links__item {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  display: inline-block;
  margin-left: 1rem;
}
@media (min-width: 35em) {
  .c-social-links__item {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-social-links__item {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-social-links__item:first-child {
  margin-left: 0;
}

.c-social-links--color .o-icon--twitter {
  color: #1da1f2;
}
.c-social-links--color .o-icon--facebook {
  color: #3b5998;
}
.c-social-links--color .o-icon--google-plus {
  color: #ea4335;
}
.c-social-links--color .o-icon--linkedin {
  color: #0077b5;
}
.c-social-links--color .o-icon--pinterest {
  color: #bd081c;
}
.c-social-links--color .o-icon--instagram {
  color: #f56040;
}

.c-sources {
  margin-top: 3rem;
  border-top: 0.0625rem solid #f8f8f8;
  padding-top: 3rem;
}
.c-sources__title {
  text-align: center;
  margin-bottom: 2rem;
}

.c-sources__lead-in {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  font-style: italic;
}

.c-sources__list-wrapper {
  height: 8rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: 0.2s ease-in-out all;
  text-align: left;
}

.c-sources__toggle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0s;
}
.c-sources__list-wrapper.is-active .c-sources__toggle {
  opacity: 0;
}
.c-sources__toggle:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, white 75%);
}
.c-sources__toggle .c-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.c-sources__list {
  position: relative;
  z-index: 1;
  list-style: decimal;
}
.c-sources__list li {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.c-sources__list a {
  color: #2270c3;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.c-sources__list a:hover {
  color: rgb(9.0234375, 40.90625, 67.9765625);
  text-decoration: underline;
}

.c-sources {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}

.c-sources__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  text-transform: uppercase;
  margin-top: 0;
}

.c-sources__list {
  padding-left: 1.5rem;
}
.c-sources__list li {
  font-size: 0.875rem;
  line-height: 1.7142857143;
}
.c-sources__list li:first-child {
  margin-top: 0;
}
.c-sources__list li:last-child {
  margin-bottom: 0;
}

.c-table {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.c-table table {
  margin-top: 0;
  border: 0.0625rem solid #979797;
}

.c-table-responsive {
  max-width: 100%;
  overflow-x: auto;
}

.c-table__title {
  background-color: #0f4471;
  color: #ffffff;
  font-family: "Poppins", "Lato", Arial;
  border-radius: 0.25rem 0.25rem 0 0;
  display: block;
  margin: 0;
  padding: 1rem;
}

.c-table__sources {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-top: 1rem;
  text-align: center;
}

.c-factbox + .c-table {
  margin-top: 2.5rem;
}

.c-tabs__nav {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.c-tabs__nav-item {
  margin: 0 0 0 0.5rem;
  list-style: none;
}

.c-tabs__nav-link {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-left: 0.5rem;
  background-color: #f8f8f8;
  display: block;
  text-decoration: none;
  color: #303740;
}
.c-tabs__nav-link.is-active {
  color: #ffffff;
  background-color: #303740;
}
.c-tabs__tab {
  display: none;
  border: 0.25rem solid #303740;
  border-radius: 0.5rem;
  padding: 1.75rem;
}
.c-tabs__tab.is-active {
  display: block;
}

.c-video {
  display: block;
  position: relative;
  width: 100%;
}

.c-video__link {
  display: block;
}

.c-video__image {
  max-width: 100%;
  position: relative;
  z-index: 1;
}

.c-video__details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 1rem;
}
@media (min-width: 35em) {
  .c-video__details {
    padding: 1.5rem;
  }
}
.c-video__details:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  transform-origin: bottom center;
  transition: 0.2s all ease-in-out;
  transform: scale(1, 1);
}
.c-video__link:hover .c-video__details:after {
  transform: scale(1, 1.5);
}

.c-video__titles {
  margin-left: 1rem;
  position: relative;
  color: #ffffff;
}
@media (min-width: 35em) {
  .c-video__titles {
    margin-left: 1.5rem;
  }
}

.c-video__title {
  display: block;
}

@media (max-width: 34.9375em) {
  .c-video__subtitle {
    display: none;
  }
}

.c-video__cta {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(48, 55, 64, 0.95);
  color: #ffffff;
}
.c-video__cta.is-active {
  display: flex;
}

.c-video__cta-close {
  font-size: 1.25rem;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 2;
}
@media (min-width: 35em) {
  .c-video__cta-close {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (min-width: 55em) {
  .c-video__cta-close {
    font-size: 1.625rem;
    line-height: 1.0769230769;
  }
}

@media (max-width: 34.9375em) {
  .c-video__replay {
    display: none;
  }
}

.c-video__play {
  flex: 0 0 auto;
}
@media (max-width: 34.9375em) {
  .c-video__play {
    font-size: 0.75rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }
}

@media (max-width: 34.9375em) {
  .c-video__duration {
    font-size: 0.75rem;
    line-height: 1;
  }
}

@media (max-width: 34.9375em) {
  .c-video__title {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
}
@media (min-width: 35em) {
  .c-video__title {
    font-size: 1rem;
    line-height: 1.25;
  }
}

.c-youtube {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.c-wysiwyg {
  max-width: 40rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg > *:first-child {
  margin-top: 0;
}
.c-wysiwyg > *:last-child {
  margin-bottom: 0;
}
.c-wysiwyg p {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.c-wysiwyg h2 + p,
.c-wysiwyg h3 + p,
.c-wysiwyg h4 + p,
.c-wysiwyg h5 + p,
.c-wysiwyg h6 + p,
.c-wysiwyg .h2 + p,
.c-wysiwyg .h3 + p,
.c-wysiwyg .h4 + p,
.c-wysiwyg .h5 + p,
.c-wysiwyg .h6 + p {
  margin-top: 0;
}
.c-wysiwyg ul,
.c-wysiwyg ol {
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.c-wysiwyg ul {
  list-style-type: initial;
}
.c-wysiwyg ol {
  list-style-type: decimal;
}
.c-wysiwyg img,
.c-wysiwyg .wp-caption {
  max-width: 100%;
  height: auto;
  margin: 2rem auto;
  display: table;
}
@media (min-width: 35em) {
  .c-wysiwyg img,
  .c-wysiwyg .wp-caption {
    margin: 3rem auto;
  }
  .c-wysiwyg img.alignleft,
  .c-wysiwyg .wp-caption.alignleft {
    float: left;
    max-width: 50%;
    margin-right: 2rem;
  }
  .c-wysiwyg img.alignright,
  .c-wysiwyg .wp-caption.alignright {
    float: right;
    max-width: 50%;
    margin-left: 2rem;
  }
}
.c-wysiwyg img .wp-caption-text,
.c-wysiwyg .wp-caption .wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: table-caption;
  caption-side: bottom;
  margin: 0;
  text-align: center;
}
.c-wysiwyg .wp-caption img {
  margin: 0 0 1rem;
}
.c-wysiwyg .wp-caption {
  width: auto !important;
}

.c-wysiwyg--center {
  margin-left: auto;
  margin-right: auto;
}

h2 + .c-wysiwyg,
h3 + .c-wysiwyg,
h4 + .c-wysiwyg,
h5 + .c-wysiwyg,
h6 + .c-wysiwyg {
  margin-top: 0;
}

.c-wysiwyg {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  max-width: 50rem;
}
@media (min-width: 35em) {
  .c-wysiwyg {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-wysiwyg {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-wysiwyg ul {
  list-style: disc;
}
.c-wysiwyg ol {
  list-style: decimal;
}

.c-list__title {
  font-weight: bold;
  font-family: "Lato", Arial;
  margin-bottom: 1rem;
  display: block;
  margin-top: 0;
}

h2.c-list__title {
  padding-bottom: 0;
}
h2.c-list__title:after {
  display: none;
}

.c-list {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
}
@media (min-width: 35em) {
  .c-list {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-list {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-list + .c-list {
  margin-top: 1.75rem;
}

.c-list--doctors {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.c-pro-con__list .c-ul__item:before {
  background-color: transparent;
}

.c-pro-con__list--pros .c-ul__item::before {
  content: url('data:image/svg+xml,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="%23199edc" /%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="%23199edc" /%3E%3C/svg%3E');
}

.c-action-list__items {
  margin-right: -1rem;
  margin-left: -1rem;
}
@media (min-width: 55em) {
  .c-action-list__items {
    display: flex;
    justify-content: center;
  }
}

.c-action-list__item {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Modifiers */
.c-action-list--wrap .c-action-list__items {
  flex-wrap: wrap;
}
.c-action-list--wrap .c-action-list__item {
  flex: 1 0 auto;
}

.c-action-list--2-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--2-col .c-action-list__item {
    flex: 0 1 50%;
  }
}
.c-action-list--2-col img {
  max-width: 100%;
}

.c-action-list--3-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--3-col .c-action-list__item {
    flex: 0 1 33%;
  }
}
.c-action-list--3-col img {
  max-width: 100%;
}

.c-action-list--4-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--4-col .c-action-list__item {
    flex: 0 1 25%;
  }
}
.c-action-list--4-col img {
  max-width: 100%;
}

.c-action-list--5-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--5-col .c-action-list__item {
    flex: 0 1 20%;
  }
}
.c-action-list--5-col img {
  max-width: 100%;
}

.c-action-list--6-col .c-action-list__items {
  flex-wrap: wrap;
}
@media (min-width: 55em) {
  .c-action-list--6-col .c-action-list__item {
    flex: 0 1 16.66%;
  }
}
.c-action-list--6-col img {
  max-width: 100%;
}

.c-action-block {
  text-align: left;
  padding: 0;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-action-block {
    text-align: left;
  }
}

a.c-action-block--card {
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
a.c-action-block--card:hover {
  box-shadow: none;
}

.c-action-block__media {
  width: 100%;
}

.c-action-block__image img {
  border-radius: 0.25rem;
  max-width: 100%;
}

.c-action-list.c-action-list--gray-bg .c-action-block {
  background-color: #f1f4f6;
}
.c-action-list.c-action-list--white-bg .c-action-block {
  background-color: #ffffff;
}

.c-action-list--col-1 .c-action-list__items {
  display: block;
}
.c-action-list--col-1 .c-action-block {
  max-width: none;
}

.c-action-list--col-2 .c-action-list__items {
  display: flex;
  flex-wrap: wrap;
}
.c-action-list--col-2 .c-action-list__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 35em) {
  .c-action-list--col-2 .c-action-list__item {
    width: 50%;
  }
}

.c-action-list--center .c-action-block {
  text-align: center;
}

.c-action-list--col-3 .c-action-list__items {
  display: flex;
  flex-wrap: wrap;
}
.c-action-list--col-3 .c-action-list__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 35em) {
  .c-action-list--col-3 .c-action-list__item {
    width: 33.3333333333%;
  }
}
.c-action-list--col-3 .c-action-block {
  text-align: center;
  display: flex;
  align-items: flex-start;
  padding: 2.5rem;
  max-width: 40rem;
  margin-bottom: 0;
}
.c-action-list--col-3.c-action-list--left .c-action-block {
  text-align: left;
}

.c-action-list--col-4 .c-action-block__image img {
  border-radius: 100%;
}
@media (max-width: 34.9375em) {
  .c-action-list--col-4 .c-action-block__image img {
    width: 4rem;
  }
}
.c-action-list--col-4 .c-action-block__copy {
  text-align: left;
}
.c-action-list--col-4 .c-action-block__content {
  text-align: left;
}
.c-action-list--col-4 .c-action-list__items {
  display: flex;
  flex-wrap: wrap;
}
.c-action-list--col-4 .c-action-list__item {
  flex: 1 1 26rem;
}
.c-action-list--col-4 .c-action-block {
  display: flex;
  align-items: flex-start;
  padding: 2.5rem;
  max-width: 40rem;
  margin-bottom: 0;
}
.c-action-list--col-4 .c-action-block__media {
  flex: 0 0 5rem;
}
.c-action-list--col-4 .c-action-block__content {
  flex: 1 1 auto;
}

.c-action-list--col-5 .c-action-list__items {
  display: flex;
  flex-wrap: wrap;
}
.c-action-list--col-5 .c-action-list__item {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 35em) {
  .c-action-list--col-5 .c-action-list__item {
    width: 20%;
  }
}
@media (max-width: 54.9375em) {
  .c-action-list--col-5 .c-action-list__item {
    width: 100%;
  }
}
.c-action-list--col-5 .c-action-block {
  text-align: center;
  display: flex;
  align-items: flex-start;
  padding: 2.5rem;
  max-width: 40rem;
  margin-bottom: 0;
}
@media (max-width: 54.9375em) {
  .c-action-list--col-5 .c-action-block {
    padding: 1rem;
  }
}
.c-action-list--col-5.c-action-list--left .c-action-block {
  text-align: left;
}

.c-action-list--narrow .c-action-list__items {
  max-width: 55rem;
  margin: auto;
}
.c-action-list--narrow .c-action-block {
  max-width: 22rem;
}

.c-action-list--icon .c-action-block__image img {
  border-radius: 100%;
}
.c-action-list--icon .c-action-block__copy {
  text-align: left;
}
.c-action-list--icon .c-action-block__content {
  text-align: left;
}
@media (min-width: 35em) {
  .c-action-list--icon .c-action-list__items {
    display: flex;
    flex-wrap: wrap;
  }
  .c-action-list--icon .c-action-list__item {
    flex: 1 1 26rem;
  }
  .c-action-list--icon .c-action-block {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    padding: 0;
  }
  .c-action-list--icon .c-action-block__media {
    flex: 0 0 5rem;
  }
  .c-action-list--icon .c-action-block__content {
    flex: 1 1 auto;
    padding-left: 2rem;
  }
}

.no-flexbox .c-action-list__items,
.no-flexboxlegacy .c-action-list__items {
  overflow: hidden;
}
.no-flexbox .c-action-list__item,
.no-flexboxlegacy .c-action-list__item {
  display: block;
  float: left;
  width: 33%;
}
.no-flexbox .c-action-list--col-2 .c-action-list__item,
.no-flexboxlegacy .c-action-list--col-2 .c-action-list__item {
  display: block;
  float: left;
  width: 49%;
}

.c-attribution--header {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}
.c-attribution--header .c-modified {
  display: inline;
}
.c-page--one-column .c-attribution--header {
  margin-top: 0;
}
.c-page--one-column .c-attribution--header .c-attribution__modal-toggle {
  text-decoration: underline;
}

.c-hero__attribution {
  font-size: 1rem;
  background: #e6edf2;
  margin-top: 2.5rem;
  color: #424242;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
@media (min-width: 55em) {
  .c-hero__attribution .c-hero__attribution-wrapper {
    padding-right: 1.5rem;
    padding-right: 22rem;
  }
}
@media (min-width: 35em) {
  .c-hero__attribution .c-hero__attribution-wrapper {
    display: flex;
  }
}
@media (max-width: 54.9375em) {
  .c-hero__attribution .c-hero__attribution-wrapper {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 54.9375em) {
  .c-hero__attribution .c-profile-block {
    display: flex;
  }
}
.c-hero__attribution .c-attribution__author {
  font-size: 1rem;
  margin-right: 1.5rem;
  display: flex;
  line-height: initial;
}
@media (max-width: 54.9375em) {
  .c-hero__attribution .c-attribution__author {
    margin-bottom: 0.5rem;
  }
}
.c-hero__attribution .c-profile-block__before {
  font-weight: bold;
  font-size: 1rem;
}
.c-hero__attribution .c-profile-block__before a {
  font-weight: bold;
  font-size: 1rem;
}
.c-hero__attribution .c-profile-block__content {
  display: block;
}
.c-hero__attribution .c-profile-block__name {
  font-size: 1rem;
  font-weight: normal;
  color: #424242;
  display: inline-block;
}
.c-hero__attribution a.c-profile-block__name {
  text-decoration: underline;
}
.c-hero__attribution .o-icon {
  font-size: 1.5rem;
  color: #199edc;
  display: inline-block;
  margin-right: 0.5rem;
  line-height: normal;
}
.c-hero__attribution .c-attribution__date {
  font-style: italic;
  line-height: initial;
}
.c-hero__attribution .c-profile-block__content {
  line-height: 1.5625rem;
}

.c-attribution--footer {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}
.c-attribution--footer .c-modified {
  display: inline;
}
@media (min-width: 35em) {
  .c-attribution--footer .c-modified {
    padding-left: 7rem;
  }
}

.c-attribution__modal-toggle {
  cursor: pointer;
  margin-bottom: 1.25rem;
}

.c-attribution__primary .c-profile-block {
  margin-bottom: 1.5rem;
}
.c-attribution__primary .c-profile-block__before {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  text-transform: uppercase;
}
.c-attribution__primary .c-profile-block__name {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
@media (min-width: 35em) {
  .c-attribution__primary .c-profile-block__name {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-attribution__primary .c-profile-block__name {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-attribution__primary .c-profile-block__after {
  font-size: 1rem;
  line-height: 1.75;
  max-width: 40rem;
}
.c-attribution__primary .c-profile-block__after p {
  margin: 0;
}

.c-attribution__additional .c-profile-block {
  display: block;
}
.c-attribution__additional .c-profile-block__content {
  display: block;
}
@media (min-width: 35em) {
  .c-attribution__additional .c-profile-block__content {
    padding-left: 7rem;
  }
}
.c-attribution__additional .c-profile-block__before {
  font-size: 1rem;
  line-height: 1.75;
  font-weight: 700;
  display: inline;
}
.c-attribution__additional .c-profile-block__name {
  font-weight: 400;
  display: inline;
}

@media (max-width: 54.9375em) {
  .c-backpage-mobile-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
    border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
    z-index: 900;
  }
}
@media (min-width: 55em) {
  .c-backpage-mobile-nav {
    display: none;
  }
}

.c-backpage-mobile-nav__list {
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.c-backpage-mobile-nav__item {
  font-size: 0.75rem;
  line-height: 1;
  flex: 1 1 auto;
  padding: 0 0.75rem;
  text-align: center;
}

.c-backpage-mobile-nav__button {
  font-size: 0.75rem;
  line-height: 1;
  background-color: transparent;
  border-color: transparent;
  color: #303740;
  border: none;
  padding: 0;
  border-radius: 0;
  height: auto;
  font-weight: 400;
  min-width: 3rem;
}
.u-hover-children:hover .c-backpage-mobile-nav__button, .c-backpage-mobile-nav__button:hover {
  background-color: transparent;
  border-color: transparent;
  color: #303740;
}
.c-backpage-mobile-nav__button.is-active {
  color: #0f4471;
  font-weight: 700;
}
.c-backpage-mobile-nav__button .o-icon {
  font-size: 1rem;
  line-height: 1;
  display: block;
  margin-bottom: 0.25rem;
}

.c-content-builder > *:last-child {
  margin-bottom: 0;
}
.c-content-builder > *:first-child {
  margin-top: 0;
}

.c-site-footer {
  color: #ffffff;
  background-color: #0f4471;
  padding-top: 4rem;
  padding-bottom: 0;
  margin-top: auto;
  text-align: center;
}
.c-site-footer a {
  color: inherit;
  text-decoration: none;
}
.c-site-footer a:hover {
  text-decoration: none;
}

.c-site-footer__logo {
  margin: 0 auto 2rem;
  max-width: 20rem;
}
.c-site-footer__logo .c-logo {
  background-image: url("../assets/img/logos/logo-light.svg");
}

.c-site-footer__menu {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  margin-bottom: 3rem;
}
@media (min-width: 55em) {
  .c-site-footer__menu .c-menu__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.c-site-footer__menu .c-menu__item {
  letter-spacing: 0.0625rem;
}
@media (max-width: 54.9375em) {
  .c-site-footer__menu .c-menu__item {
    margin-bottom: 1rem;
  }
}
@media (min-width: 55em) {
  .c-site-footer__menu .c-menu__item {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.c-site-footer__disclaimer {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  color: #f8f8f8;
}

.c-site-footer {
  background-color: #0f4471;
}
.c-site-footer a {
  text-decoration: underline;
}
.c-site-footer section {
  border-bottom: 0.0625rem solid rgb(13.08, 57.12, 94.92);
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
}
.c-site-footer section:last-of-type {
  margin-bottom: 1.25rem;
}

.c-site-footer__cols {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-site-footer__col {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: left;
}
@media (min-width: 35em) {
  .c-site-footer__col {
    width: 33.3333333333%;
  }
}
@media (max-width: 34.9375em) {
  .c-site-footer__col {
    margin-top: 2rem;
  }
}
.c-site-footer__col strong {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  display: block;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
}
@media (min-width: 35em) {
  .c-site-footer__col strong {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-site-footer__col strong {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-site-footer__col strong:after {
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 1.5rem;
  background-color: #18d8b8;
  bottom: 0rem;
  left: 0;
}
.c-site-footer__col .c-menu__link,
.c-site-footer__col .c-button--link {
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
  opacity: 0.8;
}
.c-site-footer__col .c-menu__link:hover,
.c-site-footer__col .c-button--link:hover {
  text-decoration: underline;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s;
}
.c-site-footer__col .c-button--link {
  font-size: 1rem;
  line-height: 1.75;
  font-family: "Lato", Arial;
  color: #ffffff;
  font-weight: normal;
  padding: 0;
  margin-bottom: 3rem;
}
.c-site-footer__col .c-button--link::after {
  display: none;
}

.c-menu--2nd {
  margin-bottom: 0;
}

.c-site-footer__menu .c-menu__list {
  display: block;
}
.c-site-footer__menu .c-menu__item {
  padding-left: 0;
  padding-right: 0;
}

.c-site-footer__disclaimer {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  font-style: normal;
}

.c-site-footer__logo-container {
  width: 75%;
}

@media (min-width: 35em) {
  .c-site-footer__logo {
    margin: 4.3rem 0 0 30rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-footer__logo {
    margin: 4.4rem 0 0 15rem;
  }
}

.c-site-footer__certs {
  height: 3rem;
}
.c-site-footer__certs img {
  max-height: 100%;
  width: auto;
}

.no-flexbox .c-site-footer .l-container,
.no-flexboxlegacy .c-site-footer .l-container {
  display: block;
}
.no-flexbox .c-site-footer__cols,
.no-flexboxlegacy .c-site-footer__cols {
  overflow: hidden;
}
.no-flexbox .c-site-footer__col,
.no-flexboxlegacy .c-site-footer__col {
  display: block;
  float: left;
}
.no-flexbox .c-site-footer__disclaimer,
.no-flexboxlegacy .c-site-footer__disclaimer {
  display: block;
  float: none;
}

.c-site-header {
  z-index: 1000;
  background-color: #ffffff;
}
@media (max-width: 54.9375em) {
  .c-site-header {
    position: sticky;
    top: 0;
  }
}

.c-site-header--lander {
  border-bottom: 0;
  background-color: #0f4471;
  box-shadow: none;
}
.c-site-header--lander .c-site-header__content {
  padding-top: 1.5rem;
}
.c-site-header--lander .c-site-header__phone {
  text-align: right;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-right: 0;
  margin-left: 1rem;
}
@media (max-width: 54.9375em) {
  .c-site-header--lander .c-site-header__phone {
    box-shadow: none;
  }
}
.c-site-header--lander .c-site-header__phone-title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: normal;
}
@media (max-width: 54.9375em) {
  .c-site-header--lander .c-site-header__phone-title {
    display: none;
  }
}
.c-site-header--lander .c-site-header__phone-button {
  font-size: 1.25rem;
  line-height: 1.2;
  display: block;
  color: #ffffff;
  padding: 0;
  border: 0;
}
@media (min-width: 35em) {
  .c-site-header--lander .c-site-header__phone-button {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-site-header--lander .c-site-header__phone-button {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-site-header--lander .c-site-header__phone-button:hover {
  color: #ffffff;
}
@media (max-width: 34.9375em) {
  .c-site-header--lander .c-site-header__phone-button {
    font-size: 1rem;
    line-height: 1.75;
  }
}

.c-site-header--lander-variant {
  border-bottom: 0;
  background-color: #ffffff;
  box-shadow: none;
}
.c-site-header--lander-variant .c-site-header__content {
  padding-top: 1.5rem;
}
.c-site-header--lander-variant .c-site-header__phone {
  text-align: right;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-right: 0;
  margin-left: 1rem;
}
@media (max-width: 54.9375em) {
  .c-site-header--lander-variant .c-site-header__phone {
    box-shadow: none;
  }
}
.c-site-header--lander-variant .c-site-header__phone-title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  text-transform: uppercase;
  font-weight: normal;
}
@media (max-width: 54.9375em) {
  .c-site-header--lander-variant .c-site-header__phone-title {
    display: none;
  }
}
.c-site-header--lander-variant .c-site-header__phone-button {
  font-size: 1.25rem;
  line-height: 1.2;
  display: block;
  padding: 0;
  border: 0;
}
@media (min-width: 35em) {
  .c-site-header--lander-variant .c-site-header__phone-button {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-site-header--lander-variant .c-site-header__phone-button {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}
.c-site-header--lander-variant .c-site-header__phone-button:hover {
  color: #ffffff;
}
@media (max-width: 34.9375em) {
  .c-site-header--lander-variant .c-site-header__phone-button {
    font-size: 1rem;
    line-height: 1.75;
  }
}

.c-site-header__content {
  display: flex;
}
@media (max-width: 54.9375em) {
  .c-site-header__content {
    align-items: stretch;
  }
}
@media (min-width: 55em) {
  .c-site-header__content {
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-bottom: 1.5rem;
    padding-top: 3rem;
  }
}

.c-site-header__button {
  border: 0;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
}
.c-site-header__button:hover {
  background: none;
}

.c-site-header__button--mobile {
  font-size: 1.4375rem;
  border: 0;
  padding: 0 1rem;
  margin-left: -1rem;
}
@media (min-width: 35em) {
  .c-site-header__button--mobile {
    font-size: 1.6875rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__button--mobile {
    font-size: 2rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__button--mobile {
    display: none;
  }
}

.c-site-header__left {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  font-size: 0;
}
.c-site-header__left a,
.c-site-header__left a:hover {
  text-decoration: none;
}
@media (max-width: 34.9375em) {
  .c-site-header__left {
    max-width: 12rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__left {
    max-width: 15rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media (max-width: 54.9375em) {
  .c-site-header__left {
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-site-header__left {
    margin-right: 2rem;
    max-width: 18rem;
  }
}

.c-site-header__right {
  flex: 0 0 auto;
}

@media (max-width: 54.9375em) {
  .c-mobile-nav__drawer {
    position: fixed;
    right: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    max-width: 25rem;
    z-index: 2;
    transform: translateX(0%);
    transition: transform 0.2s ease-in-out;
    overflow-y: auto;
  }
  .c-mobile-nav.is-active .c-mobile-nav__drawer {
    transform: translateX(100%);
  }
}
@media (min-width: 55em) {
  .c-mobile-nav__drawer {
    display: flex;
    align-items: center;
  }
}

.c-mobile-nav__header {
  background: #0f4471;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (min-width: 55em) {
  .c-mobile-nav__header {
    display: none;
  }
}

.c-site-header__search-form {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 55em) {
  .c-site-header__search-form {
    display: none;
  }
}

.c-site-header__search-link {
  font-size: 0.875rem;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  line-height: 3rem;
  color: #303740;
  position: relative;
  margin-left: 0.75rem;
}
.c-site-header__search-link:hover {
  text-decoration: none;
}
@media (max-width: 54.9375em) {
  .c-site-header__search-link {
    display: none;
  }
}
@media (min-width: 75em) {
  .c-site-header__search-link {
    font-size: 1rem;
    margin-left: 1rem;
  }
}

@media (max-width: 54.9375em) {
  .c-site-header__nav {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .c-site-header__nav:before {
    font-size: 1rem;
    line-height: 1.25;
    font-family: Poppins, Lato, Arial;
    font-weight: normal;
    color: #0d1b26;
    content: "Navigation";
    text-transform: uppercase;
    display: block;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid #979797;
    margin-bottom: 1.5rem;
  }
}

.c-mobile-nav__mask {
  position: fixed;
  cursor: pointer;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
@media (min-width: 55em) {
  .c-mobile-nav__mask {
    width: 0;
    height: 0;
  }
}
.c-mobile-nav.is-active .c-mobile-nav__mask {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
}

@media (max-width: 34.9375em) {
  .c-site-header__button--phone {
    font-size: 1.4375rem;
  }
}
@media (max-width: 34.9375em) and (min-width: 35em) {
  .c-site-header__button--phone {
    font-size: 1.6875rem;
  }
}
@media (max-width: 34.9375em) and (min-width: 55em) {
  .c-site-header__button--phone {
    font-size: 2rem;
  }
}
@media (max-width: 34.9375em) {
  .c-site-header__button--phone .o-icon {
    display: initial;
  }
  .c-site-header__button--phone .copy {
    display: none;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__button--phone {
    font-size: 1.125rem;
    width: auto;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) and (min-width: 35em) {
  .c-site-header__button--phone {
    font-size: 1.1875rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) and (min-width: 55em) {
  .c-site-header__button--phone {
    font-size: 1.25rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-site-header__button--phone .o-icon {
    display: none;
  }
  .c-site-header__button--phone .copy {
    display: initial;
  }
}
@media (max-width: 54.9375em) {
  .c-site-header__button--phone {
    padding: 0 1rem;
    margin-right: -1rem;
  }
}
@media (min-width: 55em) {
  .c-site-header__button--phone {
    display: none;
  }
}
@media (min-width: 55em) {
  .c-site-header__button--phone {
    font-size: 1rem;
    line-height: 1.75;
    background-color: #0f4471;
    border-color: #0f4471;
    color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.125rem 0.75rem;
    border-radius: 0 0 0.25rem 0.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .u-hover-children:hover .c-site-header__button--phone, .c-site-header__button--phone:hover {
    background-color: rgb(10.5, 47.6, 79.1);
    border-color: rgb(10.5, 47.6, 79.1);
    color: #ffffff;
  }
}

.c-button--phone-link .o-icon {
  font-size: 1.4375rem;
  line-height: 1.7391304348;
}
@media (min-width: 35em) {
  .c-button--phone-link .o-icon {
    font-size: 1.6875rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-button--phone-link .o-icon {
    font-size: 2rem;
    line-height: 1.75;
  }
}
@media (min-width: 35em) {
  .c-button--phone-link .o-icon {
    display: none;
  }
}

.c-button--phone-link {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  padding: 0;
  margin-right: 0.5rem;
}
@media (min-width: 35em) {
  .c-button--phone-link {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-button--phone-link {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-button--phone-link:hover {
  text-decoration: underline;
}

.c-header__phone-context {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  opacity: 0.6;
  padding: 0;
}
@media (min-width: 35em) {
  .c-header__phone-context {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-header__phone-context {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
.c-header__phone-context:hover {
  opacity: 1;
}
@media (max-width: 54.9375em) {
  .c-header__phone-context {
    display: none;
  }
}

.no-flexbox .c-site-header__content:after,
.no-flexboxlegacy .c-site-header__content:after {
  content: "";
  display: table;
  clear: both;
}
.no-flexbox .c-site-header__right,
.no-flexboxlegacy .c-site-header__right {
  display: inline-block;
  float: right;
}

.c-hero__before {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.c-hero--single .c-breadcrumbs {
  margin-bottom: 1rem;
}
.c-hero--single .c-hero__content {
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 0.0625rem solid #f8f8f8;
}

.c-hero__bottom-menu {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 55em) {
  .c-hero__bottom-menu {
    display: none;
  }
}
.c-hero__bottom-menu .c-hero__jump-to {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: center;
  border: thin solid rgb(151, 151, 151);
  padding: 0;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero__bottom-menu .c-hero__jump-to {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.c-hero__bottom-menu .c-jump-to__title {
  font-size: 0.75rem;
  line-height: 1.6666666667;
  padding: 1rem 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.c-hero__bottom-menu .c-jump-to.is-active .c-jump-to__title {
  background: rgba(15, 68, 113, 0.1);
  color: rgb(111, 102.68, 102.68);
}
.c-hero__bottom-menu .c-jump-to.is-active .c-jump-to__title:after {
  transform: rotate(180deg);
}
.c-hero__bottom-menu .c-jump-to__list {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  display: none;
}
.c-hero__bottom-menu .c-jump-to__item {
  margin: 0 auto;
}
.c-hero__bottom-menu .c-jump-to__item:last-child {
  border: none;
}

.c-hero--backpage {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 4rem;
  background-color: #0f4471;
}
.c-hero--backpage .c-breadcrumbs {
  margin-bottom: 1rem;
}
.c-hero--backpage .c-hero__title {
  color: #ffffff;
}
.c-hero--backpage .c-hummingbird {
  margin-top: 1rem;
  max-width: 40rem;
  color: #ffffff;
}

.c-hero--profile .c-hero__before img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  margin-bottom: 1rem;
}
.c-hero--profile .c-hero__subtitle {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: rgb(111, 102.68, 102.68);
}
@media (min-width: 35em) {
  .c-hero--profile .c-hero__subtitle {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-hero--profile .c-hero__subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
.c-hero--profile .c-profile-contact,
.c-hero--profile .c-profile-social {
  margin-top: 1rem;
}
.c-hero--profile .c-profile-contact a,
.c-hero--profile .c-profile-social a {
  color: #2270c3;
}
.c-hero--profile .c-profile-contact a:hover,
.c-hero--profile .c-profile-social a:hover {
  color: rgb(9.0234375, 40.90625, 67.9765625);
}

.c-hero {
  background-color: #0f4471;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.c-breadcrumbs {
  margin-bottom: 2.75rem;
}
@media (max-width: 54.9375em) {
  .c-breadcrumbs {
    position: relative;
  }
  .c-breadcrumbs:after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 100%;
    background: linear-gradient(to right, rgba(15, 68, 113, 0) 0%, #0f4471 100%);
    position: absolute;
    top: 0;
    right: 0;
  }
}

.single .c-hero--backpage .c-breadcrumbs {
  margin-bottom: 0;
}

@media (max-width: 54.9375em) {
  .c-breadcrumbs__list {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.c-hero__title {
  font-weight: bold;
  color: #ffffff;
}

@media (min-width: 55em) {
  .c-hero__description {
    max-width: 60%;
  }
}

.c-hero__phone {
  text-align: right;
}

.c-hero__phone-title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: normal;
}

.c-hero__phone-button {
  font-size: 1.25rem;
  line-height: 1.2;
  display: block;
  color: #ffffff;
  padding: 0;
  border: 0;
}
@media (min-width: 35em) {
  .c-hero__phone-button {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-hero__phone-button {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-hero--backpage {
  color: #ffffff;
  background-image: url("/wp-content/uploads/hero-pattern.png");
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 2rem;
  position: relative;
}
@media (max-width: 54.9375em) {
  .c-hero--backpage {
    margin-bottom: 2rem;
    background-size: cover;
  }
}
.c-hero--backpage .c-breadcrumbs {
  margin-bottom: 2.75rem;
}
.c-hero--backpage .c-hero__subtitle {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  color: #ffffff;
  margin: 0;
  padding-bottom: 0;
}
.c-hero--backpage .c-hero__subtitle:after {
  display: none;
}
.c-hero--backpage .c-hero__subtitle .o-icon {
  margin-right: 0.25rem;
}
@media (max-width: 54.9375em) {
  .c-hero--backpage .c-hero__content {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 55em) {
  .c-hero--backpage .c-hero__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: no-wrap;
    align-items: center;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media (min-width: 55em) {
  .c-hero--backpage .c-hero__main {
    flex: 1 1 auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 55em) {
  .c-hero--backpage .c-hero__aside {
    flex: 0 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 21.875rem;
    z-index: 1;
  }
}
.c-hero--backpage .c-hero__aside img {
  max-width: 100%;
}
@media (max-width: 54.9375em) {
  .c-hero--backpage .c-hero__aside {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 1050;
    background-color: #ffffff;
    transition: top, visibility;
    transition-duration: 0.2s, 0s;
    transition-delay: 0s, 0.2s;
    visibility: hidden;
  }
  .c-hero--backpage .c-hero__aside .c-hero__image {
    display: none;
  }
  .c-hero--backpage .c-hero__aside.is-active {
    top: 0;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
}
.c-hero--backpage .c-hero__image {
  border-radius: 0.25rem 0.25rem 0 0;
}
@media (max-width: 54.9375em) {
  .c-hero--backpage .c-hero__phone {
    display: none;
  }
}

.c-hero--lander {
  background-color: #0f4471;
  color: #ffffff;
  position: relative;
}
@media (max-width: 54.9375em) {
  .c-hero--lander {
    padding-top: 2rem;
  }
}
.c-hero--lander:after {
  content: "";
  display: block;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 6rem;
  background-color: #ffffff;
  position: absolute;
}
.c-hero--lander .c-hero__content {
  position: relative;
  z-index: 1;
}
.c-hero--lander .c-hero__copy > *:last-child {
  margin-bottom: 0;
}
.c-hero--lander .c-hero__title,
.c-hero--lander h1 {
  color: inherit;
  margin-bottom: 1.5rem;
}
.c-hero--lander .c-hero__main {
  font-size: 1.125rem;
  line-height: 1.7777777778;
}
@media (min-width: 35em) {
  .c-hero--lander .c-hero__main {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-hero--lander .c-hero__main {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-hero--lander .c-hero__description {
  font-size: 1.125rem;
  line-height: 1.7777777778;
  margin-top: 1.5rem;
  max-width: auto;
  max-width: 100%;
}
@media (min-width: 35em) {
  .c-hero--lander .c-hero__description {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (min-width: 55em) {
  .c-hero--lander .c-hero__description {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}
.c-hero--lander .c-ul {
  font-size: 1.0625rem;
  line-height: 1.4117647059;
}
@media (min-width: 35em) {
  .c-hero--lander .c-ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 55em) {
  .c-hero--lander .c-ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
.c-hero--lander .c-ul__item {
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.c-hero--lander .c-ul__item:before {
  left: auto;
  transform: translateX(-1rem);
  top: 0.75rem;
  background-color: #f8f8f8;
}
.c-hero--lander .c-hero__image {
  margin-top: 1rem;
  max-width: 100%;
  height: auto;
}
.c-hero--lander .c-form__footer .c-button {
  width: 100%;
}

.c-hero--full {
  position: static;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.c-hero--full:after {
  display: none;
}

.c-hero--profile .c-hero__main {
  min-height: auto;
}
.c-hero--profile .c-hero__title {
  color: #ffffff;
}
.c-hero--profile .c-hero__description {
  color: #ffffff;
}
.c-hero--profile .c-hero__before {
  width: auto;
}
.c-hero--profile .c-hero__before img {
  margin-bottom: 0;
}

.c-hero--center {
  text-align: center;
}
.c-hero--center .c-hero__content {
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}
.c-hero--center .c-hero__main {
  margin-bottom: 2.5rem;
}
.c-hero--center .c-hero__description {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;
}
@media (min-width: 35em) {
  .c-hero--center .c-hero__description {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-hero--center .c-hero__description {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-hero--center .c-form__radio {
    flex-basis: 10rem;
  }
}

.c-hero--split {
  padding-top: 2.5rem;
  overflow: hidden;
  padding-bottom: 1rem;
}
.c-hero--split:after {
  display: none;
}
.c-hero--split .c-hero__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.c-hero--split .c-hero__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 45rem;
}
@media (max-width: 54.9375em) {
  .c-hero--split .c-hero__main {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
}
@media (min-width: 55em) {
  .c-hero--split .c-hero__main {
    width: 60%;
  }
}
.c-hero--split .c-hero__main:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-250vw, -6rem);
  width: 1000vw;
  height: 1000vh;
  background: #f8f8f8;
  z-index: 0;
}
.c-hero--split .c-hero__image {
  position: relative;
  z-index: 1;
}
.c-hero--split .c-hero__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (max-width: 54.9375em) {
  .c-hero--split .c-hero__aside {
    margin-top: 1rem;
  }
}
@media (min-width: 55em) {
  .c-hero--split .c-hero__aside {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 40%;
  }
}

.no-flexbox .c-hero--lander,
.no-flexboxlegacy .c-hero--lander {
  overflow: hidden;
}
.no-flexbox .c-hero--split .c-hero__main,
.no-flexboxlegacy .c-hero--split .c-hero__main {
  display: block;
  float: left;
}
.no-flexbox .c-hero--split .c-hero__aside,
.no-flexboxlegacy .c-hero--split .c-hero__aside {
  display: block;
  float: right;
}

.c-hero--featured {
  padding-bottom: 0;
}
.c-hero--featured .c-hero__content {
  position: relative;
}
@media (min-width: 55em) {
  .c-hero--featured .c-hero__aside {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.c-hero--featured .c-aside {
  background-color: #ffffff;
}
@media (min-width: 55em) {
  .c-hero--featured .c-aside {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 55em) {
  .c-hero--featured .c-hero__main {
    min-height: 19rem;
  }
}

/*.c-hero--buttonMobile {

    @include media-breakpoint-up(l) {
        display: none;
    }
}*/
@media (min-width: 55em) {
  .c-hero--no-image .c-hero__button {
    display: none;
  }
}

@media (max-width: 34.9375em) {
  .c-hero--horizontal {
    background: linear-gradient(to bottom, #0f4471 50%, #ffffff 50%);
    padding-bottom: 2rem;
  }
  .c-hero--horizontal::after {
    content: none;
  }
  .c-hero--horizontal .c-form__radio {
    flex: auto;
  }
  .c-hero--horizontal .c-form__label--radio {
    font-size: 0.875rem;
  }
}
@media (max-width: 54.9375em) {
  .c-hero--horizontal .c-picture {
    display: none;
  }
  .c-hero--horizontal .c-hero__main {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) {
  .c-hero--horizontal .c-form__step-container .c-form__fields {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }
}
.c-hero--horizontal .c-form .c-disclaimer {
  margin-top: 1rem;
}
.c-hero--horizontal .c-form__nav {
  max-width: unset;
}
.c-hero--horizontal .c-hero__main {
  display: flex;
  justify-content: space-between;
}
.c-hero--horizontal .c-hero__copy {
  max-width: 37.5rem;
}
@media (min-width: 35em) {
  .c-hero--horizontal .c-pro-con__list--pros {
    display: flex;
    gap: 1.25rem;
  }
}
.c-hero--horizontal .c-pro-con__list--pros li {
  font-size: 1rem;
}
.c-hero--horizontal .c-pro-con__list--pros li::before {
  content: url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="%2318d8b8" /%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="%2318d8b8" /%3E%3C/svg%3E');
  margin-right: 0.5rem;
}

.c-hero--lander.hero--variant-c .c-form__title {
  margin-bottom: 2rem;
}
.c-hero--lander.hero--variant-c .c-hero__main {
  justify-content: center;
}
@media (min-width: 55em) {
  .c-hero--lander.hero--variant-c {
    margin-bottom: 4rem;
  }
  .c-hero--lander.hero--variant-c .c-pro-con__list--pros {
    flex-direction: column;
  }
  .c-hero--lander.hero--variant-c .c-hero__main {
    display: block;
  }
  .c-hero--lander.hero--variant-c .c-hero__content {
    display: grid;
    grid-template-columns: 1fr 25rem;
    grid-template-rows: 1fr;
    column-gap: 1.5rem;
  }
  .c-hero--lander.hero--variant-c::after {
    display: none;
  }
}

.c-form .c-disclaimer {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  color: #424242;
  font-style: normal;
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.c-form .c-disclaimer:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1;
  content: "\f023";
  flex: 0 0 auto;
  padding-right: 1rem;
}

.c-form__wrapper {
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 0 2rem 1.5rem;
  box-shadow: 0 0 0.5rem 0 rgba(15, 68, 113, 0.3);
}
@media (max-width: 54.9375em) {
  .c-form__wrapper {
    max-width: 38rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.c-form__title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #ffffff;
  background-color: rgb(11.16, 46.24, 76.84);
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-left: -2rem;
  margin-right: -2rem;
}
@media (min-width: 35em) {
  .c-form__title {
    font-size: 1.1875rem;
    line-height: 1.0526315789;
  }
}
@media (min-width: 55em) {
  .c-form__title {
    font-size: 1.25rem;
    line-height: 1;
  }
}

.c-form__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
.c-form__nav:before {
  content: "";
  display: block;
  height: 0.0625rem;
  width: 100%;
  background: rgb(184.28, 184.28, 184.28);
  top: 50%;
  left: 0;
  position: absolute;
  z-index: 0;
}

.c-form__nav-item {
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.c-form__nav-button {
  font-size: 0.75rem;
  line-height: 1;
  width: 1.5rem;
  height: 1.5rem;
  font-weight: bold;
  text-align: center;
  background: #ffffff;
  border: 0.0625rem solid rgba(48, 55, 64, 0.3);
  color: rgba(48, 55, 64, 0.3);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  box-shadow: 0 0 0 0.5rem #ffffff;
}
.c-form__nav-button.is-active {
  border-width: 0.125rem;
  border-color: #0f4471;
  color: #0f4471;
}

.c-form__group {
  margin-bottom: 0.75rem;
}

.c-form__footer {
  margin-top: 1rem;
}

.c-form__fields--split {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}
.c-form__fields--split .c-form__group {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 50%;
}

.c-form__wrapper--single {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.c-form--single {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.c-form--single .c-form__submit {
  width: 100%;
}

.c-form--search {
  display: flex;
}
.c-form--search .c-button {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.75rem;
}

.no-flexbox .c-hero--split .c-hero__main:after,
.no-flexboxlegacy .c-hero--split .c-hero__main:after {
  background: transparent;
}
.no-flexbox .c-form__nav,
.no-flexboxlegacy .c-form__nav {
  display: block;
}
.no-flexbox .c-form__nav-item,
.no-flexboxlegacy .c-form__nav-item {
  float: left;
  width: 33%;
}
.no-flexbox .c-form__nav,
.no-flexboxlegacy .c-form__nav {
  width: 100%;
}
.no-flexbox .c-form__step,
.no-flexboxlegacy .c-form__step {
  width: 100%;
}

@media (min-width: 35em) {
  .hero--variant-b fieldset[data-step="1"] .c-form__fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 1rem;
  }
}
@media (min-width: 35em) {
  .hero--variant-b fieldset[data-step="1"] .c-form__fields .c-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 35em) {
  .hero--variant-b fieldset[data-step="2"] .c-form__fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    column-gap: 1rem;
  }
}
@media (min-width: 55em) {
  .hero--variant-b fieldset[data-step="2"] .c-form__fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 1rem;
  }
}
@media (min-width: 55em) {
  .hero--variant-b fieldset[data-step="2"] .c-form__fields .c-form__group:first-of-type {
    grid-column: 1/span 2;
  }
}
@media (min-width: 35em) {
  .hero--variant-b fieldset[data-step="2"] .c-form__fields .c-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
  }
}

.c-media-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 55em) {
  .c-media-block {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    justify-content: space-between;
    align-items: center;
  }
}

.c-media-block__media {
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-media-block__media {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-media-block__media {
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-media-block__media {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
}

.c-media-block__image {
  position: relative;
  display: inline-block;
}
.c-media-block__image .o-image {
  max-width: 100%;
  z-index: 1;
  position: relative;
}

@media (min-width: 55em) {
  .c-media-block__content {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
  }
  .c-media-block__content .c-wysiwyg {
    max-width: 100%;
  }
}
.c-media-block__content .c-button {
  margin-right: 1rem;
}

@media (min-width: 55em) {
  .c-media-block--media-right .c-media-block__media {
    order: 2;
  }
}

@media (min-width: 55em) {
  .c-media-block--media-smaller .c-media-block__content {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .c-media-block--media-smaller .c-media-block__media {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 55em) {
  .c-media-block--third .c-media-block__content {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .c-media-block--third .c-media-block__media {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

.c-media-block .c-wysiwyg {
  margin-top: 0;
}
.c-media-block .c-ul li {
  margin-bottom: 1.5rem;
}

@media (min-width: 55em) {
  .c-media-block__media {
    width: 33.3333333333%;
  }
}

@media (min-width: 55em) {
  .c-media-block__content {
    width: 66.6666666667%;
  }
}

.c-media-block--nm {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 55em) {
  .c-media-block--col-6-4 .c-media-block__content {
    width: 55%;
  }
}
@media (min-width: 55em) {
  .c-media-block--col-6-4 .c-media-block__media {
    width: 45%;
  }
}

.c-page--lander .c-media-block h2:after {
  right: initial;
}

.c-expand-menu--collapsible {
  padding-left: 1.5rem;
}

.c-expand-menu__list--d2 {
  display: none;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  background: #f8f8f8;
  padding-left: 3rem;
  padding-right: 1.5rem;
}

.c-expand-menu__list--d3 {
  padding-left: 1.5rem;
}

.c-expand-menu__item--d1.is-active .c-expand-menu__list--d2 {
  display: block;
}
.c-expand-menu__item--d1.is-active .o-icon {
  transform: rotate(180deg);
}

.c-expand-menu__item-wrapper {
  display: flex;
  align-items: center;
}

.c-expand-menu__link {
  font-size: 1rem;
  line-height: 1.5;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-decoration: none;
  flex: 1 1 auto;
  color: #303740;
}

.c-expand-menu__toggle {
  font-size: 1.25rem;
  line-height: 1;
  flex: 0 0 auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  width: auto;
}
@media (min-width: 35em) {
  .c-expand-menu__toggle {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (min-width: 55em) {
  .c-expand-menu__toggle {
    font-size: 1.625rem;
    line-height: 1.0769230769;
  }
}
.c-expand-menu__toggle .o-icon {
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}

.c-jump-to__list {
  font-size: 1rem;
  line-height: 1.25;
}

.c-jump-to__item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.c-jump-to__link {
  color: #424242;
  text-decoration: none;
}
.c-jump-to__link:hover {
  text-decoration: underline;
}

.c-hero__aside .c-jump-to__item {
  border-top: 0.0625rem solid rgb(217.56, 217.56, 217.56);
  padding-left: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}
.c-hero__aside .c-jump-to__link {
  padding: 1rem 2rem;
  color: #424242;
  text-decoration: none;
  position: relative;
  display: block;
  font-weight: bold;
  font-family: "Poppins", "Lato", Arial;
}
.c-hero__aside .c-jump-to__link:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90a";
  color: #0f4471;
  position: absolute;
  left: 0;
  line-height: 1.375rem;
  font-weight: bold;
}
.c-hero__aside .c-jump-to__link:hover {
  text-decoration: underline;
  color: #0f4471;
}
.no-flexboxlegacy .c-hero__aside .c-jump-to__link:before {
  padding-top: 10px;
}

.c-page--one-column .c-attribution--header {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.c-site-menu {
  font-family: "Poppins", "Lato", Arial;
}

.c-site-menu__item--d1 {
  display: flex;
  align-items: center;
}

@media (max-width: 54.9375em) {
  .c-site-menu--collapsible {
    padding-left: 1.5rem;
  }
  .c-site-menu__list--d1 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 0;
  }
  .c-site-menu__list--d2 {
    display: none;
    width: 100%;
    background: #f8f8f8;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .c-site-menu__item--has-children.is-active .c-site-menu__list--d2 {
    display: block;
  }
  .c-site-menu__item--d2,
  .c-site-menu__item--d3 {
    padding-left: 1.5rem;
  }
  .c-site-menu__item {
    font-size: 1rem;
    line-height: 1.75;
    flex-wrap: wrap;
  }
  .c-site-menu__item--d1.is-active .c-site-menu__list--d2 {
    display: block;
  }
  .c-site-menu__item--d1.is-active .o-icon {
    transform: rotate(180deg);
  }
  .c-site-menu__item-wrapper {
    display: flex;
    align-items: center;
  }
  .c-site-menu__link {
    font-size: 1rem;
    line-height: 1.5;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    text-decoration: none;
    flex: 1 1 auto;
    color: #303740;
    display: inline-block;
    transition: all 0.2s ease-in-out;
  }
  .c-site-menu__link:hover {
    text-decoration: underline;
    color: #0f4471;
  }
  .c-site-menu__item--current > .c-site-menu__link {
    font-weight: bold;
    color: #0f4471;
  }
  .c-site-menu__link--d1 {
    padding: 1rem 0 1rem 1.5rem;
  }
  .c-site-menu__toggle {
    font-size: 1.25rem;
    line-height: 1;
    flex: 0 0 auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: auto;
  }
}
@media (max-width: 54.9375em) and (min-width: 35em) {
  .c-site-menu__toggle {
    font-size: 1.4375rem;
    line-height: 1.0434782609;
  }
}
@media (max-width: 54.9375em) and (min-width: 55em) {
  .c-site-menu__toggle {
    font-size: 1.625rem;
    line-height: 1.0769230769;
  }
}
@media (max-width: 54.9375em) {
  .c-site-menu__toggle .o-icon {
    transform: rotate(0deg);
    transition: 0.2s ease-in-out;
  }
}
@media (min-width: 55em) {
  .c-site-menu__list {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-grow: 1;
  }
  .c-site-menu__item--has-children:hover > .c-site-menu__list {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
  .c-site-menu__list--d1 {
    position: relative;
  }
  .c-site-menu__item--d1 {
    line-height: 3rem;
    height: 3rem;
    color: #303740;
    position: relative;
    background-color: transparent;
    transition: background-color 0.2s ease;
    position: initial;
  }
  .c-site-menu__item--d1 .c-button {
    line-height: inherit;
    padding: 0;
    border: 0;
    margin-left: 0.25rem;
    display: inline-block;
    height: 3rem;
  }
  .c-site-menu__item--d1 .c-button .o-icon:after {
    content: "\f0d7";
    color: #0f4471;
  }
  .c-site-menu__item--d1:hover {
    color: #0f4471;
    background-color: transparent;
  }
  .c-site-menu__item--d1:hover .c-site-menu__link--d1 {
    text-decoration: none;
  }
  .c-site-menu__item--d1:hover .c-site-menu__link--d1:before, .c-site-menu__item--d1:hover .c-site-menu__link--d1:after {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
  }
  .c-site-menu__link {
    color: inherit;
    text-decoration: none;
  }
  .c-site-menu__link:hover {
    text-decoration: underline;
    color: #303740;
  }
  .c-site-menu__link--d1 {
    display: inline-block;
    line-height: inherit;
    color: inherit;
    font-weight: bold;
    position: relative;
    display: inline-block;
    z-index: 2;
  }
  .c-site-menu__link--d1:hover {
    color: #0f4471;
  }
  .c-site-menu__item--has-children .c-site-menu__link--d1 {
    color: inherit;
  }
  .c-site-menu__item--has-children .c-site-menu__link--d1:before, .c-site-menu__item--has-children .c-site-menu__link--d1:after {
    content: "";
    width: 0;
    height: 0;
    border-width: 0 0.75rem 0.75rem 0.75rem;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: rgb(217.56, 217.56, 217.56);
    position: absolute;
    top: 2.25rem;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: 0.2s, 0s;
    transition-delay: 0s, 0.2s;
  }
  .c-site-menu__item--has-children .c-site-menu__link--d1:after {
    top: 2.3125rem;
    border-bottom-color: #ffffff;
  }
  .c-site-menu__list--d2 {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    flex-direction: column;
    min-width: 15rem;
    padding: 0 1rem;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity, visibility;
    transition-duration: 0.2s, 0s;
    transition-delay: 0s, 0.2s;
    width: 46rem;
    left: auto;
    right: 0;
    padding: 1.5rem 3rem 2.5rem;
    box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
    border: 0.0625rem solid rgb(217.56, 217.56, 217.56);
    display: block;
    column-count: 2;
    column-gap: 2rem;
  }
  .c-site-menu__item--d2 {
    font-size: 1rem;
    line-height: 1.5;
    width: 100%;
    left: auto;
    right: 0;
    margin: 0.75rem 0;
    color: #303740;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
    break-inside: avoid;
  }
  .c-site-menu__item--d2:first-child {
    margin-top: 0;
  }
  .c-site-menu__item--d2:last-child {
    margin-bottom: 0;
  }
  .c-site-menu__link--d2 {
    font-size: 1rem;
    line-height: 1.25;
    display: block;
    color: #303740;
    font-weight: 700;
  }
  .c-site-menu__link--d2:hover {
    color: inherit;
  }
  .c-site-menu__item--has-children:hover .c-site-menu__link--d2 {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
  .c-site-menu__list--d3 {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    margin-top: 1rem;
    border-left: 0.125rem solid rgb(217.56, 217.56, 217.56);
    padding-left: 1rem;
  }
  .c-site-menu__item--d3 {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .c-site-menu__item--d3:first-child {
    padding-top: 0;
  }
  .c-site-menu__item--d3:last-child {
    padding-bottom: 0;
  }
  .c-site-menu__link--d3 {
    color: rgb(114.24, 119, 125.12);
  }
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-site-menu__item--d1 {
    font-size: 0.875rem;
    padding: 0 0.75rem;
  }
}
@media (min-width: 75em) {
  .c-site-menu__item--d1 {
    font-size: 1rem;
    padding: 0 1rem;
  }
}
.no-flexbox .c-site-header__left,
.no-flexboxlegacy .c-site-header__left {
  float: left;
}
.no-flexbox .c-site-menu__list--d1,
.no-flexboxlegacy .c-site-menu__list--d1 {
  width: 45rem;
  float: left;
}
.no-flexbox .c-site-menu__item,
.no-flexboxlegacy .c-site-menu__item {
  display: inline-block;
}
.no-flexbox .c-site-menu__list--d2,
.no-flexboxlegacy .c-site-menu__list--d2 {
  left: 0;
  min-width: 13rem;
  z-index: 9;
}
.no-flexbox .c-site-menu__item--d3,
.no-flexboxlegacy .c-site-menu__item--d3 {
  display: block;
}

@media (min-width: 35em) {
  .c-hero__aside .c-menu {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

.c-menu__item {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.c-menu__item--current > .c-menu__link {
  position: relative;
}
.c-menu__item--current > .c-menu__link:before {
  content: "";
  display: block;
  width: 0.125rem;
  height: 100%;
  position: absolute;
  left: -0.5rem;
  background: #199edc;
}
.c-menu--footer .c-menu__item--current > .c-menu__link:before {
  display: none;
}

.c-menu__link {
  color: #424242;
  text-decoration: none;
}
.c-menu__link:hover {
  text-decoration: underline;
}

.c-menu__list--d1 {
  font-size: 1rem;
  line-height: 1.25;
}

.c-menu__link--d1 {
  font-weight: 700;
}

.c-menu__list--d2 {
  padding-left: 0.75rem;
}

.c-menu__list--d3 {
  padding-left: 2rem;
}

.c-menu__link--d3 {
  color: rgb(111, 102.68, 102.68);
}

.c-menu--simple .c-menu__link--d1 {
  font-weight: 400;
}

.c-modal {
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background-color: #f8f8f8;
  padding: 1.5rem;
}

.c-modal__header {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1;
}
@media (min-width: 35em) {
  .c-modal__header {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
@media (min-width: 55em) {
  .c-modal__header {
    font-size: 1.625rem;
    line-height: 1.2307692308;
  }
}

.c-modal__content {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  font-family: "Poppins", "Lato", Arial;
}
@media (min-width: 35em) {
  .c-modal__content {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-modal__content {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-modal--fact-check {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.c-modal--medically-reviewed .c-modal__content {
  display: flex;
}
.c-modal--medically-reviewed .o-icon {
  font-size: 1.6875rem;
  color: #199edc;
  margin-right: 1rem;
}
.c-modal--medically-reviewed .c-modal__header {
  margin: 0;
  line-height: 1;
}
.c-modal--medically-reviewed .c-attribution__desc {
  color: #303740;
  font-weight: bold;
}
.c-modal--medically-reviewed .c-attribution__reviewer {
  border-radius: 0.25rem;
  border-left: 0.25rem solid #199edc;
  padding-left: 1rem;
  margin-top: 1rem;
}
.c-modal--medically-reviewed .c-attribution__reviewer__name {
  font-size: 1.5rem;
  font-family: "Poppins", "Lato", Arial;
  color: #0f4471;
  font-weight: bold;
  line-height: normal;
}
.c-modal--medically-reviewed .c-attribution__reviewer__title {
  font-size: 1rem;
  line-height: 1.75;
  font-style: italic;
}

@media (max-width: 54.9375em) {
  .c-aside__header {
    background: #0f4471;
    text-align: center;
    margin-bottom: 2rem;
  }
}
@media (min-width: 55em) {
  .c-aside__header {
    display: none;
  }
}

.c-aside__content {
  border-radius: 0 0 0.25rem 0.25rem;
}
@media (max-width: 54.9375em) {
  .c-aside__content {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 3rem;
  }
  .c-aside__content:before {
    content: "";
    display: block;
    width: 90%;
    height: 3rem;
    background-image: url("../assets/img//logos/logo-dark.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 1.5rem auto;
  }
}

@media (max-width: 54.9375em) {
  .c-aside__section {
    display: none;
  }
}
@media (min-width: 55em) {
  .c-aside__section {
    margin-bottom: 4rem;
  }
  .c-aside__section:first-child {
    margin-bottom: 2.5rem;
  }
}

.c-hero__aside .c-aside__section .c-button--menu {
  position: relative;
}
@media (min-width: 55em) {
  .c-hero__aside .c-aside__section .c-button--menu:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.0625rem;
    position: absolute;
    color: #0f4471;
    content: "\f067";
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
  }
  .c-hero__aside .c-aside__section .c-button--menu:hover:after {
    color: #199edc;
  }
}
@media (max-width: 54.9375em) {
  .c-hero__aside .c-aside__section.is-active .c-aside__toggle-wrapper {
    display: block !important;
  }
}
@media (min-width: 55em) {
  .c-hero__aside .c-aside__section.is-active .c-button--menu:after {
    content: "\f068";
  }
}
@media (min-width: 55em) {
  .c-hero__aside .c-aside__section {
    margin-bottom: 0;
  }
}
@media (max-width: 54.9375em) {
  .c-hero__aside .c-aside__section .c-button--menu {
    display: none;
  }
}

@media (min-width: 55em) {
  .c-aside__mobileHeader {
    display: none;
  }
}
@media (max-width: 54.9375em) {
  .c-aside__mobileHeader {
    font-size: 1.125rem;
    line-height: 1.7777777778;
    text-transform: uppercase;
    background-color: #ffffff;
    text-align: left;
    color: #0f4471;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-top: 0.0625rem solid rgb(200.92, 200.92, 200.92);
  }
}
@media (max-width: 54.9375em) and (min-width: 35em) {
  .c-aside__mobileHeader {
    font-size: 1.1875rem;
    line-height: 1.6842105263;
  }
}
@media (max-width: 54.9375em) and (min-width: 55em) {
  .c-aside__mobileHeader {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

@media (max-width: 54.9375em) {
  .c-aside__related .c-aside__toggle-wrapper {
    padding-left: 2rem;
  }
}

@media (max-width: 54.9375em) {
  .single .c-aside {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 1050;
    background-color: #ffffff;
    transition: top, visibility;
    transition-duration: 0.2s, 0s;
    transition-delay: 0s, 0.2s;
    visibility: hidden;
  }
  .single .c-aside .c-hero__image {
    display: none;
  }
  .single .c-aside.is-active {
    top: 0;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
}

.c-page-summary {
  padding-bottom: 2rem;
  border-bottom: 0.0625rem solid #979797;
  font-family: "Poppins", "Lato", Arial;
  margin-bottom: 4rem;
}
.c-page-summary p:first-child {
  margin-top: 0;
}
.c-page-summary p:last-child {
  margin-bottom: 0;
}
.c-page--one-column .c-page-summary {
  margin-bottom: 1.5rem;
}

.c-page--one-column .c-page-summary__hummingbird {
  margin-top: 0;
}
.c-page-summary__hummingbird:before {
  font-size: 1rem;
  line-height: 1;
  content: "Summary";
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.5rem;
}

.c-page-summary__button {
  margin-top: 1.5rem;
}

.c-related-posts {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-related-posts__title {
  margin-bottom: 2rem;
}

.c-related-posts__list {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow-x: auto;
}

.c-related-posts__item {
  padding: 0 1rem;
  min-width: 18rem;
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}
.c-related-posts__item .c-post-block--card,
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  display: flex;
  align-items: stretch;
}
.c-related-posts__item .c-post-block--card .c-post-block__inner,
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-direction: column;
}
.c-related-posts__item .c-post-block--card .c-post-block__content {
  flex-grow: 1;
}
.c-related-posts__item .c-post-block--card .c-post-block__after {
  margin-top: auto;
}

.c-related-profiles {
  margin-top: 3rem;
}

.c-related-profiles__heading {
  margin-bottom: 2rem;
  text-align: center;
}

.c-related-profiles__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.c-related-profiles__list li {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 50%;
  margin-bottom: 2rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-related-profiles__list li {
    width: 33.3333333333%;
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-related-profiles__list li {
    width: 25%;
    margin-bottom: 3rem;
  }
}
.c-related-profiles__list .c-profile-block {
  display: flex;
}

.c-related-profiles--card .c-related-profiles__list {
  margin-left: auto;
  margin-right: auto;
}
.c-related-profiles--card li {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
  flex: 0 0 46%;
  margin: 2%;
  width: auto;
  padding: 1rem;
}
.c-related-profiles--card li:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.375rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-related-profiles--card li {
    flex: 0 0 29.33%;
  }
}
@media (min-width: 55em) {
  .c-related-profiles--card li {
    flex: 0 0 21%;
  }
}

.c-related-profiles--two-col li {
  flex: 0 1 96%;
}
@media (min-width: 35em) {
  .c-related-profiles--two-col li {
    flex: 0 1 46%;
  }
}

@media (min-width: 35em) {
  .c-related-profiles--three-col li {
    flex: 0 1 29.33%;
  }
}

.c-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow-x: hidden;
}
@media (min-width: 35em) {
  .c-section {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 55em) {
  .c-section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.c-section__description {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

.c-section--light {
  background-color: #f8f8f8;
}

.c-section--center .c-section__header,
.c-section--center .c-section__footer {
  text-align: center;
}
.c-section--center .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 54.9375em) {
  .c-section--center .c-section__header {
    text-align: center;
  }
}
.c-section--center .c-section__header {
  width: 100%;
}

.c-section--light {
  background: linear-gradient(0deg, #ffffff, #f8f8f8);
}
.c-section--light + .c-cta--full {
  margin-top: 0;
}
.c-section--light + .c-blockquote--primary {
  margin-top: 0;
}

.c-section--primary {
  background-color: #0f4471;
}

.c-section--gradient {
  background: linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
}

.c-section--bg-pattern-1 {
  background-image: url("/wp-content/uploads/bg-pattern-1.png"), linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-section--bg-pattern-2 {
  background-image: url("/wp-content/uploads/bg-pattern-2.png"), linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-section--mobile-only {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  text-align: center;
}
@media (min-width: 55em) {
  .c-section--mobile-only {
    display: none;
  }
}

.c-section--back-to-top {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
}

.c-section__block .c-section__title {
  margin: 0 auto;
  margin-bottom: 0;
}
@media (min-width: 35em) {
  .c-section__block .c-section__title {
    padding: 0 15rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-section__block .c-section__title {
    padding: 0 10rem;
    text-align: center;
  }
}
.c-section__block .c-section__header {
  padding: 0 1rem;
}
.c-section__block .c-section__footer {
  width: 100%;
  background-color: #f1f4f6;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
@media (max-width: 54.9375em) {
  .c-section__block .c-section__footer {
    margin: 0;
  }
}

.c-section--np {
  padding-top: 0;
  padding-bottom: 0;
}

.c-section--npt {
  padding-top: 0;
}
@media (min-width: 35em) {
  .c-section--npt {
    padding-bottom: 3rem;
  }
}

.c-section--border-top .c-section__title {
  border-top: 0.125rem solid rgba(68, 68, 68, 0.2);
  padding-top: 5rem;
}

.c-section--trust-bar {
  padding-top: 0;
}
.c-section--trust-bar .c-action-list__items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}
@media (min-width: 35em) {
  .c-section--trust-bar .c-action-list__items {
    grid-template-columns: repeat(3, 1fr);
  }
}
.c-section--trust-bar .c-action-block {
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  margin-top: 0;
  margin-bottom: 1rem;
  align-items: flex-start;
}
@media (min-width: 35em) {
  .c-section--trust-bar .c-action-list__item:not(:last-of-type) {
    border-right: solid 0.0625rem rgb(217.56, 217.56, 217.56);
  }
}
@media (max-width: 34.9375em) {
  .c-section--trust-bar .c-action-list__item {
    padding-top: 1rem;
    border-bottom: solid 0.0625rem rgb(217.56, 217.56, 217.56);
  }
}

.c-section--media-lp strong {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
@media (min-width: 35em) {
  .c-section--media-lp strong {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-section--media-lp strong {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (max-width: 54.9375em) {
  .c-section--media-lp .c-media-block--media-right {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 55em) {
  .c-section--trust-reviews .c-section__content {
    display: flex;
    gap: 5rem;
  }
  .c-section--trust-reviews .content {
    width: 60%;
  }
  .c-section--trust-reviews .media {
    width: 40%;
    padding: 3rem 5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-section--trust-reviews .c-section__title {
    margin-bottom: 0;
    padding-top: 4rem;
  }
}
.c-section--trust-reviews .media {
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 23.75rem;
  padding: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}
.c-section--trust-reviews .media .h4 {
  margin-top: 0;
  text-align: center;
}
.c-section--trust-reviews .c-action-block__button {
  margin-top: 0;
}
.c-section--trust-reviews .c-action-block__image {
  margin: 0;
}
.c-section--trust-reviews .c-ul__item::before {
  margin-top: 0.25rem;
}
@media (min-width: 35em) {
  .c-section--trust-reviews .c-ul__item {
    font-size: 1.25rem;
  }
}
.c-section--trust-reviews .c-action-list--trust .c-action-list__items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0;
}
.c-section--trust-reviews .c-action-list--trust .c-action-block {
  margin-bottom: 0;
  margin-top: 0;
}
.c-section--trust-reviews .c-action-list--trust .c-action-block__media {
  width: auto;
}

.c-widget {
  width: 100%;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.c-widget__title {
  font-size: 1rem;
  line-height: 1.25;
  font-family: Poppins, Lato, Arial;
  font-weight: normal;
  color: #0d1b26;
  text-transform: uppercase;
  display: block;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #979797;
  margin-bottom: 1.5rem;
}

.c-widget--phone {
  margin-bottom: 1rem;
}

.c-hero--404 .c-hero__content {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.c-hero--404 .c-hero__main {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
}

.c-hero--archive {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-archive__featured {
  max-width: 100%;
}
@media (max-width: 34.9375em) {
  .c-archive__featured {
    margin-bottom: 2rem;
  }
}
@media (min-width: 35em) {
  .c-archive__featured {
    margin-bottom: 4rem;
  }
  .c-archive__featured .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive__featured .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive__featured .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive__featured .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .c-archive__featured .c-post-block__image-wrapper {
    width: 50%;
  }
}

@media (min-width: 35em) {
  .c-archive--full .c-archive__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-column-gap: 2rem;
  }
}

@media (max-width: 34.9375em) {
  .c-archive--full .c-post-block .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive--full .c-post-block .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive--full .c-post-block .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive--full .c-post-block .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .c-archive--full .c-post-block .c-post-block__image-wrapper {
    width: 25%;
  }
  .c-archive--full .c-post-block .c-post-block__content {
    padding: 0 1rem;
  }
  .c-archive--full .c-post-block .c-post-block__category,
  .c-archive--full .c-post-block .c-post-block__copy,
  .c-archive--full .c-post-block .c-profile-block {
    display: none;
  }
}

@media (max-width: 34.9375em) {
  .c-archive--sidebar .c-post-block .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 25%;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1rem;
  }
  .c-archive--sidebar .c-post-block .c-post-block__category,
  .c-archive--sidebar .c-post-block .c-post-block__copy,
  .c-archive--sidebar .c-post-block .c-profile-block {
    display: none;
  }
}
@media (min-width: 35em) {
  .c-archive--sidebar .c-post-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .c-archive--sidebar .c-post-block .c-post-block__inner {
    display: flex;
    align-items: stretch;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image-wrapper {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-archive--sidebar .c-post-block .c-post-block__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .c-archive--sidebar .c-post-block .c-post-block__content {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.c-archive__pagination {
  margin-top: 0.125rem;
  margin-bottom: 2rem;
}

@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-archive--sidebar .c-page__aside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
}

.c-page--profile .c-page__header {
  text-align: center;
  background-color: #f8f8f8;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.c-page--profile .c-page__main {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.c-search__subtitle {
  margin-bottom: 2.5rem;
  margin-top: 0;
}

.c-search__form {
  margin-bottom: 2rem;
}

.c-search__count {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-search__result {
  padding-bottom: 3rem;
}

.c-search__result-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
@media (min-width: 35em) {
  .c-search__result-title {
    font-size: 1.1875rem;
    line-height: 1.2631578947;
  }
}
@media (min-width: 55em) {
  .c-search__result-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
.c-search__result-title a {
  text-decoration: none;
}

.c-search__result-url {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 1rem;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.c-search__result-url a {
  color: rgb(18.28, 107.44, 149.6);
  text-decoration: none;
  width: 100%;
}

.c-search__result-snippet {
  font-size: 1.0625rem;
  line-height: 1.6470588235;
  color: #303740;
}
@media (min-width: 35em) {
  .c-search__result-snippet {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}
@media (min-width: 55em) {
  .c-search__result-snippet {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.c-search__pagination {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.c-page--single .c-hero__content,
.c-page--single .c-page__footer {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--single .c-page__content {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
.c-page--single .c-page__main {
  max-width: 60rem;
  margin: 3rem auto;
}
.c-page--single .c-page__main .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}
.c-page--single .c-page__featured {
  margin-top: 0;
}
@media (min-width: 35em) {
  .c-page--single .c-page__featured {
    max-width: 50%;
    padding-left: 2rem;
  }
}

.home .c-site-header {
  border-bottom: 0;
  box-shadow: none;
}

.c-hero--home {
  background-image: url("/wp-content/uploads/header-bg.jpg");
  background-color: #f7f5f8;
  padding-top: 2.5rem;
  padding-bottom: 10rem;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-position: cover;
}
.c-hero--home .c-hero__title {
  color: #0d1b26;
  font-weight: normal;
  position: relative;
}
.c-hero--home .c-hero__title:before {
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 4rem;
  background-color: #199edc;
  top: -1.5rem;
  left: 0;
}
@media (min-width: 55em) {
  .c-hero--home {
    background-size: cover;
  }
}
@media (max-width: 34.9375em) {
  .c-hero--home {
    background-color: #0f4471;
    background-image: url("../assets/img/cta-pattern.png");
    background-repeat: no-repeat;
    background-position: top right;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;
    height: auto;
  }
  .c-hero--home .c-hero__title {
    color: #ffffff;
  }
  .c-hero--home .c-hero__button {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0f4471;
  }
  .u-hover-children:hover .c-hero--home .c-hero__button, .c-hero--home .c-hero__button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: rgb(9.24, 35.36, 58.76);
  }
  .c-hero--home .c-hero__main {
    top: 0;
  }
}
.c-hero--home .c-hero__title {
  margin-bottom: 2.5rem;
}
.c-hero--home .c-hero__main {
  max-width: 30rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-hero--home {
    background-size: 100%;
    background-position: right bottom;
    padding-bottom: 2.5rem;
  }
  .c-hero--home .c-hero__main {
    max-width: 23rem;
  }
}
@media (min-width: 35em) {
  .c-hero--home {
    min-height: 35rem;
    position: relative;
  }
  .c-hero--home .c-hero__main {
    position: relative;
    top: 8rem;
  }
}
@media (max-width: 54.9375em) {
  .c-hero--home {
    min-height: 5rem;
  }
  .c-hero--home .c-hero__main {
    top: 0;
  }
  .c-hero--home .c-hero__title {
    font-size: 1.75rem;
  }
  .c-hero--home .c-hero__title:before {
    display: none;
  }
}

.c-home .c-section__title {
  margin: 0 auto;
  margin-bottom: 0;
}
@media (min-width: 35em) {
  .c-home .c-section__title {
    padding: 0 15rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-home .c-section__title {
    padding: 0 10rem;
    text-align: center;
  }
}
.c-home .c-section__header {
  padding: 0 1rem;
}
@media (max-width: 54.9375em) {
  .c-home .c-action-list--col-3 .c-action-list__item {
    width: 100%;
  }
}
.c-home .c-section__footer {
  width: 100%;
  background-color: #f1f4f6;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
@media (max-width: 54.9375em) {
  .c-home .c-section__footer {
    margin: 0;
  }
}
@media (max-width: 54.9375em) {
  .c-home .c-action-list--gray-bg .c-action-block__image img {
    width: 4rem;
  }
}
.c-home .c-cta__description {
  text-align: left;
}
.c-home .c-cta {
  margin-top: 0;
}
@media (max-width: 54.9375em) {
  .c-home .c-section--call-out .c-home__subsection-content {
    order: 2;
  }
}
@media (max-width: 54.9375em) {
  .c-home .c-section--call-out .c-home__subsection-image {
    order: 1;
  }
}

.c-home__subsection {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 1 1 30rem;
  max-width: 40rem;
  margin: 2rem auto;
}
.c-home__subsection > *:first-child {
  margin-top: 0;
}
.c-home__subsection > *:last-child {
  margin-bottom: 0;
}
.c-home__subsection h2 {
  margin-bottom: 0;
}
.c-section--call-out .c-home__subsection {
  color: #ffffff;
}
.c-section--call-out .c-home__subsection h2 {
  color: #ffffff;
}
.c-section--text-center .c-home__subsection {
  text-align: center;
}

.c-section--call-out .c-home__subsection {
  margin-top: 0;
  margin-bottom: 0;
}

.c-section--cited {
  padding-bottom: 0;
}

.c-section--cited-items {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.c-home__subsection-content p:first-of-type {
  font-size: 1.3125rem;
}
@media (min-width: 35em) {
  .c-home__subsection-content {
    padding-left: 4rem;
    padding-top: 1.5rem;
  }
}

.c-home__subsection-image img {
  width: 100%;
  border-radius: 1rem;
}

.covid-banner {
  width: 100%;
  background: #f8f8f8;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.covid-banner p {
  text-align: center;
  margin: 0;
  font-size: 0.875rem;
  color: #424242;
}
.covid-banner p span {
  color: #d03232;
  font-weight: bold;
}

.l-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.c-page {
  flex: 1;
}

.l-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 75em;
}

.l-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.c-page--one-column > .l-container {
  max-width: 42rem;
}

.c-page--one-column.c-page--backpage .c-page__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
}
.c-page--one-column .c-wysiwyg {
  margin-left: auto;
  margin-right: auto;
}
.c-page--one-column .c-page__footer {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--one-column .c-figure {
  width: 100%;
}
.c-page--one-column .c-figure__image {
  width: 100%;
  height: auto;
}

.c-page--two-column .c-page__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  justify-content: space-around;
}
.c-page--two-column .c-page__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__main {
    max-width: 60%;
    width: 66.6666666667%;
    margin-left: 0;
  }
}
.c-page--two-column .c-page__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-page--two-column .c-page__aside {
    width: 33.3333333333%;
    margin-left: auto;
  }
}

.no-flexbox .c-page--two-column .c-page__main,
.no-flexboxlegacy .c-page--two-column .c-page__main {
  float: left;
}
.no-flexbox .c-page--two-column .c-page__aside,
.no-flexboxlegacy .c-page--two-column .c-page__aside {
  float: left;
}

@media (max-width: 34.9375em) {
  .c-about .c-cta__button a {
    width: 100%;
  }
}
@media (max-width: 54.9375em) {
  .c-about .c-breadcrumbs:after {
    background: linear-gradient(to right, rgba(241, 244, 246, 0) 0%, rgba(241, 244, 246, 0) 100%);
  }
}
.c-about .c-hero {
  margin-bottom: 0;
  min-height: 25.625rem;
  background-color: #f1f4f6;
  background-image: url("/wp-content/uploads/hero-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 34.9375em) {
  .c-about .c-hero {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.c-about .c-hero .c-hero__title {
  color: #0d1b26;
  font-weight: normal;
  position: relative;
  margin-top: 6.25rem;
}
@media (max-width: 34.9375em) {
  .c-about .c-hero .c-hero__title {
    margin-top: 3.125rem;
  }
}
.c-about .c-hero .c-hero__title:before {
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 4rem;
  background-color: #199edc;
  top: -1.5rem;
  left: 0;
}
.c-about .c-hero .c-hero__description {
  color: #0d1b26;
  font-size: 1.3125rem;
}
@media (max-width: 34.9375em) {
  .c-about .c-hero .c-hero__description {
    font-size: 1.125rem;
  }
}
.c-about .c-hero .c-breadcrumbs,
.c-about .c-hero .c-breadcrumbs a,
.c-about .c-hero .c-breadcrumbs span {
  color: #0d1b26;
}
.c-about .c-profile-block {
  margin-bottom: 2.5rem;
}
.c-about .c-profile-block__image {
  border-radius: 100%;
}
.c-about .c-profile-block__after {
  font-size: 1rem;
  line-height: 1.5;
}
.c-about .c-profile-block__after p {
  margin: 0;
}
.c-about .c-section--cited-items .c-action-list__item .c-action-block {
  margin-top: 1.5rem;
}
.c-about .c-section--cited-items .c-section__header {
  margin-bottom: 0;
}
.c-about .c-section--cited-items .c-section__header .c-section__title {
  margin-top: 0;
}
.c-about .c-section__header {
  padding: 0 15rem;
  margin-bottom: 0;
}
.c-about .c-section__header .c-section__title {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-about .c-section__header {
    padding: 0 8rem;
  }
}
@media (max-width: 54.9375em) {
  .c-about .c-section__header {
    padding: 0 5rem;
  }
}
@media (max-width: 34.9375em) {
  .c-about .c-section__header {
    padding: 0 1rem;
    margin-bottom: 0;
  }
}
.c-about .c-section__footer {
  width: 100%;
  background-color: #f1f4f6;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
@media (max-width: 54.9375em) {
  .c-about .c-section__footer {
    margin: 0;
  }
}
.c-about .c-section--action-list {
  margin-top: 5rem;
  background-color: #f1f4f6;
  background-image: url("/wp-content/uploads/content-bg-1.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.c-about .c-action-list--col-4 .c-action-block {
  flex-direction: row;
  align-items: center;
}
@media (max-width: 34.9375em) {
  .c-about .c-action-list--col-4 .c-action-block {
    padding: 1rem;
  }
}
.c-about .c-action-list--col-4 .c-action-block .c-action-block__content {
  padding-left: 1.5rem;
}
@media (max-width: 34.9375em) {
  .c-about .c-action-list--col-4 .c-action-block .c-action-block__content {
    padding-left: 0;
  }
}
.c-about .c-action-list--col-4 .c-action-block .c-action-block__image {
  margin-bottom: 0;
}
.c-about .c-section--profiles {
  padding-top: 0;
  padding-bottom: 0;
}
.c-about .c-section--profiles h2 {
  text-align: center;
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.c-about .c-section--profiles h3 {
  margin-bottom: 0;
}
.c-about .c-section--profiles h3:first-child {
  margin-top: 0;
}
@media (min-width: 55em) and (max-width: 74.9375em) {
  .c-about .c-section--profiles .l-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 35em) {
  .c-about .c-section--profiles .l-container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media (max-width: 54.9375em) {
  .c-about .c-section--profiles .l-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles .l-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.c-about .c-section--profiles .c-section--profiles-content {
  padding-left: 5rem;
  padding-right: 5rem;
}
@media (max-width: 54.9375em) {
  .c-about .c-section--profiles .c-section--profiles-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles .c-section--profiles-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.c-about .c-section--profiles .c-profile-block {
  background-color: #f1f4f6;
  padding: 3rem;
  margin-bottom: 4rem;
  border-radius: 0.5rem;
}
@media (max-width: 54.9375em) {
  .c-about .c-section--profiles .c-profile-block {
    flex-direction: column;
  }
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles .c-profile-block {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
}
.c-about .c-section--profiles .c-profile-block .c-profile-block__image {
  border-radius: 0.4375rem;
  margin-right: 2rem;
}
@media (max-width: 54.9375em) {
  .c-about .c-section--profiles .c-profile-block .c-profile-block__image {
    width: 40%;
  }
}
.c-about .c-section--profiles .c-profile-block .c-profile-block__name {
  font-family: "Poppins", "Lato", Arial;
  font-size: 1.4375rem;
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles .c-profile-block .c-profile-block__name {
    font-size: 1.125rem;
  }
}
.c-about .c-section--profiles .c-profile-block .c-profile-block__title {
  font-family: "Poppins", "Lato", Arial;
  font-size: 1.125rem;
  color: #0f4471;
  margin-bottom: 1.5rem;
}
@media (max-width: 34.9375em) {
  .c-about .c-section--profiles .c-profile-block .c-profile-block__title {
    font-size: 1rem;
  }
}
.c-about .c-section--gray-bg {
  background-color: #f1f4f6;
}
@media (max-width: 34.9375em) {
  .c-about .c-action-list--col-3 .c-action-block__content {
    word-break: break-all;
  }
}
.c-about .c-action-list--col-3 .c-social-links__item {
  position: relative;
  display: block;
  margin: 1.25rem 0 0;
}
@media (max-width: 34.9375em) {
  .c-about .c-action-list--col-3 .c-social-links__item {
    display: inline;
  }
}
.c-about .c-action-list--col-3 .c-social-links__item span {
  font-size: 0.875rem;
  font-weight: normal;
  text-decoration: underline;
  position: absolute;
  text-align: left;
  left: 3.125rem;
  width: 16.25rem;
}
@media (min-width: 55em) {
  .c-about .c-action-list--col-3 .c-social-links__item span {
    left: 3.75rem;
  }
}
@media (max-width: 34.9375em) {
  .c-about .c-action-list--col-3 .c-social-links__item span {
    display: none;
  }
}
.c-about .c-action-list--col-3 .c-social-links__item span:hover {
  text-decoration: none;
}
@media (max-width: 54.9375em) {
  .c-about .c-action-list--col-3 .c-action-list__items {
    flex-direction: column;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .c-about .c-action-list--col-3 .c-action-list__items .c-action-list__item .c-action-block {
    min-width: 40rem;
  }
}
.c-about .c-action-list--col-3 .c-action-list__items .c-action-list__item:first-of-type .c-action-block {
  width: 100%;
}
.c-about .c-action-list--col-3 .c-action-list__items .c-action-list__item:nth-of-type(2) .c-action-block {
  margin: 2rem auto 0;
  width: 100%;
}
@media (max-width: 54.9375em) {
  .c-about .c-action-list--col-3 .c-action-list__items .c-action-list__item {
    width: 100%;
  }
}
.c-about .sponsor__media {
  max-width: 100%;
  border-radius: 0.4375rem;
}
.c-about .c-blockquote--secondary .c-blockquote__footer {
  max-width: 50rem;
  margin: 0 auto;
}

.c-about__subsection {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 1 1 30rem;
  max-width: 40rem;
  margin: 2rem auto;
}
.c-about__subsection > *:first-child {
  margin-top: 0;
}
.c-about__subsection > *:last-child {
  margin-bottom: 0;
}
@media (min-width: 35em) {
  .c-about__subsection {
    width: 50%;
  }
  .c-about__subsection img {
    width: 100%;
  }
}
@media (max-width: 54.9375em) {
  .c-about__subsection img {
    display: none;
  }
}

.c-about__subsection-image img {
  border-radius: 0.625rem;
}

.c-about__subsection-content h3 {
  margin-bottom: 0;
}

.c-page {
  overflow-x: hidden;
}

.c-page--lander h2,
.c-page--lander .h2,
.c-section--center h2,
.c-section--center .h2 {
  margin-top: 0;
}
@media (min-width: 55em) {
  .c-page--lander h2:after,
  .c-page--lander .h2:after,
  .c-section--center h2:after,
  .c-section--center .h2:after {
    margin: auto;
    right: 0;
  }
}
.c-page--lander .c-dl,
.c-section--center .c-dl {
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}
.c-page--lander .c-section__content,
.c-section--center .c-section__content {
  position: relative;
}

.c-lander--meso-packet-variant {
  background-image: url("/wp-content/uploads/meso-packet-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.c-lander--meso-packet-variant .c-hero--lander {
  background-color: transparent;
}
.c-lander--meso-packet-variant .c-hero--lander .c-hero__title,
.c-lander--meso-packet-variant .c-hero--lander .c-hero__description {
  color: #0d1b26;
}
@media (max-width: 54.9375em) {
  .c-lander--meso-packet-variant .c-hero--lander .c-hero__before {
    padding-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 54.9375em) {
  .c-lander--meso-packet-variant .c-hero--lander .c-hero__title {
    text-align: center;
  }
}
.c-lander--meso-packet-variant .c-hero--lander .c-form__wrapper {
  background-color: none;
  border-radius: none;
  box-shadow: unset;
}
.c-lander--meso-packet-variant .c-hero__before {
  margin: auto;
  padding-bottom: 3rem;
}
.c-lander--meso-packet-variant .c-hero__content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  justify-content: space-around;
  align-items: center;
}
.c-lander--meso-packet-variant .c-hero__main {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-lander--meso-packet-variant .c-hero__main {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
.c-lander--meso-packet-variant .c-hero__main:after {
  display: none;
}
.c-lander--meso-packet-variant .c-hero__aside {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 55em) {
  .c-lander--meso-packet-variant .c-hero__aside {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
.c-lander--meso-packet-variant .c-form__wrapper {
  background-color: transparent;
  border-radius: none;
  box-shadow: unset;
}
@media (max-width: 54.9375em) {
  .c-lander--meso-packet-variant .c-form__wrapper {
    padding: 0;
  }
}
.c-lander--meso-packet-variant .h2,
.c-lander--meso-packet-variant h2 {
  margin-bottom: 0;
}
.c-lander--meso-packet-variant .item-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  justify-content: center;
}
.c-lander--meso-packet-variant .item-card {
  position: relative;
  width: 100%;
  min-height: 0.0625rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
  color: #424242;
}
@media (max-width: 54.9375em) {
  .c-lander--meso-packet-variant .item-card {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 55em) {
  .c-lander--meso-packet-variant .item-card {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
.c-lander--meso-packet-variant .item-card__header {
  display: flex;
  align-items: center;
}
.c-lander--meso-packet-variant .item-card__header-title {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5rem;
}
.c-lander--meso-packet-variant .c-action-block {
  padding: 1rem;
}
.c-lander--meso-packet-variant .c-section--mobile-only {
  margin-bottom: 0;
  padding-bottom: 2.5rem;
}
.c-lander--meso-packet-variant .c-section--mobile-only .c-button {
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #18d8b8;
  border-color: #18d8b8;
  color: #303740;
}
@media (max-width: 34.9375em) {
  .c-lander--meso-packet-variant .c-section--mobile-only .c-button {
    width: calc(100% - 2rem);
  }
}

.page-template-thank-you .c-page__header {
  background-image: url("/wp-content/uploads/bg-lander-thankyou.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.c-404 .c-hero__title {
  color: #ffffff;
}
.c-404 .c-hero__description {
  color: #ffffff;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}
.c-404 .c-page__header {
  background-image: url("/wp-content/uploads/bg-lander-thankyou.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 55em) {
  .c-page--backpage .c-hero__title {
    max-width: 60%;
  }
}
@media (min-width: 55em) {
  .c-page--backpage .c-page__aside .c-form--search {
    margin-bottom: 2rem;
  }
}
@media (max-width: 54.9375em) {
  .c-page--backpage .c-page__footer {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 55em) {
  .c-page--backpage .c-page__footer {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
.c-page--backpage .c-page__footer-content {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 55em) {
  .c-page--backpage .c-page__footer-content {
    margin-left: 0;
  }
}
.c-page--backpage hr {
  border: 0;
  border-bottom: 0.0625rem solid rgb(217.56, 217.56, 217.56);
}
@media (max-width: 54.9375em) {
  .c-page--backpage hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 55em) {
  .c-page--backpage hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 34.9375em) {
  .patient-resources .c-button {
    width: 100%;
  }
}
.patient-resources .c-about__subsection img {
  width: 100%;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-about__subsection img {
    display: block;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-breadcrumbs:after {
    background: linear-gradient(to right, rgba(241, 244, 246, 0) 0%, rgba(241, 244, 246, 0) 100%);
  }
}
.patient-resources .c-hero {
  margin-bottom: 0;
  min-height: 25.625rem;
  background-color: #f1f4f6;
  background-image: url("/wp-content/uploads/hero-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-hero {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    min-height: auto;
  }
}
.patient-resources .c-hero .c-hero__title {
  color: #0d1b26;
  font-weight: normal;
  position: relative;
  margin-top: 6.25rem;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-hero .c-hero__title {
    margin-top: 3.125rem;
  }
}
.patient-resources .c-hero .c-hero__title:before {
  position: absolute;
  content: "";
  height: 0.125rem;
  width: 4rem;
  background-color: #199edc;
  top: -1.5rem;
  left: 0;
}
.patient-resources .c-hero .c-hero__description {
  color: #0d1b26;
  font-size: 1.3125rem;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-hero .c-hero__description {
    font-size: 1.125rem;
  }
}
.patient-resources .c-hero .c-breadcrumbs,
.patient-resources .c-hero .c-breadcrumbs a,
.patient-resources .c-hero .c-breadcrumbs span {
  color: #0d1b26;
}
.patient-resources .c-section__header {
  text-align: center;
}
@media (min-width: 55em) {
  .patient-resources .c-section__header {
    margin: 0 auto;
    width: 34.625rem;
  }
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .patient-resources .c-section__header {
    margin: 0 auto;
    width: 25rem;
  }
}
.patient-resources .c-section--one {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--one .c-section__title {
    margin-top: 2rem;
    margin-bottom: 0;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--one .c-section__title {
    margin-top: 2.5rem;
    margin-bottom: 0;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--one .c-section__content {
    order: 2;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--one .c-section__image {
    order: 1;
    margin-bottom: 0;
  }
}
.patient-resources .c-section--two {
  padding-top: 1rem;
}
.patient-resources .c-section--two .l-container {
  background-color: #f1f4f6;
  border-radius: 0.5rem;
  padding-top: 3.5rem;
  padding-bottom: 3.75rem;
}
.patient-resources .c-section--two .c-section--books {
  padding-top: 0;
  padding-bottom: 0;
}
.patient-resources .c-section--two .c-section--books .l-container {
  padding-top: 0;
  padding-bottom: 0;
}
.patient-resources .c-section--two .c-cta {
  margin-top: -2.5rem;
  text-align: center;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--two .c-action-block__content,
  .patient-resources .c-section--two .c-action-block__copy,
  .patient-resources .c-section--two .c-action-block__media {
    text-align: center;
  }
}
.patient-resources .c-section--two .c-section__title {
  font-size: 1.125rem;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  padding-bottom: 0;
  margin-bottom: 3.4375rem;
  margin-top: 0;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--two .c-section__title {
    margin-bottom: 1rem;
  }
}
.patient-resources .c-section--two .c-action-list__item .c-action-block {
  margin-top: 0;
  margin-bottom: 4rem;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--two .c-action-list__item .c-action-block {
    margin-bottom: 2.5rem;
  }
}
.patient-resources .c-section--three {
  background-color: #f1f4f6;
  padding-top: 0;
  padding-bottom: 2.5rem;
}
.patient-resources .c-section--three .c-about__subsection img {
  border-radius: 0.375rem;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--three .c-section__content {
    order: 2;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--three .c-section__image {
    order: 1;
    margin-bottom: 0;
  }
}
.patient-resources .c-section--three .c-section__title {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--three .c-section__title {
    margin-top: 0;
  }
}
.patient-resources .c-section--three .l-row {
  align-items: center;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--three {
    background-color: #ffffff;
  }
}
.patient-resources .c-section--four {
  padding-top: 0;
  padding-bottom: 0;
}
.patient-resources .c-section--four .c-about__subsection img {
  border-radius: 0.375rem;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--four .c-section__content {
    order: 2;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--four .c-section__image {
    order: 1;
    margin-bottom: 0;
  }
}
.patient-resources .c-section--four .c-section__title {
  margin-bottom: 1rem;
  padding-bottom: 0;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .c-section__title {
    margin-top: 0;
  }
}
.patient-resources .c-section--four .c-cta__description {
  text-align: left;
  margin-left: 0;
}
.patient-resources .c-section--four .l-row {
  align-items: center;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--four .c-section--cta {
    padding-top: 1rem;
  }
}
.patient-resources .c-section--four .cta-block {
  background-color: #f1f4f6;
  background-image: url("/wp-content/uploads/content-bg-1.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.5rem;
  padding: 3.5rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .patient-resources .c-section--four .cta-block {
    padding: 2rem;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .cta-block {
    padding: 2.5rem;
  }
}
.patient-resources .c-section--four .cta-block .c-cta__title {
  display: block;
  font-size: 1.6875rem;
  margin-bottom: 2rem;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .cta-block .c-cta__title {
    text-align: center;
  }
}
.patient-resources .c-section--four .cta-block .c-section__cta-image {
  width: 40%;
  text-align: center;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .cta-block .c-section__cta-image {
    width: 100%;
  }
}
.patient-resources .c-section--four .cta-block .c-section__cta-image img {
  width: 70%;
}
.patient-resources .c-section--four .cta-block .c-section__cta-content {
  width: 60%;
  text-align: left;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .cta-block .c-section__cta-content {
    width: 100%;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--four .c-cta {
    margin-bottom: 0;
  }
}
.patient-resources .c-section--five {
  background-color: #f1f4f6;
  padding-top: 0;
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--five {
    padding-bottom: 0;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--five .c-section__content {
    order: 2;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--five .c-section__profile {
    order: 1;
    margin-bottom: 0;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--five .c-section__content {
    margin-top: 0;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--five .c-section__profile {
    margin-bottom: 0;
  }
}
@media (max-width: 54.9375em) {
  .patient-resources .c-section--five .c-section__title {
    margin-top: 2.5rem;
    margin-bottom: 0;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--five .c-section__title {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
}
.patient-resources .c-section--five .c-profile-block {
  width: 100%;
  margin-bottom: 2.5rem;
  padding-left: 8.3125rem;
}
@media (min-width: 35em) and (max-width: 54.9375em) {
  .patient-resources .c-section--five .c-profile-block {
    padding-left: 0;
    width: auto;
  }
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--five .c-profile-block {
    padding-left: 0;
    width: auto;
    display: flex;
  }
}
.patient-resources .c-section--five .c-profile-block .c-profile-block__name {
  font-size: 1.4375rem;
}
.patient-resources .c-section--five .c-profile-block .c-profile-block__title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #0f4471;
}
.patient-resources .c-section--five .c-profile-block .c-profile-block__content {
  padding-left: 2.0625rem;
}
.patient-resources .c-section--five .c-profile-block img {
  border-radius: 0.5rem;
}
@media (max-width: 34.9375em) {
  .patient-resources .c-section--five .c-profile-block img {
    margin-bottom: 0;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon/icomoon.eot?2b0k8b");
  src: url("../assets/fonts/icomoon/icomoon.eot?2b0k8b#iefix") format("embedded-opentype"), url("../assets/fonts/icomoon/icomoon.woff2?2b0k8b") format("woff2"), url("../assets/fonts/icomoon/icomoon.ttf?2b0k8b") format("truetype"), url("../assets/fonts/icomoon/icomoon.woff?2b0k8b") format("woff"), url("../assets/fonts/icomoon/icomoon.svg?2b0k8b#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.o-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.o-icon--question-circle:after {
  content: "\e91a";
}

.o-icon--checkmark-circle:after {
  content: "\e917";
}

.o-icon--file-circle:after {
  content: "\e918";
}

.o-icon--write-circle:after {
  content: "\e919";
}

.o-icon--checkmark:after {
  content: "\e916";
}

.o-icon--chat:after {
  content: "\e915";
}

.o-icon--file:after {
  content: "\e911";
}

.o-icon--files:after {
  content: "\e912";
}

.o-icon--top:after {
  content: "\e913";
}

.o-icon--quote-right:after {
  content: "\e90e";
}

.o-icon--quote-left:after {
  content: "\e90f";
}

.o-icon--play:after {
  content: "\e910";
}

.o-icon--radio_button_checked:after {
  content: "\e904";
}

.o-icon--radio_button_unchecked:after {
  content: "\e905";
}

.o-icon--home:after {
  content: "\e900";
}

.o-icon--chevron-right:after {
  content: "\e906";
}

.o-icon--chevron-left:after {
  content: "\e907";
}

.o-icon--chevron-down:after {
  content: "\e908";
}

.o-icon--chevron-up:after {
  content: "\e909";
}

.o-icon--close:after {
  content: "\e901";
}

.o-icon--calendar:after {
  content: "\e914";
}

.o-icon--arrow-right:after {
  content: "\e90a";
}

.o-icon--arrow-left:after {
  content: "\e90b";
}

.o-icon--arrow-down:after {
  content: "\e90c";
}

.o-icon--arrow-up:after {
  content: "\e90d";
}

.o-icon--search:after {
  content: "\e903";
}

.o-icon--menu:after {
  content: "\e902";
}

.o-icon--plus:after {
  content: "\f067";
}

.o-icon--minus:after {
  content: "\f068";
}

.o-icon--check:after {
  content: "\e916";
}

.o-icon--x:after {
  content: "\f00d";
}

.o-icon--lock:after {
  content: "\f023";
}

.o-icon--location:after {
  content: "\f041";
}

.o-icon--check-square-o:after {
  content: "\f046";
}

.o-icon--pause:after {
  content: "\f04c";
}

.o-icon--info:after {
  content: "\f05a";
}

.o-icon--warning:after {
  content: "\f071";
}

.o-icon--external:after {
  content: "\f08e";
}

.o-icon--phone:after {
  content: "\f095";
}

.o-icon--square-o:after {
  content: "\f096";
}

.o-icon--twitter:after {
  content: "\f099";
}

.o-icon--facebook:after {
  content: "\f09a";
}

.o-icon--expand:after {
  content: "\f0b2";
}

.o-icon--chain:after {
  content: "\f0c1";
}

.o-icon--link:after {
  content: "\f0c1";
}

.o-icon--caret-down:after {
  content: "\f0d7";
}

.o-icon--caret-up:after {
  content: "\f0d8";
}

.o-icon--envelope:after {
  content: "\f0e0";
}

.o-icon--youtube:after {
  content: "\f16a";
}

.o-icon--question:after {
  content: "\f29c";
}

.o-icon--quora:after {
  content: "\f2c4";
}

img {
  height: auto;
}

.u-clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.u-cover-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@media (max-width: 54.9375em) {
  body.no-scroll {
    overflow: hidden;
  }
}

.u-sr-only {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  color: #000000;
  background-color: #ffffff;
}

.u-sr-only-focusable:active, .u-sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
  max-width: 60rem;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
#c-livechat {
  position: fixed;
  bottom: 1.5rem;
  right: 2rem;
  cursor: pointer;
  overflow: hidden;
  visibility: visible;
  z-index: 9999999;
  border: 0;
  padding: 0;
  transition: transform 0.2s ease-in-out;
  transform: translateY(0%);
}
#c-livechat:focus, #c-livechat:active {
  outline: none;
  border: none;
}
@media (max-width: 54.9375em) {
  #c-livechat {
    display: none;
  }
}

#c-livechat__loading {
  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;
  display: none;
}
#c-livechat__loading.u-show {
  display: block;
}

#livechat-compact-container {
  display: none;
}