$home-hero-bg-color: #f7f5f8;

.home {
    .c-site-header {
        @include media-breakpoint-up(s) {
            border-bottom: 0;
            box-shadow: none;
        }
    }
}

.c-hero--home {
    .c-hero__title {
        color: $dark-text;
        font-weight: normal;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            height: rem(2);
            width: rem(64);
            background-color: $accent;
            top: rem(-24);
            left: 0;
        }
    }

    @include media-breakpoint-up(l) {

        background-size: cover;

    }
    @include media-breakpoint-down(s) {
        background-color: $primary;
        background-image: url('#{$img-path}cta-pattern.png');
        background-repeat: no-repeat;
        background-position: top right;
        padding-top: $spacer--2xl;
        padding-bottom: $spacer--2xl;
        text-align: center;
        height: auto;

        .c-hero__title {
            color: $white;
        }

        .c-hero__button {
            @include make-button-variant($white, $white, $primary, $white, $white, color-step($primary, 800));
        }

        .c-hero__main {
            top: 0;
        }
    }
    @include media-breakpoint-up(s) {
        background-image: url('/wp-content/uploads/header-bg.jpg');
        background-color: $home-hero-bg-color;
        padding-top: $spacer--2xl;
        padding-bottom: 10rem;
        background-position: right bottom;
        background-repeat: no-repeat;
        background-position: cover;

        .c-hero__title {
            margin-bottom: $spacer--2xl;
        }

        .c-hero__main {
            max-width: 30rem;
        }
    }
    @include media-breakpoint-only(m) {
        background-size: 100%;
        background-position: right bottom;
        padding-bottom: $spacer--2xl;

        .c-hero__main {
            max-width: 23rem;
        }
    }
    @include media-breakpoint-up(m) {
        min-height: 35rem;
        position: relative;

        .c-hero__main {
            position: relative;
            top: 8rem;
        }
    }
    @include media-breakpoint-down(m) {
        min-height: 5rem;

        .c-hero__main {
            top: 0;
        }

        .c-hero__title {
            font-size: rem(28);

            &:before {
                display: none;
            }
        }
    }
}

.c-home {
    .c-section__title {
        margin: 0 auto;
        @include media-breakpoint-up(m) {
            padding: 0 15rem;
        }
        @include media-breakpoint-only(m) {
            padding: 0 10rem;
            text-align: center;
        }
        @include media-breakpoint-up(s) {
            margin-bottom: 0;
        }
    }

    .c-section__header {
        padding: 0 $spacer;
    }

    .c-action-list--col-3 {
        .c-action-list__item {
            @include media-breakpoint-down(m) {
                width: percentage(1);
            }
        }
    }

    .c-section__footer {
        width: 100%;
        background-color: $gray-alt;
        border-radius: rem(16);
        margin-left: $spacer;
        margin-right: $spacer;
        margin-top: $spacer;
        @include media-breakpoint-down(m) {
            margin: 0;
        }
    }

    .c-action-list--gray-bg {
        .c-action-block__image {
            img {
                @include media-breakpoint-down(m) {
                    width: rem(64);
                }
            }
        }
    }

    .c-cta__description {
        text-align: left;
    }

    .c-cta {
        margin-top: 0;
    }

    .c-section--call-out {

        .c-home__subsection-content {

            @include media-breakpoint-down(m) {

                order: 2;
            }
        }

        .c-home__subsection-image {

            @include media-breakpoint-down(m) {

                order: 1;
            }
        }
    }
}

.c-home__subsection {
    @include make-col-ready();
    flex: 1 1 30rem;
    max-width: 40rem;
    margin: $spacer--xl auto;

    > * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        margin-bottom: 0;
    }

    .c-section--call-out & {
        color: $white;

        h2 {
            color: $white;
        }


    }

    .c-section--text-center & {
        text-align: center;
    }
}

.c-section--call-out {
    .c-home__subsection {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.c-section--cited {
    padding-bottom: 0;
}

.c-section--cited-items {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.c-home__subsection-content {
    p {
        &:first-of-type {
            font-size: rem(21);
        }
    }
    @include media-breakpoint-up(m) {
        padding-left: $spacer--4xl;
        padding-top: $spacer--l;
    }
}

.c-home__subsection-image {
    img {
        width: 100%;
        border-radius: rem(16);
    }
}
