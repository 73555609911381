.c-page--lander,
.c-section--center {

    h2,
    .h2 {

        margin-top: 0;


        @include media-breakpoint-up(l) {
            &:after {
                margin: auto;
                right: 0;
            }
        }
    }

    .c-dl {
        max-width: 48rem;
        margin-left: auto;
        margin-right: auto;
    }

    // brings the content above the white background
    // styles for heros
    .c-section__content {
        position: relative;
    }
}
