@mixin make-link(
    $color: $link-color,
    $decoration: $link-decoration,
    $hover-color: $link-hover-color,
    $hover-decoration: $link-hover-decoration
) {
    color: $color;
    text-decoration: $decoration;

    &:hover {
        color: $hover-color;
        text-decoration: $hover-decoration;
    }
}
