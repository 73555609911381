.c-banner {
    position: relative;
    display: flex;
    align-items: center;
    background-color: theme-color('light');
    padding: 0.25rem;
}

.c-banner__content {
    @include type-scale(s);
    padding: 0.25rem 0.75rem;
    flex-grow: 1;
}

.c-banner__close {
    display: flex;
    align-items: center;
    justify-content: center;
}

.c-banner--dark {
    background-color: theme-color('dark');
    color: $white;
}

.c-banner--fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    transform: translateY(-100%);
    transition: 0.2s transform ease-in-out;

    &.is-active {
        transform: translateY(0%);
    }
}
