.c-action-list {}

.c-action-list__items {
    margin-right: -($action-list-gutter / 2);
    margin-left: -($action-list-gutter / 2);

    @include media-breakpoint-up(l) {
        display: flex;
        justify-content: center;
    }
}

.c-action-list__item {
    padding-right: ($action-list-gutter / 2);
    padding-left: ($action-list-gutter / 2);
}

.c-action-list__button {}

/* Modifiers */

.c-action-list--wrap {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        flex : 1 0 auto;
    }

}

.c-action-list--2-col {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 50%;
        }
    }

    img {
        max-width: 100%;
    }
}

.c-action-list--3-col {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 33%;
        }
    }

    img {
        max-width: 100%;
    }
}

.c-action-list--4-col {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 25%;
        }
    }

    img {
        max-width: 100%;
    }
}

.c-action-list--5-col {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 20%;
        }
    }

    img {
        max-width: 100%;
    }
}

.c-action-list--6-col {

    .c-action-list__items {
        flex-wrap : wrap;
    }

    .c-action-list__item {
        @include media-breakpoint-up(l) {
            flex: 0 1 16.66%;
        }
    }

    img {
        max-width: 100%;
    }
}
