.c-factbox {
    @include type-scale(xl);
    border-radius: rem(4);
    border-left: rem(3) solid $accent;
    padding-left: $spacer--l;
    padding-right: $spacer;
    padding-top: $spacer;
    padding-bottom: $spacer;

    .c-factbox__title {
        display: block;
        font-weight: bold;

        & + .c-factbox__copy {
            display: inline;
        }
    }

    & + .c-list {
        margin-top: $spacer--l;
    }
}
