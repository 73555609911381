.c-page--one-column {

    // /cancer/understanding-cancer/
    &.c-page--backpage {

        .c-page__main {
            @include make-col-ready();
            max-width: 50rem;
            margin-left: auto;
            margin-right: auto;   
            margin-top: $spacer--4xl; 
        }   
    }

    .c-wysiwyg {
        margin-left: auto;
        margin-right: auto;
    }

    .c-page__footer {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
    }
    
    .c-figure {
        width: 100%;
    }

    .c-figure__image {
        width: 100%;
        height: auto;
    }
}
