.c-search__subtitle {
    margin-bottom: $spacer--2xl;
    margin-top: 0;
}

.c-search__form {
    margin-bottom: $spacer--xl;
}

.c-search__count {
    margin-top: $spacer--xl;
    margin-bottom: $spacer--xl;
}

.c-search__results {
}

.c-search__result {
    padding-bottom: $spacer--3xl;
}

.c-search__result-title {
    @include type-scale(l, 1.2);
    font-weight: $font-weight--bold;
    margin-top: 0;
    margin-bottom: $spacer--xs;

    a {
        text-decoration: none;
    }
}

.c-search__result-url {
    @include type-scale(base, 1);
    margin-bottom: $spacer;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    a {
        color: color-step($accent, 700);
        text-decoration: none;
        width: 100%;
    }
}

.c-search__result-snippet {
    @include type-scale(m, 1.667);
    color: $dark;
}

.c-search__pagination {
    margin-top: $spacer;
    margin-bottom: $spacer--5xl;
}