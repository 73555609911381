

.c-hero__aside {

    // when this gets adapted for all templates we can remove c-hero__aside wrapper
    .c-menu {

        @include media-breakpoint-up(m) {
            padding-left: $spacer--2xl;
            padding-right: $spacer--2xl;
        }
    }
}

.c-menu__item {
    margin-top: $spacer;
    margin-bottom: $spacer;
}

.c-menu__item--current > .c-menu__link {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: rem(2);
        height: 100%;
        position: absolute;
        left: -$spacer--xs;
        background: $accent;

        .c-menu--footer & {
            display: none;
        }
    }
}

.c-menu__link {
    color: $body-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
} 

.c-menu__list--d1 {
    @include type-scale(base, 1.2);


}

.c-menu__item--d1 {}

.c-menu__link--d1 {
    font-weight: $font-weight--bold;
}

.c-menu__list--d2 {
    padding-left: $spacer--s;
}

.c-menu__item--d2 {}

.c-menu__link--d2 {}

.c-menu__list--d3 {
    padding-left: $grid-gutter-width;
}

.c-menu__item--d3 {}

.c-menu__link--d3 {
    color: $gray-700;
}

.c-menu--simple {
    .c-menu__link--d1 {
        font-weight: $font-weight--normal;
    }
}
