.c-action-block__title {

    @include headings-after;
    margin-top: 0;
    margin-bottom: $spacer--s;
    padding-bottom: $spacer--xs;
    display: block;

    .c-action-block__media + .c-action-block__content & {

        margin-bottom: $spacer--3xs;

        &:after {
            display: none;
        }

    }

}

h2.c-action-block__title {
    padding-bottom: 0;

    &:after {
        display: none;
    }
}

.c-action-block__content,
.c-action-block__title,
.c-action-block__copy {
    max-width: 100%;
}

.c-action-block__button--chat {
    @include media-breakpoint-only(s) {
        display: none;
    }
}
.c-action-block__button--phone {
    @include media-breakpoint-up(m) {
        display: none;
    }
}
