.c-attribution--header {
    @include type-scale(s);
    margin-top: $spacer--l;
    margin-bottom: $spacer--4xl;

    .c-modified {
        display: inline;
    }
    
    // if we make the /cancer/understanding-cancer/ page summary consistent with
    // the new backpage stuff we can get rid of this block and the 
    // reviewed-checked-updated file 
    .c-page--one-column & {
        margin-top: 0;

        .c-attribution__modal-toggle {
            text-decoration: underline;
        }
    }
}

$type-body-size: rem(16);

.c-hero__attribution {
    font-size: $type-body-size;
    background: $primary-light;
    margin-top: $spacer--2xl;
    color: $body-color;
    padding-top: $spacer--s;
    padding-bottom: $spacer--s;

    // @include media-breakpoint-up(l) {

    //     // to handle min-height scenario
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;    
    // }
    


    .c-hero__attribution-wrapper {
        

        @include media-breakpoint-up(l) {
            padding-right: $spacer--l;
            padding-right: 22rem;
            
        }


        @include media-breakpoint-up(m) {
            display: flex;
        }



        @include media-breakpoint-down(m) {
            max-width: 42rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .c-profile-block {
        @include media-breakpoint-down(m) {
            display: flex;

        }
    }

    .c-attribution__author {
        font-size: $type-body-size;
        margin-right: $spacer--l;
        display: flex;
        line-height: initial;

        @include media-breakpoint-down(m) {
            margin-bottom: $spacer--xs;
        }
    }

    .c-profile-block__before {
        font-weight: bold;
        font-size: $type-body-size;

        a {
            font-weight: bold;
            font-size: $type-body-size;
        }
    }

    .c-profile-block__content {
        display: block;
    }

    .c-profile-block__name {
        font-size: $type-body-size;
        font-weight: normal;
        color: $body-color;
        display: inline-block;
    }
    a.c-profile-block__name {
        text-decoration: underline;
    }

    .o-icon {
        font-size: rem(24);
        color: $accent;
        display: inline-block;
        margin-right: $spacer--xs;
        line-height: normal;
    }

    .c-attribution__date {
        font-style: italic;
        line-height: initial;
        // display: flex;
    }

    .c-profile-block__content {
        line-height: rem(25);
    }
}

.c-attribution--footer {
    margin-top: $spacer--3xl;
    padding-top: $spacer--3xl;
    border-top: rem(1) solid $gray-100;

    .c-modified {
        display: inline;

        @include media-breakpoint-up(m) {
            padding-left: 7rem;
        }
    }
}

.c-attribution__modal-toggle {
    cursor: pointer;
    margin-bottom: rem(20);
}

.c-attribution__primary {
    .c-profile-block {
        margin-bottom: $spacer--l;
    }
    .c-profile-block__before {
        @include make-h6();
        text-transform: uppercase;
    }
    .c-profile-block__name {
        @include make-h3();
        margin-top: $spacer--xs;
        margin-bottom: $spacer;
    }
    .c-profile-block__after {
        @include type-scale;
        max-width: 40rem;

        p {
            margin: 0;
        }
    }
}

.c-attribution__additional {
    .c-profile-block {
        display: block;
    }
    .c-profile-block__content {
        display: block;

        @include media-breakpoint-up(m) {
            padding-left: 7rem;
        }
    }
    .c-profile-block__before {
        @include type-scale;
        font-weight: $font-weight--bold;
        display: inline;
    }
    .c-profile-block__name {
        font-weight: $font-weight--normal;
        display: inline;
    }
}
