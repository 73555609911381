.c-jump-to__list {
    @include type-scale(base, 1.2);
}

.c-jump-to__item {
    margin-top: $spacer;
    margin-bottom: $spacer;
}

.c-jump-to__link {
    color: $body-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.c-hero__aside {
    .c-jump-to__item {

        border-top: rem(1) solid $gray-100;
        padding-left: $spacer--xl;
        margin-top: 0;
        margin-bottom: 0;
    }

    .c-jump-to__link {
        padding: $spacer $spacer--xl;
        color: $body-color;
        text-decoration: none;
        position: relative;
        display: block;
        font-weight: bold;
        font-family: $font-family--secondary;

        &:before {
            @include make-icon;
            content: icon('arrow-right');
            color: $primary;
            position: absolute;
            left: 0;
            line-height: rem(22);
            font-weight: bold;
        }

        &:hover {
            text-decoration: underline;
            color: $primary;
        }

        .no-flexboxlegacy & {
            &:before {
                padding-top: 10px;
            }

        }
    }

}

.c-page--one-column {
    .c-attribution--header {
        margin-top: $spacer--xl;
        margin-bottom: $spacer--2xl;
    }

}
