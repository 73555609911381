.c-page-summary {
    padding-bottom: $spacer--xl;
    border-bottom: rem(1) solid $gray;
    font-family: $font-family--secondary;
    margin-bottom: $spacer--4xl;

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    // if we make the /cancer/understanding-cancer/ page summary consistent with
    // the new backpage stuff we can get rid of this block and the 
    // reviewed-checked-updated file 
    .c-page--one-column & {
        margin-bottom: $spacer--l;
    }
}

.c-page-summary__hummingbird {

    .c-page--one-column & {
        margin-top: 0;
    }

    &:before {
        @include type-scale(base, 1);
        content: 'Summary';
        text-transform: uppercase;
        display: block;
        margin-bottom: $spacer--xs;
    }
}

.c-page-summary__button {
    margin-top: $spacer--l;
}
