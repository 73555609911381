.c-hero--404 {
    .c-hero__content {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .c-hero__main {
        max-width: 40rem;
        margin: 0 auto;
        text-align: center;
    }
}
