.c-faq {


}

.c-faq__title {
    
    @include make-h3();
    margin-bottom: $spacer--xs;
}
