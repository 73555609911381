.c-simple {
    width: rem(215);
    background: #413c6a;
    color: $white;
    text-align: center;
    position: relative;
    margin: 0;
    box-shadow: $box-shadow--l;
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translate(-120%);
    transition: transform 0.2s ease-in-out;
    z-index: 100;
    border-radius: rem(4) rem(4) 0 0;
    background-color: $primary;
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;
    background-image: url('#{$img-path}cta-pattern.png');
    background-repeat: no-repeat;
    background-position: top right;

    &.is-active {
        transform: translate(0);
    }

    @include media-breakpoint-only(s) {
        display: none;
    }
}

.c-simple__content {
    display: block;
}

.c-simple__icon {
}

.c-simple__icon,
.c-simple__image {
    display: block;
    max-width: 100%;
    margin: auto;
}
.c-simple__image {
}

.c-simple__title {
    @include type-scale(m, 1.25);
    font-weight: normal;
    display: block;
    padding: $spacer--2xs $spacer--xs $spacer;
    font-weight: bold;
}

.c-simple__button {
    margin: 0;
    margin-top: $spacer;
}

.c-simple__expand {
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        z-index: 0;
    }
}

.c-simple__close {
    position: absolute;
    top: $spacer--xs;
    right: $spacer--xs;
    border-radius: rem(2);
    background-color: rgba($accent, 0.5);
    height: auto;
    color: $white;
    line-height: 2rem;

    &:hover {
        background-color: $accent;
        color: $white;
    }
}

.c-simple--minimal {

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .c-button {
        border-radius: rem(4);
        padding: 0.8rem 0.8rem;
    }
}


.no-flexbox,
.no-flexboxlegacy {
    // display: none;
    // 
    
    .c-simple--full {
        display: none;
    }
}
