.c-profile-block {
    position: relative;
    display: inline-flex;

    a {
        position: relative;
        z-index: 1;
    }
}

.c-profile-block__image-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
}

.c-profile-block__image {
    border-radius: 50%;
    margin-right: 1rem;
}

.c-profile-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-profile-block__before {
    @include type-scale(s, 1);
}

.c-profile-block__name {
    display: block;
    font-weight: bold;

    @supports (text-decoration-color: transparent) {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all 0.2s ease-in-out;
    }
}
a.c-profile-block__name {
    position: inherit;
    @include cover-parent();

    &:hover {
        color: theme-color('primary');
        text-decoration: underline;
        text-decoration-color: theme-color('primary');
    }
}

.c-profile-block__title {
    @include type-scale(s, 1.25);
}

.c-profile-block__after {
    display: block;
    @include type-scale(xs);
}

.c-profile-block__button {
    margin-top: 0.5rem;
}

.c-profile-block--stacked {
    flex-direction: column;
    text-align: center;

    .c-profile-block__image {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}
