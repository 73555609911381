.c-about {
    .c-cta__button {
        a {
            @include media-breakpoint-only(s) {
                width: 100%;
            }
        }
    }

    .c-breadcrumbs {
        @include media-breakpoint-down(m) {
            &:after {
                background: linear-gradient(to right, rgba($gray-alt, 0) 0%, rgba($gray-alt, 0) 100%);
            }
        }
    }

    .c-hero {
        margin-bottom: 0;
        min-height: rem(410);
        background-color: $gray-alt;
        background-image: url('/wp-content/uploads/hero-bg.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-only(s) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .c-hero__title {
            color: $dark-text;
            font-weight: normal;
            position: relative;
            margin-top: rem(100);
            @include media-breakpoint-only(s) {
                margin-top: rem(50);
            }

            &:before {
                position: absolute;
                content: '';
                height: rem(2);
                width: rem(64);
                background-color: $accent;
                top: rem(-24);
                left: 0;
            }
        }

        .c-hero__description {
            color: $dark-text;
            font-size: rem(21);
            @include media-breakpoint-only(s) {
                font-size: rem(18);
            }
        }

        .c-breadcrumbs,
        .c-breadcrumbs a,
        .c-breadcrumbs span {
            color: $dark-text;
        }
    }

    .c-profile-block {
        margin-bottom: $spacer--2xl;
    }

    .c-profile-block__image {
        border-radius: 100%;
    }

    .c-profile-block__after {
        @include type-scale(base, 1.5);

        p {
            margin: 0;
        }
    }

    .c-section--cited-items {
        .c-action-list__item {
            .c-action-block {
                margin-top: rem(24);
            }
        }

        .c-section__header {
            margin-bottom: 0;

            .c-section__title {
                margin-top: 0;
            }
        }
    }

    .c-section__header {
        padding: 0 15rem;
        margin-bottom: 0;

        .c-section__title {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        @include media-breakpoint-only(l) {
            padding: 0 8rem;
        }
        @include media-breakpoint-down(m) {
            padding: 0 5rem;
        }
        @include media-breakpoint-only(s) {
            padding: 0 1rem;
            margin-bottom: 0;
        }
    }

    .c-section__footer {
        width: 100%;
        background-color: $gray-alt;
        border-radius: rem(16);
        margin-left: $spacer;
        margin-right: $spacer;
        margin-top: $spacer;
        @include media-breakpoint-down(m) {
            margin: 0;
        }
    }

    .c-section--action-list {
        margin-top: rem(80);
        background-color: $gray-alt;
        background-image: url('/wp-content/uploads/content-bg-1.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .c-action-list--col-4 {
        & .c-action-block {
            flex-direction: row;
            align-items: center;
            @include media-breakpoint-only(s) {
                padding: 1rem;
            }

            .c-action-block__content {
                padding-left: rem(24);
                @include media-breakpoint-only(s) {
                    padding-left: 0;
                }
            }

            .c-action-block__image {
                margin-bottom: 0;
            }
        }
    }

    .c-section--profiles {
        padding-top: 0;
        padding-bottom: 0;

        h2 {
            text-align: center;
            @include media-breakpoint-only(s) {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
        }

        h3 {
            margin-bottom: 0;

            &:first-child {
                margin-top: 0;
            }
        }

        .l-container {
            @include media-breakpoint-only(l) {
                padding-left: $spacer--xl;
                padding-right: $spacer--xl;
            }
            @include media-breakpoint-up(m) {
                padding-left: $spacer--5xl;
                padding-right: $spacer--5xl;
            }
            @include media-breakpoint-down(m) {
                padding-left: $spacer--xl;
                padding-right: $spacer--xl;
            }
            @include media-breakpoint-only(s) {
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }

        .c-section--profiles-content {
            padding-left: $spacer--5xl;
            padding-right: $spacer--5xl;
            @include media-breakpoint-down(m) {
                padding-left: $spacer;
                padding-right: $spacer;
            }
            @include media-breakpoint-only(s) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .c-profile-block {
            background-color: $gray-alt;
            padding: $spacer--3xl;
            margin-bottom: $spacer--4xl;
            border-radius: rem(8);
            @include media-breakpoint-down(m) {
                flex-direction: column;
            }
            @include media-breakpoint-only(s) {
                padding: rem(24);
                margin-bottom: $spacer--xl;
            }

            .c-profile-block__image {
                border-radius: rem(7);
                margin-right: rem(32);
                @include media-breakpoint-down(m) {
                    width: 40%;
                }
            }

            .c-profile-block__name {
                font-family: $font-family--secondary;
                font-size: rem(23);
                @include media-breakpoint-only(s) {
                    font-size: rem(18);
                }
            }

            .c-profile-block__title {
                font-family: $font-family--secondary;
                font-size: rem(18);
                color: $primary;
                margin-bottom: rem(24);
                @include media-breakpoint-only(s) {
                    font-size: rem(16);
                }
            }
        }
    }

    .c-section--gray-bg {
        background-color: $gray-alt;
    }

    .c-action-list--col-3 {
        .c-action-block__content {
            @include media-breakpoint-only(s) {
                word-break: break-all;
            }
        }

        .c-social-links__item {
            position: relative;
            display: block;
            margin: rem(20) 0 0;
            @include media-breakpoint-only(s) {
                display: inline;
            }

            span {
                font-size: rem(14);
                font-weight: normal;
                text-decoration: underline;
                position: absolute;
                text-align: left;
                left: rem(50);
                width: rem(260);
                @include media-breakpoint-up(l) {
                    left: rem(60);
                }
                @include media-breakpoint-only(s) {
                    display: none;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .c-action-list__items {
            @include media-breakpoint-down(m) {
                flex-direction: column;
            }

            .c-action-list__item {
                .c-action-block {
                    @include media-breakpoint-only(m) {
                        min-width: 40rem;
                    }
                }

                &:first-of-type {
                    .c-action-block {
                        width: 100%;
                    }
                }

                &:nth-of-type(2) {
                    .c-action-block {
                        margin: 2rem auto 0;
                        @include media-breakpoint-up(s) {
                            width: 100%;
                        }
                    }
                }
                @include media-breakpoint-down(m) {
                    width: 100%;
                }
            }
        }
    }

    .sponsor__media {
        max-width: 100%;
        border-radius: rem(7);
    }


    .c-blockquote--secondary {
        .c-blockquote__footer {
            max-width: 50rem;
            margin: 0 auto;
        }
    }
}

.c-about__subsection {
    @include make-col-ready();
    flex: 1 1 30rem;
    max-width: 40rem;
    margin: $spacer--xl auto;

    > * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up(m) {
        width: 50%;

        img {
            width: 100%;
        }
    }
    @include media-breakpoint-down(m) {
        img {
            display: none;
        }
    }
}

.c-about__subsection-image {
    img {
        border-radius: rem(10);
    }
}

.c-about__subsection-content {
    h3 {
        margin-bottom: 0;
    }
}
