.c-wysiwyg {
    @include type-scale(m);
    max-width: 50rem;
}


.c-wysiwyg {
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
}