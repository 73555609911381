.c-hero--profile {
    .c-hero__before img {
        margin-left: auto;
        margin-right: auto;
        border-radius: 100%;
        margin-bottom: 1rem;
    }

    .c-hero__title {
    }

    .c-hero__subtitle {
        @include make-h4;
        color: gray('700');
    }

    .c-profile-contact,
    .c-profile-social {
        margin-top: 1rem;

        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;
            }
        }
    }
}
