.c-site-footer {
    color: $footer-color;
    background-color: $footer-background-color;
    padding-top: $footer-padding-top;
    padding-bottom: $footer-padding-bottom;
    margin-top: auto;
    text-align: center;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.c-site-footer__logo {
    margin: 0 auto 2rem;
    max-width: 20rem;

    .c-logo {
        background-image: url('#{$footer-logo-path}');
    }
}

.c-site-footer__menu {
    @include type-scale(s, 1.25);
    margin-bottom: 3rem;

    .c-menu__list {
        @include media-breakpoint-up(l) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .c-menu__item {
        letter-spacing: rem(1);

        @include media-breakpoint-down(m) {
            margin-bottom: 1rem;
        }

        @include media-breakpoint-up(l) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.c-site-footer__disclaimer {
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    color: $footer-disclaimer-color;
}
