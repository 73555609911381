.c-list__title {    
    font-weight: bold;
    font-family: $font-family--sans-serif;
    margin-bottom: $spacer;
    display: block;
    margin-top: 0;
}

h2.c-list__title {
    padding-bottom: 0;

    &:after {
        display: none;
    }
}

.c-list {
    @include type-scale(m);

    & + .c-list {
        margin-top: 1.75rem;
    }
}




.c-list--doctors {
    margin-top: $spacer--3xl;
    margin-bottom: $spacer--3xl;
}
