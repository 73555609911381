.c-aside__header {  
    @include media-breakpoint-down(m) {
        background: $primary;
        text-align: center;
        margin-bottom: $spacer--xl;
    }
    @include media-breakpoint-up(l) {
        display: none;
    }
}

.c-aside__content {

    @include media-breakpoint-down(m) {

        @include make-col-ready();
        padding-bottom: $spacer--3xl;

        &:before {
            content: '';
            display: block;
            width: 90%;
            height: 3rem;
            background-image: url('#{$img-path}/logos/logo-dark.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin: $spacer--l auto;
        }
    }

    border-radius: 0 0 rem(4) rem(4);
}


.c-aside__section {
    @include media-breakpoint-down(m) {
        display: none;
    }

    @include media-breakpoint-up(l) {
        margin-bottom: $spacer--4xl;

        &:first-child {
            margin-bottom: $spacer--2xl;
        }
    }
}

// should this be template based? 
.c-hero__aside {


    .c-aside__section {

        .c-button--menu {

            position: relative;  

            @include media-breakpoint-up(l) {

                &:after {
                    @include make-icon();
                    font-size: rem(17);
                    position: absolute;
                    color: $primary;
                    content: icon('plus');
                    top: 50%;
                    right: $spacer--l;
                    transform: translateY(-50%);
                }

                &:hover {
                    &:after {
                        color: $accent;
                    }
                }
            }

            
        }

        &.is-active {

            .c-aside__toggle-wrapper {
                
                @include media-breakpoint-down(m) {
                    display: block !important;    
                    
                    
                }
                
            }

            .c-button--menu {

                @include media-breakpoint-up(l) {

                    &:after {
                        content: icon('minus');
                    }    
                }

            }

        }


        @include media-breakpoint-up(l) {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(m) {

            .c-button--menu {
                display: none;
            }
        }
    }
}

// for the mobile pop up so that people cant 
// use the button that slides the menus 
// this was a "quick" solution

.c-aside__mobileHeader {

    @include media-breakpoint-up(l) {
        display: none;
    }

    @include media-breakpoint-down(m) {
        
        @include type-scale(l); 
        text-transform: uppercase;
        background-color: $white;
        text-align: left;
        color: $primary;
        font-weight: bold;
        padding-left: $spacer--xl;
        padding-right: $spacer--xl;
        padding-bottom: $spacer;
        padding-top: $spacer;
        border-top: rem(1) solid $gray-200;
    }
}


// yikes

.c-aside__related {


    
    @include media-breakpoint-down(m) {

        .c-aside__toggle-wrapper {
            padding-left: $spacer--xl; 
        }    
    }
}



.single {

    .c-aside {

        @include media-breakpoint-down(m) {
            // @todo i would make this a mixin if we werent deleting it 
            // in like two weeks 
            // see also _hero.scss c-hero__aside
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            position: fixed;
            top: 100%;
            left: 0;
            z-index: 1050;
            background-color: $white;
            transition: top, visibility;
            transition-duration: 0.2s, 0s;
            transition-delay: 0s, 0.2s;
            visibility: hidden;

            .c-hero__image {
                display: none;
            }

            &.is-active {
                top: 0;
                visibility: visible;
                transition-delay: 0s, 0s;
            }
        }

        
    }
    
}
