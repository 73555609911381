@mixin make-button-outline() {
    @include make-button-variant($white, $dark, $dark, $dark, $dark, $white);
}

@mixin make-button--icon-only() {
    @include make-button-variant(
        transparent,
        transparent,
        $black,
        transparent,
        $accent,
        $primary
    );
}

p {
    & + .c-button {
        margin-top: rem(40);
    }

}

.c-button {
    letter-spacing: 0;
    font-family: $font-family--secondary;
}

.c-button--outline {
    @include make-button-outline();
}

.c-button--secondary-light {
    @include make-button-variant($secondary-light, $secondary-light, $dark, $accent, $accent, $white);
}

.c-button--white {
    @include make-button-variant($white, $white, $primary, $accent, $accent, $white);
}

.c-button--outline-white {
    @include make-button-variant(transparent, $primary-light, $primary-light, $gray-100, $gray-100, $primary,);
    font-size: rem(14);
    line-height: 1;
    font-weight: bold;
    padding-left: rem(30);
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    width: fit-content;

    .c-button__icon {
        font-size: rem(14);
        line-height: 1;
        border-radius: 100%;
        // color: $primary;
        color: $accent;
        // width: rem(16);
        // height: rem(16);
        // line-height: rem(16);
        // text-align: center;
        margin-right: rem(4);
        position: absolute;
        left: rem(8);
    }

    &:hover {
        .c-button__icon {
            // color: $gray-100;
        }
    }

    &.c-button--xs {
        border-radius: rem(4);
        line-height: 1;

        .c-button__icon {
            line-height: 1;
        }
    }
}

.c-button--ghost {
    box-shadow: none;

    &.c-button--white {
        color: $white;
        background: transparent;
        border-color: transparent;

        &:hover {
            color: $white;
            background: transparent;
            border-color: transparent;
        }
    }
}

.c-button--link {
    @include type-scale(m, 1);
    @include make-button-variant(transparent, transparent, $link-color, transparent, transparent, $accent,);
    height: auto;
    border: 0;
    text-decoration: none;
    text-align: left;
    position: relative;
    line-height: rem(30);
    font-family: $font-family--secondary;
    // this should be a mixin
    &:after {
        @include make-icon;
        line-height: rem(30);
        content: icon('arrow-right');
        border-radius: 100%;
        color: $accent;
        height: rem(30);
        width: rem(30);
        padding-left: rem(8);
        text-align: center;
        font-weight: bold;
        display: inline-block;
    }

    &:hover {
        text-decoration: underline;
    }
    // this is freaking stupid but don't even
    // think about removing it
    @include media-breakpoint-up(l) {
        line-height: rem(30);
    }
    // this is freaking stupid but don't even
    // think about removing it
    @include media-breakpoint-down(m) {
        line-height: rem(30);
    }

    .no-flexboxlegacy & {
        &:before {
            padding-top: rem(14);
        }
    }
}
// meh
.c-cta.c-cta--button-inverted {
    background: $primary;
    border-radius: 0 0 rem(4) rem(4);
    padding-left: $spacer--l;
    padding-right: $spacer--l;
    margin-top: 0;
    margin-bottom: 0;

    .c-cta__button {
        margin-top: 0;
    }

    .c-button {
        @include make-button-variant(transparent, transparent, $white, transparent, transparent, $white,);
        font-size: rem(16);
        height: auto;
        border: 0;
        padding-left: $spacer--2xl;
        text-decoration: none;
        text-align: left;
        position: relative;
        line-height: rem(30);
        font-family: $font-family--secondary;
        // this should be a mixin
        &:before {
            @include make-icon;
            line-height: rem(30);
            content: icon('arrow-right');
            border-radius: 100%;
            background-color: $accent;
            color: $white;
            position: absolute;
            left: 0;
            height: rem(30);
            width: rem(30);
            text-align: center;
            font-weight: bold;
        }
        // this is freaking stupid but don't even
        // think about removing it
        @include media-breakpoint-up(l) {
            line-height: rem(30);
        }
        // this is freaking stupid but don't even
        // think about removing it
        @include media-breakpoint-down(m) {
            line-height: rem(30);
        }
    }

    &:hover {
        background-color: color-step($primary, 600);
    }

    .no-flexboxlegacy & {
        .c-button {
            &:before {
                padding-top: rem(14);
            }
        }
    }
}
// aside menu buttons
.c-button--menu {
    @include make-button-variant(transparent, transparent, $primary, transparent, transparent, $accent,);
    @include type-scale(l);
    border: 0;
    border-top: rem(1) solid $gray-100;
    padding-left: $spacer--xl;
    padding-right: $spacer--xl;
    text-transform: uppercase;
    width: 100%;
    border-radius: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;

    &:hover {
        border-top: rem(1) solid $gray-100;
    }
}

.c-button--xs {
    .o-icon {
        line-height: 1;
    }
}

.c-button--rounded {
    border-radius: $spacer--2xs;
}

.c-button--icon {
    line-height: 3rem;
}

.c-button--icon[href*='facebook'] {
    color: $primary;

    &:hover {
        background-color: rgba($white, 0.66);
    }
}

.c-button--icon[href*='twitter'] {
    color: $primary;

    &:hover {
        background-color: rgba($white, 0.66);
    }
}

.c-button--l {
    letter-spacing: normal;
}

.c-button--icon-only {
    @include make-button--icon-only;
    box-shadow: none;
    border: solid rem(2) $primary;
    padding: rem(10) rem(11);
    margin-top: 0;
    border-radius: rem(50);
    color: $primary;

    .c-button__icon--after {
        margin: 0;
    }

    &:hover {
        box-shadow: none;
    }
}