.c-site-menu {
    font-family: $font-family--secondary;
}

.c-site-menu__item--d1 {
    display: flex;
    align-items: center;
}
@include media-breakpoint-down(m) {

    .c-site-menu {}

    .c-site-menu--collapsible {
        padding-left: $spacer--l;
    }

    .c-site-menu__list {}

    .c-site-menu__list--d1 {
        margin-left: -$spacer--l;
        margin-right: -$spacer--l;
        padding-left: 0;
    }
    .c-site-menu__list--d2 {
        display: none;
        width: 100%;
        background: $light;
        padding-left: $spacer--l;
        padding-right: $spacer--l;

        .c-site-menu__item--has-children.is-active & {
            display: block;
        }
    }
    .c-site-menu__item--d2,
    .c-site-menu__item--d3 {
        padding-left: $spacer--l;
    }

    .c-site-menu__item {
        @include type-scale(base, 1.75);
        flex-wrap: wrap;
    }
    .c-site-menu__item--d1 {
        &.is-active {
            .c-site-menu__list--d2 {
                display: block;
            }
            .o-icon {
                transform: rotate(180deg);
            }
        }
    }
    .c-site-menu__item--d2 {}
    .c-site-menu__item--has-children.is-active {}
    .c-site-menu__item--parent {}
    .c-site-menu__item--ancestor {}

    .c-site-menu__item-wrapper {
        display: flex;
        align-items: center;
    }
    .c-site-menu__item-wrapper--d1 {}

    .c-site-menu__link {
        @include type-scale(base, 1.5);
        padding-top: $spacer--s;
        padding-bottom: $spacer--s;
        text-decoration: none;
        flex: 1 1 auto;
        color: $dark;
        display: inline-block;
        transition: all 0.2s ease-in-out;

        &:hover {
            text-decoration: underline;
            color: $primary;
        }
        .c-site-menu__item--current > & {
            font-weight: bold;
            color: $primary;
        }
    }
    .c-site-menu__link--d1 {
        padding: $spacer 0 $spacer $spacer--l;
    }
    .c-site-menu__link--has-children {}
    .c-site-menu__link--parent {}
    .c-site-menu__link--ancestor {}

    .c-site-menu__toggle {
        @include type-scale(xl, 1);
        flex: 0 0 auto;
        padding-right: $spacer--l;
        padding-left: $spacer--l;
        width: auto;

        .o-icon {
            transform: rotate(0deg);
            transition: 0.2s ease-in-out;
        }
    }

}

@include media-breakpoint-up(l) {
    .c-site-menu__list {
        @include list-unstyled;
        display: flex;
        flex-grow: 1;

        .c-site-menu__item--has-children:hover > & {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s, 0s;
        }
    }

    .c-site-menu__list--d1 {
        position: relative;
    }

    .c-site-menu__item--d1 {
        line-height: $site-header-height--desktop;
        height: $site-header-height--desktop;
        color: $site-header-color;
        position: relative;
        background-color: transparent;
        transition: background-color 0.2s ease;
        position: initial;

        .c-button {
            line-height: inherit;
            padding: 0;
            border: 0;
            margin-left: $spacer--2xs;
            display: inline-block;
            height: $site-header-height--desktop;

            .o-icon:after {
                content: map-get($icons, 'caret-down');
                color: $primary;
            }
        }

        &:hover {
            color: $primary;
            background-color: transparent;

            .c-site-menu__link--d1 {
                text-decoration: none;

                &:before,
                &:after {
                    visibility: visible;
                    opacity: 1;
                    transition-delay: 0s, 0s;
                }
            }
        }
    }
    .c-site-menu__link {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $site-header-hover-color;
        }
    }
    .c-site-menu__link--d1 {
        display: inline-block;
        line-height: inherit;
        color: inherit;
        font-weight: bold;
        position: relative;
        display: inline-block;
        z-index: 2;

        &:hover {
            color: $primary;
        }

        .c-site-menu__item--has-children & {
            color: inherit;

            &:before,
            &:after {
                content: '';
                width: 0;
                height: 0;
                border-width: 0 $spacer--s $spacer--s $spacer--s;
                border-color: transparent;
                border-style: solid;
                border-bottom-color: $gray-100;
                position: absolute;
                top: rem(36);
                left: 50%;
                transform: translateX(-50%);
                visibility: hidden;
                opacity: 0;
                transition-property: opacity, visibility;
                transition-duration: 0.2s, 0s;
                transition-delay: 0s, 0.2s;
            }
            &:after {
                top: rem(37);
                border-bottom-color: $white;
            }
        }
    }

    .c-site-menu__list--d2 {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $site-header-hover-background-color;
        flex-direction: column;
        min-width: 15rem;
        padding: 0 1rem;
        visibility: hidden;
        opacity: 0;
        transition-property: opacity, visibility;
        transition-duration: 0.2s, 0s;
        transition-delay: 0s, 0.2s;
        width: 46rem;
        left: auto;
        right: 0;
        padding: $spacer--l $spacer--3xl $spacer--2xl;
        box-shadow: 0 0 $spacer--s 0 rgba($black, 0.1);
        border: rem(1) solid $gray-100;
        display: block;
        column-count: 2;
        column-gap: $grid-gutter-width;
    }

    .c-site-menu__item--d2 {
        @include type-scale(base, 1.5);
        width: 100%;
        left: auto;
        right: 0;
        margin: $spacer--s 0;
        color: $site-header-hover-color;
        padding-top: $spacer--xs;
        padding-bottom: $spacer--xs;
        margin-top: 0;
        margin-bottom: 0;
        break-inside: avoid;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .c-site-menu__link--d2 {
        @include type-scale(base, 1.25);
        display: block;
        color: $dark;
        font-weight: $font-weight--bold;

        &:hover {
            color: inherit;
        }

        .c-site-menu__item--has-children:hover & {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s, 0s;
        }
    }
    .c-site-menu__list--d3 {
        @include type-scale(base, 1.5);
        display: block;
        margin-top: $spacer;
        border-left: rem(2) solid $gray-100;
        padding-left: $spacer;
    } 
    .c-site-menu__item--d3 {
        display: block;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: $spacer--xs;
        padding-bottom: $spacer--xs;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
    .c-site-menu__link--d3 {
        color: color-step($dark, 300);
    }
}

@include media-breakpoint-only(l) {
    .c-site-menu__item--d1 {
        @include type-scale(s, false);
        padding: 0 $spacer--s;
    }
}

@include media-breakpoint-up(xl) {
    .c-site-menu__item--d1 {
        @include type-scale(base, false);
        padding: 0 $spacer;
    }
}


.no-flexbox,
.no-flexboxlegacy {

    .c-site-header__left {
        float: left;
    }

    .c-site-menu__list--d1 {
        width: 45rem;
        float: left;
    }

    .c-site-menu__item {
        display: inline-block;
    }
    
    // white dropdown
    .c-site-menu__list--d2 {
        left: 0;
        min-width: 13rem;
        z-index: 9;
    }

    // child links
    .c-site-menu__item--d2 {

    }

    .c-site-menu__item--d3 {
        display: block;
    }
}
