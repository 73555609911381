

.c-figure__caption {
    text-align: center;
    font-style: normal;
}

.c-figure--right {
    text-align: center;
    display: block;

    img {
        border-radius: rem(4);
        max-width: 100%;
        height: auto;
    }
    
    .c-figure__figcaption {
        display: block;
    }
    .c-figure__caption {
        font-style: normal;
        display: block;
    }
    
    @include media-breakpoint-up(m) {
        float: right;
        max-width: 16rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
        height: auto;
        width: 100%;
    }
}




.c-figure--video {
    .c-figure__caption {
        text-align: center;
    }
}