.c-share {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}

.c-share__heading {
    @include make-h6();
    text-transform: uppercase;
    margin-bottom: $spacer--xl;
}

.c-share__list {
    @include media-breakpoint-down(m) {
        flex-direction: row;
        justify-content: center;
    }
}

.c-share__link {
    width: auto;
    text-transform: uppercase;
    font-weight: normal;
    padding-left: $spacer--xl;
    padding-right: $spacer--xl;

    @include media-breakpoint-down(m) {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        padding: 0;

        .c-button__copy {
            display: none;
        }
    }
}

.c-share__copy {
    textarea {
        margin-top: $spacer--l;
        margin-bottom: $spacer--s;
        border: rem(1) solid $gray-100;
    }
}
