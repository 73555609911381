.c-page--two-column {
    .c-page__content {
        @include make-row;
        justify-content: space-around;
    }
    .c-page__main {
        @include make-col-ready;
        max-width: 42rem;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(l) {
            max-width: 60%;
            width: percentage(2/3);
            margin-left: 0;
        }


    }
    .c-page__aside {
        @include make-col-ready;
        @include media-breakpoint-up(l) {
            width: percentage(1/3);
            margin-left: auto;
        }
    }
}

.no-flexbox,
.no-flexboxlegacy {
    .c-page--two-column {
        .c-page__content {
        }
        .c-page__main {
            float: left;
        }
        .c-page__aside {
            float: left;
        }
    }
}
