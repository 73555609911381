.c-ul {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

.c-ul__item  {
    list-style: none;
    position: relative;
    padding-left: $spacer--l;
    margin-bottom: $spacer--m;

    &::before {
        content: '';
        position: absolute;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 100%;
        left: 0;
        top: 0.6rem;
        background-color: $gray;
    }
}

.c-ul--split {
    @include media-breakpoint-up(m) {
        column-count: 2;
        column-gap: $spacer--2xl;
    }
}





.c-ul--doctors {
    border: rem(1) solid $gray-200;
    background-color: $light;
    border-radius: 0.25rem;
    padding-left: $spacer--2xl;
    padding-right: $spacer--2xl;

    .c-ul__item {
        display: flex;
        flex-wrap: wrap;
        border-top: rem(1) solid $gray-200;
        padding: $spacer--l 0;
        margin: 0;


        &:before {
            display: none;
        }

        &:first-child {
            border-top: none;
        }

        strong,
        span {
            flex: 1 1 15rem;
        }

        strong {
            padding-right: $spacer--xs;
        }
    }
}