//Defualt Backpage Layout
.c-hero--backpage {
    padding-top: $hero-padding-top--backpage-1;
    padding-bottom: $hero-padding-bottom--backpage-1;
    margin-bottom: $hero-margin-bottom--backpage-1;
    background-color: $hero-background-color--backpage-1;

    .c-breadcrumbs {
        margin-bottom: 1rem;
    }

    .c-hero__title {
        color: $hero-color--backpage-1;
    }
    .c-hummingbird {
        margin-top: 1rem;
        max-width: $wysiwyg-max-width;
        color: $hero-color--backpage-1;
    }
}
