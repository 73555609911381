//alignment variants

.c-section--center {
	@include media-breakpoint-down(m) {
		.c-section__header {
			text-align: center;
		}
	}

	.c-section__header {
		width: 100%;
	}
}

.c-section--light {
	background: linear-gradient(0deg, $white, $light);

	& + .c-cta--full {
		margin-top: 0;
	}

	& + .c-blockquote--primary {
		margin-top: 0;
	}
}

.c-section--primary {
	background-color: $primary;
}
// TODO: clean up colors and make them variables
.c-section--gradient {
	background: linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
}

.c-section--bg-pattern-1 {
	background-image: url('/wp-content/uploads/bg-pattern-1.png'),
		linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
	background-position: right top;
	background-repeat: no-repeat;
	background-size: cover;
}

.c-section--bg-pattern-2 {
	background-image: url('/wp-content/uploads/bg-pattern-2.png'),
		linear-gradient(111.81deg, #0f4571 0%, #1d70c9 59.03%);
	background-position: right top;
	background-repeat: no-repeat;
	background-size: cover;
}

.c-section--mobile-only {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $spacer--2xl;
	text-align: center;
	@include media-breakpoint-up(l) {
		display: none;
	}
}

.c-section--back-to-top {
	padding-top: $spacer--2xl;
	padding-bottom: $spacer--2xl;
	text-align: center;
}

.c-section__block {
	.c-section__title {
		margin: 0 auto;
		@include media-breakpoint-up(m) {
			padding: 0 15rem;
		}
		@include media-breakpoint-only(m) {
			padding: 0 10rem;
			text-align: center;
		}
		@include media-breakpoint-up(s) {
			margin-bottom: 0;
		}
	}

	.c-section__header {
		padding: 0 $spacer;
	}

	.c-section__footer {
		width: 100%;
		background-color: $gray-alt;
		border-radius: rem(16);
		margin-left: $spacer;
		margin-right: $spacer;
		margin-top: $spacer;
		@include media-breakpoint-down(m) {
			margin: 0;
		}
	}
}

.c-section--np {
	padding-top: 0;
	padding-bottom: 0;
}

.c-section--npt {
	padding-top: 0;

	@include media-breakpoint-up(m) {
		padding-bottom: $spacer--3xl;
	}
}

.c-section--border-top {
	.c-section__title {
		border-top: rem(2) solid rgba(68, 68, 68, 0.2);
		padding-top: rem(80);
	}
}

.c-section--trust-bar {
	padding-top: 0;

	.c-action-list__items {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: 1fr;
		grid-column-gap: 0;
		grid-row-gap: 0;

		@include media-breakpoint-up(m) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.c-action-block {
		display: grid;
		grid-template-columns: rem(32) 1fr;
		grid-template-rows: 1fr;
		grid-column-gap: $spacer;
		grid-row-gap: 0;
		margin-top: 0;
		margin-bottom: rem(16);
		align-items: flex-start;
	}

	.c-action-list__item {
        @include media-breakpoint-up(m) {
            &:not(:last-of-type) {
                border-right: solid rem(1) $gray-100;
            }
        }

        @include media-breakpoint-down(s) {
            padding-top: $spacer;
			border-bottom: solid rem(1) $gray-100;
        }
    }
}

.c-section--media-lp {
	strong {
		@include make-h4;
	}

	.c-media-block--media-right {
		@include media-breakpoint-down(m) {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

.c-section--trust-reviews {
	@include media-breakpoint-up(l) {
		.c-section__content {
			display: flex;
			gap: $spacer--5xl;
		}

		.content {
			width: 60%;
		}

		.media {
			width: 40%;
			padding: $spacer--3xl $spacer--5xl;
		}
	}

	@include media-breakpoint-down(s) {
		.c-section__title {
			margin-bottom: 0;
			padding-top: $spacer--4xl;
		}
	}

	.media {
		border-radius: rem(8);
		box-shadow: $box-shadow;
		max-width: rem(380);
		padding: $spacer--2xl;
		margin-left: auto;
		margin-right: auto;

		.h4 {
			margin-top: 0;
			text-align: center;
		}
	}


	.c-action-block__button {
		margin-top: 0;
	}

	.c-action-block__image {
		margin: 0;
	}

	.c-ul__item {
		&::before {
			margin-top: rem(4);
		}

		@include media-breakpoint-up(m) {
			font-size: rem(20);
		}
	}

	.c-action-list--trust {
		.c-action-list__items {
			display: flex;
			flex-direction: column;
			gap: $spacer--xl;
			padding: 0;
		}

		.c-action-block {
			margin-bottom: 0;
			margin-top: 0;
		}

		.c-action-block__media {
			width: auto;
		}
	}
}