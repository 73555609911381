.c-blockquote__title {
	font-weight: normal;

	&:before {
		content: '\2014';
		padding-right: $spacer;
	}
}

.c-blockquote {
	.c-blockquote__quote {
		@include type-scale(l);
		font-weight: bold;
	}

	.c-blockquote__quote {
		max-width: 50rem;
		padding-left: $spacer--2xl;
		padding-right: $spacer--2xl;
		margin-top: 0;
		position: relative;

		&:before {
			@include make-icon();
			position: absolute;
			color: $primary;
			content: icon('quote-left');
			top: 0;
			left: 0;
		}

		&:after {
			@include make-icon();
			position: absolute;
			color: $primary;
			content: icon('quote-right');
			top: 0;
			right: 0;
		}
	}
}

.c-blockquote--primary {
	position: relative;
	background-color: $primary;
	background-image: url('#{$img-path}cta-pattern.png');
	background-repeat: no-repeat;
	background-position: top right;
	color: $white;
	padding-top: $spacer--5xl;
	padding-bottom: $spacer--5xl;
	font-weight: bold;
	padding-left: 1rem;
	padding-right: 1rem;
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: none;
	margin-top: 0;

	&:before {
		background-image: url('#{$img-path}cta-pattern-corner.png');
		background-repeat: no-repeat;
		background-position: bottom left;
		content: '';
		position: absolute;
		width: rem(74);
		height: rem(35);
		left: 0;
		bottom: 0;
	}

	.c-blockquote__quote {
		max-width: 50rem;
		padding-left: $spacer--2xl;
		padding-right: $spacer--2xl;
		margin-top: 0;
		position: relative;

		&:before {
			@include make-icon();
			position: absolute;
			color: $accent;
			content: icon('quote-left');
			top: 0;
			left: 0;
		}

		&:after {
			@include make-icon();
			position: absolute;
			color: $accent;
			content: icon('quote-right');
			top: 0;
			right: 0;
		}
	}
}

.c-blockquote--secondary {
	position: relative;
	background-color: #f1f4f6;
	background-image: url('/wp-content/uploads/content-bg-2.png');
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	color: $dark-text;
	padding-top: $spacer--5xl;
	padding-bottom: $spacer--5xl;
	font-weight: normal;
	padding-left: 1rem;
	padding-right: 1rem;
	width: 100vw;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: none;
	margin-top: 0;

	.c-page--lander & {
		margin-bottom: 0;
	}

	@include media-breakpoint-only(s) {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: $spacer--2xl;
		margin-bottom: 0;
	}

	.c-blockquote__footer {
		text-align: left;
		margin: 0 21.5rem;
		@include media-breakpoint-only(l) {
			margin: 0 8.5rem;
		}
		@include media-breakpoint-down(m) {
			margin: 0 2rem;
		}
		@include media-breakpoint-only(s) {
			margin: 0;
			padding: 0 $spacer;
		}
	}

	.c-blockquote__title {
		font-weight: bold;
	}

	.c-blockquote__quote {
		max-width: 50rem;
		padding-left: $spacer--2xl;
		padding-right: $spacer--2xl;
		margin-top: 0;
		position: relative;
		text-align: left;
		font-family: $font-family--sans-serif;
		font-weight: 400;
		font-style: italic;
		@include media-breakpoint-only(s) {
			padding-left: $spacer;
			padding-right: $spacer;
		}

		&:before {
			@include make-icon();
			position: absolute;
			color: $accent;
			content: icon('quote-left');
			top: 0;
			left: rem(-60);
			font-size: rem(80);
			@include media-breakpoint-only(s) {
				top: rem(-24);
				left: rem(16);
				font-size: rem(20);
			}
		}

		&:after {
			display: none;
		}
	}
}

.c-blockquote--gradient-blue {

    background: linear-gradient(to right, $primary, $link-color);

    &.c-blockquote--primary:before {
        background-image: url('/wp-content/uploads/bg-pattern-2.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}
