.c-expand-menu {

}
.c-expand-menu--collapsible {
    padding-left: $spacer--l;
}

.c-expand-menu__list {}
.c-expand-menu__list--d1 {
}
.c-expand-menu__list--d2 {
    display: none;
    margin-right: -$spacer--l;
    margin-left: -$spacer--l;
    background: $light;
    padding-left: $spacer--l * 2;
    padding-right: $spacer--l;
}
.c-expand-menu__list--d3 {
    padding-left: $spacer--l;
}

.c-expand-menu__item {}
.c-expand-menu__item--d1 {
    &.is-active {
        .c-expand-menu__list--d2 {
            display: block;
        }
        .o-icon {
            transform: rotate(180deg);
        }
    }
}
.c-expand-menu__item--d2 {}
.c-expand-menu__item--has-children.is-active {}
.c-expand-menu__item--parent {}
.c-expand-menu__item--ancestor {}

.c-expand-menu__item-wrapper {
    display: flex;
    align-items: center;
}
.c-expand-menu__item-wrapper--d1 {}

.c-expand-menu__link {
    @include type-scale(base, 1.5);
    padding-top: $spacer--s;
    padding-bottom: $spacer--s;
    text-decoration: none;
    flex: 1 1 auto;
    color: $dark;
}
.c-expand-menu__link--d1 {}
.c-expand-menu__link--d2 {}
.c-expand-menu__link--has-children {}
.c-expand-menu__link--parent {}
.c-expand-menu__link--ancestor {}

.c-expand-menu__toggle {
    @include type-scale(xl, 1);
    flex: 0 0 auto;
    padding-right: $spacer--l;
    padding-left: $spacer--l;
    width: auto;

    .o-icon {
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
    }
}
