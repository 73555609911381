/*!
 * Hamburger for ARES
*/
// Settings
// ==================================================
$hamburger-width: 2rem !default;
$hamburger-height: 1.125rem !default;
$hamburger-layer-height: 0.1875rem !default;
$hamburger-layer-spacing: 0.4375rem !default;
$hamburger-layer-color: currentColor !default;
$hamburger-transition-duration: 0.2s !default;
$htd--half: ($hamburger-transition-duration / 2);




// Base Hamburger (We need this)
// ==================================================

.c-hamburger {
    display: block;
    cursor: pointer;
    width: $hamburger-width;
    height: $hamburger-height;
    position: relative;
}

.c-hamburger {
    @include media-breakpoint-only(s) {
        width: $spacer--l;
        height: $spacer--s;
    }
}

.c-hamburger__bar {
    display: block;
    width: 100%;
    background-color: $hamburger-layer-color;
    position: absolute;
    left: 0;

    @include media-breakpoint-only(s) {
        height: rem(2);
        border-radius: rem(1);
        margin-top: rem(-1);
    }

    @include media-breakpoint-up(m) {
        height: $hamburger-layer-height;
        border-radius: ($hamburger-layer-height / 2);
        margin-top: ($hamburger-layer-height / -2);
    }
}


.c-hamburger__bar--1,
.c-hamburger__bar--3 {
    transition: transform $htd--half ease, top $htd--half ease $htd--half;

    .c-hamburger.is-active & {
        transition: transform $htd--half ease $htd--half, top $htd--half ease;
    }
}
.c-hamburger__bar--1 {
    top: 0%;
    transform: rotate(0deg);

    .c-hamburger.is-active & {
        top: 50%;
        transform: rotate(45deg);
    }
}
.c-hamburger__bar--2 {
    top: 50%;
    opacity: 1;
    transition: opacity $htd--half ease $htd--half;

    .c-hamburger.is-active & {
        opacity: 0;
        transition: opacity $htd--half ease $htd--half;
    }
}
.c-hamburger__bar--3 {
    top: 100%;
    transform: rotate(0deg);

    .c-hamburger.is-active & {
        top: 50%;
        transform: rotate(-45deg);
    }
}
