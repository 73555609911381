.c-hero--archive {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.c-archive__featured {
    max-width: 100%;

    @include media-breakpoint-only(s) {
        margin-bottom: 2rem;
    }

    @include media-breakpoint-up(m) {
        @include make-post-block-horizontal;
        margin-bottom: 4rem;

        .c-post-block__image-wrapper {
            width: 50%;
        }
    }
}

.c-archive__list {
    @include media-breakpoint-up(m) {
        .c-archive--full & {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
            grid-column-gap: $grid-gutter-width;
        }
    }
}

.c-archive--full {
    .c-post-block {
        @include media-breakpoint-only(s) {
            @include make-post-block-horizontal-minimal;
        }
    }
}

.c-archive--sidebar {
    .c-post-block {
        @include media-breakpoint-only(s) {
            @include make-post-block-horizontal-minimal;
        }
        @include media-breakpoint-up(m) {
            @include make-post-block-horizontal;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
        }
    }
}

.c-archive__pagination {
    margin-top: rem(2);
    margin-bottom: 2rem;
}

.c-archive--sidebar {
    .c-page__aside {
        @include media-breakpoint-only(m) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: $grid-gutter-width;
        }
    }
}
