.c-page--profile {
    .c-page__header {
        text-align: center;
        background-color: theme-color('light');
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .c-page__main {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}
